import React, { useEffect } from 'react';
import { Container, TextField, Grid, FormControlLabel, Switch, CircularProgress } from '@material-ui/core';
import BlueButton from '../../components/button/BlueButton';
import SearchIcon from '@material-ui/icons/Search';
import Alert from '@material-ui/lab/Alert';
import FormControl from '@material-ui/core/FormControl';
import baseInstaladaService from '../../Services/BaseInstalada/base-instalada-service';
import sensoresService from '../../Services/Sensores/sensores-service';
import UpdateIcon from '@material-ui/icons/Update';
import authService from '../../Services/auth/auth-service';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomAlert from '../../components/alert/CustomAlert';
import Checkbox from '@material-ui/core/Checkbox';

export default function BaseInstaladaComponent(props) {

  const [opcaoForm, setOpcaoForm] = React.useState(null);
  const [numSerie, setNumSerie] = React.useState(null);
  const [formBaseInstalada, setformBaseInstalada] = React.useState(false);
  const [sensores, setSensores] = React.useState([]);
  const [alertBaseInstaladaTotal, setAlertBaseInstaladaTotal] = React.useState(false);
  const mensagemAlertBaseInstaladaTotal = 'A série pesquisada não possui base instalada disponível para ser visualizada.';
  let usuarioID = authService.getUserInfo().usuario.id;
  const [sensorConfig1, setSensorConfig1] = React.useState(null);
  const [sensorConfig2, setSensorConfig2] = React.useState(null);
  const [sensorConfig3, setSensorConfig3] = React.useState(null);
  const [sensorConfig4, setSensorConfig4] = React.useState(null);
  const [sensorPainel1Selecionado, setSensorPainel1Selecionado] = React.useState(false);
  const [sensorPainel2Selecionado, setSensorPainel2Selecionado] = React.useState(false);
  const [tipoTravaBau, setTipoTravaBau] = React.useState(null);
  const [abrirAlert, setAbrirAlert] = React.useState(false);
  const [mensagemAlert, setMensagemAlert] = React.useState('');
  const [tipoAlert, setTipoAlert] = React.useState('error');
  const [efeitoCarregando, setEfeitoCarregando] = React.useState(false);
  const [efeitoCarregandoBaseInstalada, setEfeitoCarregandoBaseInstalada] = React.useState(false);

  useEffect(() => {
    sensoresConfiguraveis();
  }, []);

  const handleBaseInstalada = async (event) => {
    setEfeitoCarregando(true);
    event.preventDefault();

    const data = new FormData(event.target);

    let numSerie = data.get('txtSerie');

    let inventarioResult = await baseInstaladaService.total(props.na, numSerie);

    if (inventarioResult && inventarioResult.total > 0) {
      setAlertBaseInstaladaTotal(false);
      setformBaseInstalada(true);
      setNumSerie(numSerie);
      setEfeitoCarregando(false);
    } else {
      setformBaseInstalada(false);
      setAlertBaseInstaladaTotal(true);
      setEfeitoCarregando(false);
    }
  }

  const sensoresConfiguraveis = async () => {
    let sensoresResult = await sensoresService.configuravel();
    if (sensoresResult)
      setSensores(sensoresResult);
  }

  const isTrue = (string) => {
    return string == 'true';
  }

  const formBaseInstaladaHandle = async (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    if (sensorPainel1Selecionado !== 'Sensor de Painel' && sensorPainel2Selecionado !== 'Sensor de Painel') {
      setMensagemAlert({ title: 'Falha!', body: 'É obrigatório selecionar a opção de "Sensor de Painel" no sensor configurável 1 ou 2.' });
      setTipoAlert('fail');
      return setAbrirAlert(true);
    }

    setEfeitoCarregandoBaseInstalada(true);

    const baseInstalada = {
      Numero_Serie: numSerie,
      Bau_Lateral: isTrue(data.get('tgBauLateral')),
      Teclado_Multimidia: isTrue(data.get('tgTecladoMultimidia')),
      Bau_Intermediario: isTrue(data.get('tgBauIntermediario')),
      Trava_Bau_Intermediria: isTrue(data.get('tgTravaBauIntermediario')),
      Telemetria: isTrue(data.get('tgTelemetria')),
      Boto_Panico: isTrue(data.get('tgBotaoPanico')),
      Bateria_Backup: isTrue(data.get('tgBateriaBackup')),
      Setas_Pulsantes: isTrue(data.get('tgSetasPulsantes')),
      Ignicao: isTrue(data.get('tgIgnicao')),
      Teclado: isTrue(data.get('tgTecladoCompacto')),
      Sirene: isTrue(data.get('tgSirene')),
      Bloqueio_Solenoide: isTrue(data.get('tgBloqueioSolenoide')),
      Engate_Eletronico: isTrue(data.get('tgEngateEletronico')),
      Bloqueio_Eletronico: isTrue(data.get('tgBloqueioEletronico')),
      Trava_Bau_Lateral: isTrue(data.get('tgTravaBauLateral')),
      Engate_Aspiral: isTrue(data.get('tgEngateEspiral')),
      Temperatura: isTrue(data.get('tgTemperatura')),
      Portas_Cabine: isTrue(data.get('tgPortaCabine')),
      Bau_Traseiro: isTrue(data.get('tgBauTraseiro')),
      Trava_Bau_Traseira: isTrue(data.get('tgBauTraseira')),
      painel: isTrue(data.get('tgPainel')),
      Trava_Quinta_Roda: isTrue(data.get('tgTravaQuintaRoda')),
      Setas_Continuas: isTrue(data.get('tgSetasContinuas')),
      tipo_trava_bau: tipoTravaBau ? parseInt(tipoTravaBau.value) : null,
      sensor_configuravel1: sensorConfig1 ? parseInt(sensorConfig1.value) : null,
      sensor_configuravel2: sensorConfig2 ? parseInt(sensorConfig2.value) : null,
      sensor_configuravel3: sensorConfig3 ? parseInt(sensorConfig3.value) : null,
      sensor_configuravel4: sensorConfig4 ? parseInt(sensorConfig4.value) : null,
    };

    let atualizaResult = await baseInstaladaService.atualiza(JSON.stringify(baseInstalada), props.na, usuarioID);
    if (atualizaResult) {
      setMensagemAlert({ title: 'Sucesso!', body: 'Base Instalada atualizada com sucesso!' });
      setTipoAlert('success');
      setAbrirAlert(true);
      setEfeitoCarregandoBaseInstalada(false);
    }
    else {
      setMensagemAlert({ title: 'Erro!', body: 'Não foi possivel atualizar a base instalada.' });
      setTipoAlert('error');
      setAbrirAlert(true);
      setEfeitoCarregandoBaseInstalada(false);
    }
  }

  const handleSensor1Change = (event, newValue) => {
    if (newValue) {
      setSensorPainel1Selecionado(newValue.descricao);
      setSensorConfig1(newValue)
    } else {
      setSensorConfig1(newValue)
      setSensorPainel1Selecionado(null);
    }
  };

  const handleSensor2Change = (event, newValue) => {
    if (newValue) {
      setSensorPainel2Selecionado(newValue.descricao);
      setSensorConfig2(newValue);
    } else {
      setSensorConfig2(newValue);
      setSensorPainel2Selecionado(null);
    }
  };

  return (
    <Container style={{ padding: '5px' }}>
      <CustomAlert
        open={abrirAlert}
        message={mensagemAlert}
        type={tipoAlert}
        onClose={() => setAbrirAlert(false)}
      />
      <div>
        <form onSubmit={handleBaseInstalada} required>
          <Grid style={{ flexGrow: 1 }} container spacing={3}>
            <Grid item xs={12} sm={5} md={3}>
              <TextField required
                name="txtSerie"
                variant="outlined"
                label="Número de Série"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={5} md={3}>
              <BlueButton
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                style={{ height: 55 }}
                startIcon={efeitoCarregando ? <CircularProgress size={20} /> : <SearchIcon />}
              >
                <span style={{ fontSize: '0.7rem' }}>{efeitoCarregando ? ' PESQUISANDO...' : ' PESQUISAR BASE'}</span>
              </BlueButton>
            </Grid>

            <Grid xs={12} md={8} item>
              {alertBaseInstaladaTotal && <Alert severity="error">{mensagemAlertBaseInstaladaTotal}</Alert>}
            </Grid>
          </Grid>
        </form>
        {formBaseInstalada &&
          <form onSubmit={formBaseInstaladaHandle} required>
            <Grid container style={{ flexGrow: 1 }} spacing={3}>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgBauLateral" color="primary" />}
                  label="Baú Lateral"
                  labelPlacement="baulateral"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgTecladoMultimidia" color="primary" />}
                  label="Teclado Multimidia"
                  labelPlacement="tlm"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgBauIntermediario" color="primary" />}
                  label="Baú Intermediario"
                  labelPlacement="bt"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgTravaBauIntermediario" color="primary" />}
                  label="Trava Baú Intermediario"
                  labelPlacement="tbi"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgTelemetria" color="primary" />}
                  label="Telemetria"
                  labelPlacement="telemetria"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgBotaoPanico" color="primary" />}
                  label="Botão Panico"
                  labelPlacement="btnpanico"
                />
              </Grid>


              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgBateriaBackup" color="primary" />}
                  label="Bateria Backup"
                  labelPlacement="bb"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgSetasPulsantes" color="primary" />}
                  label="Setas Pulsantes"
                  labelPlacement="sp"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgIgnicao" color="primary" />}
                  label="Ignição"
                  labelPlacement="ignicao"
                />
              </Grid>

              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgTecladoCompacto" color="primary" />}
                  label="Teclado Compacto"
                  labelPlacement="tc"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgSirene" color="primary" />}
                  label="Sirene"
                  labelPlacement="sirene"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgBloqueioSolenoide" color="primary" />}
                  label="Bloqueio Solenoide"
                  labelPlacement="bs"
                />
              </Grid>


              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgEngateEletronico" color="primary" />}
                  label="Engate Eletrônico"
                  labelPlacement="ee"

                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgBloqueioEletronico" color="primary" />}
                  label="Bloqueio Eletrônico"
                  labelPlacement="be"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgTravaBauLateral" color="primary" />}
                  label="Trava Baú Lateral"
                  labelPlacement="tbl"
                />
              </Grid>

              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgEngateEspiral" color="primary" />}
                  label="Engate Espiral"
                  labelPlacement="eee"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgTemperatura" color="primary" />}
                  label="Temperatura"
                  labelPlacement="temperatura"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgPortaCabine" color="primary" />}
                  label="Portas Cabine"
                  labelPlacement="portacabine"
                />
              </Grid>

              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgBauTraseiro" color="primary" />}
                  label="Baú Traseiro"
                  labelPlacement="btr"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgBauTraseira" color="primary" />}
                  label="Travas Baú Traseira"
                  labelPlacement="tbt"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgPainel" color="primary" />}
                  label="Painel"
                  labelPlacement="painel"
                />
              </Grid>


              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgTravaQuintaRoda" color="primary" />}
                  label="Trava Quinta Roda"
                  labelPlacement="tb"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  value={true}
                  control={<Checkbox name="tgSetasContinuas" color="primary" />}
                  label="Setas Contínuas"
                  labelPlacement="sc"
                />
              </Grid>
            </Grid>

            <Grid container style={{ flexGrow: 1 }} spacing={3}>
              <Grid xs={12} md={6} lg={6} item>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    labelId="demo-simple-select-label"
                    name="drpSensorConfig1"
                    options={sensores}

                    onChange={handleSensor1Change}
                    getOptionLabel={(option) => option.descricao}
                    defaultValue={sensores.find((option) => option.id != null)}
                    renderInput={(params) => <TextField {...params} label="Sensor Configurável 1" variant="outlined" style={{ marginBottom: 10 }} />}
                  />
                </FormControl>

              </Grid>
              <Grid xs={12} md={6} lg={6} item>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    labelId="demo-simple-select-label"
                    name="drpSensorConfig2"
                    options={sensores}
                    onChange={handleSensor2Change}
                    getOptionLabel={(option) => option.descricao}
                    defaultValue={sensores.find((option) => option.id != null)}
                    renderInput={(params) => <TextField {...params} label="Sensor Configurável 2" variant="outlined" style={{ marginBottom: 10 }} />}
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} md={6} lg={6} item>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    labelId="demo-simple-select-label"
                    name="drpSensorConfig3"
                    options={sensores}
                    onChange={(event, value) => setSensorConfig3(value)}
                    getOptionLabel={(option) => option.descricao}
                    defaultValue={sensores.find((option) => option.id != null)}
                    renderInput={(params) => <TextField {...params} label="Sensor Configurável 3" variant="outlined" style={{ marginBottom: 10 }} />}
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} md={6} lg={6} item>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    labelId="demo-simple-select-label"
                    name="drpSensorConfig4"
                    options={sensores}
                    onChange={(event, value) => setSensorConfig4(value)}
                    getOptionLabel={(option) => option.descricao}
                    defaultValue={sensores.find((option) => option.id != null)}
                    renderInput={(params) => <TextField {...params} label="Sensor Configurável 4" variant="outlined" style={{ marginBottom: 10 }} />}
                  />
                </FormControl>

              </Grid>

              <Grid xs={12} md={6} lg={6} item>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    labelId="demo-simple-select-label"
                    name="drpTipoTravaBau"
                    options={[
                      { value: 1, label: 'Solenoide' },
                      { value: 2, label: 'Motorizada' },
                      { value: 3, label: 'Inteligente' }
                    ]}
                    onChange={(event, value) => setTipoTravaBau(value)}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} label="Tipo Trava Baú" variant="outlined" style={{ marginBottom: 10 }} />}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container style={{ flexGrow: 1 }} spacing={2}>
            <Grid item xs={12} sm={8} >
            </Grid>
            <Grid item xs={12} sm={4} style={{ textAlign: 'right', marginTop: '3px' }}>
                <div style={{ display: 'flex', paddingTop: 15 }}>
                  <BlueButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    onClick={() => setOpcaoForm(1)}
                    style={{ height: 55 }}
                    startIcon={efeitoCarregandoBaseInstalada ? <CircularProgress size={20} /> : <UpdateIcon />}
                  >
                    <span style={{ fontSize: '0.7rem' }}>{efeitoCarregandoBaseInstalada ? ' ATUALIZANDO...' : ' ATUALIZAR BASE'}</span>
                  </BlueButton>
                </div>
              </Grid>
              <Grid item xs={12}>
                {sensorPainel1Selecionado !== "Sensor de Painel" && sensorPainel2Selecionado !== "Sensor de Painel" && <Alert severity="error">É obrigatório selecionar a opção de &quot;Sensor de Painel&quot; no sensor configurável 1 ou 2!</Alert>}
              </Grid>
            </Grid>
          </form>
        }
      </div>
    </Container>
  );

}