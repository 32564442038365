import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class PerfilTecnicoService {

  async listarTecnicos(areaAtendimento, dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/SolicitacaoCadastroTecnico/listar?AreaAtendimentoId=${areaAtendimento}&Pagina=${dados.pagina}&Limite=${dados.limite}&Pesquisa=${dados.nome}`, {
        method: 'GET',
        headers: _headerToken,
      },
      );
      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      console.log(error)
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }

  async adicionarTecnico(dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/SolicitacaoCadastroTecnico`,
        {
          method: 'POST',
          headers: _headerToken,
          body: JSON.stringify(dados),
        },
      );
      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200)
        return {
          error: false,
          result: data,
        };

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      console.log(error)
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }

  async editarTecnico(idTecnico, dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/SolicitacaoCadastroTecnico/${idTecnico}`,
        {
          method: 'PUT',
          headers: _headerToken,
          body: JSON.stringify(dados),
        },
      );
      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200)
        return {
          error: false,
          result: data,
        };

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      console.log(error)
      return {
        error: true,
        message: 'Houve um erro ao editar o produto.',
      };
    }
  }

  async desativarTecnico(idProduto) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/SolicitacaoCadastroTecnico/delete/${idProduto}`, {
        method: 'PATCH',
        headers: _headerToken,
      });

      let data;
      try {
        data = await response.json();
      } catch (jsonError) {
        if (response.status === 200) {
          return {
            error: false,
            result: null,
          };
        } else {
          return {
            error: true,
            message: 'JSON inválido.',
          };
        }
      }

      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) {
        return {
          error: false,
          result: data,
        };
      }

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      console.log(error);
      return {
        error: true,
        message: 'Houve um erro ao deletar um produto.',
      };
    }
  }

}

export default new PerfilTecnicoService();
