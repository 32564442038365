import React, { useState, useMemo, useCallback } from 'react';
import { Grid, Typography, CircularProgress, Tooltip, Paper, Button, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  itemContainer: {
    padding: theme.spacing(2),
    textAlign: 'center',
    borderRadius: '4px',
    height: '175px', 
    width: '100%',  
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',  
    boxSizing: 'border-box',
  },
  actionButton: {
    margin: theme.spacing(1),
    width: '45%',
    backgroundColor: '#f5f5f5',
  },
  actionButtonDisabled: {
    margin: theme.spacing(1),
    width: '45%',
    backgroundColor: '#e0e0e0',
  },
  statusBadge: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px 8px',
    fontSize: '0.8rem',
    fontWeight: '500',
    color: '#fff',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderRadius: '4px',
    backgroundColor: (props) => props.bgColor,
    maxWidth: '200px',
    margin: '0 auto',
  },
}));

export function ListaProdutosServicos({ na, itensOS }) {
  const [load, setLoad] = useState(false);
  const [termoPesquisa, setTermoPesquisa] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const itemsPerPage = isMobile ? 3 : 6;  
  const produtosFiltrados = useMemo(() => {
    return itensOS.concat().filter(
      (item) =>
        item &&
        item.produto &&
        item.produto.toLowerCase().includes(termoPesquisa.toLowerCase()) ||
        item.codigoProduto &&
        item.codigoProduto.toLowerCase().includes(termoPesquisa.toLowerCase())
    );
  }, [termoPesquisa, itensOS]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = produtosFiltrados.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(produtosFiltrados.length / itemsPerPage);

  const handleNextPage = useCallback(() => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [currentPage, totalPages]);

  const handlePreviousPage = useCallback(() => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }, [currentPage]);

  const StatusBadge = ({ status }) => {
    let statusText = '';
    let bgColor = '';
    let IconComponent = null;

    switch (status) {
      case 'Aprovado':
        statusText = 'Aprovado';
        bgColor = 'rgba(3, 201, 169, 1)';
        IconComponent = CheckCircleIcon;
        break;
      case 'Aguardando Aprovacao':
        statusText = 'Aguardando Aprovação';
        bgColor = '#777';
        IconComponent = HourglassEmptyIcon;
        break;
      case 'Recusado':
        statusText = 'Recusado';
        bgColor = 'rgba(255, 99, 132, 1)';
        IconComponent = CancelIcon;
        break;
      case 'Temporário':
        statusText = 'Temporário';
        bgColor = '#87CEEB';
        IconComponent = InfoIcon;
        break;
      default:
        statusText = 'Desconhecido';
        bgColor = '#000';
        IconComponent = InfoIcon;
        break;
    }

    return (
      <div className={classes.statusBadge} style={{ backgroundColor: bgColor }}>
        <IconComponent style={{ marginRight: '8px', color: '#fff' }} />
        {statusText}
      </div>
    );
  };

  return (
    <>
      <div className={classes.subtitle} style={{marginTop: 30}}>Produtos e Serviços</div>
      <Grid container spacing={2} alignItems="center">
        {load ? (
          <CircularProgress />
        ) : (
          <>
            {currentItems.length === 0 ? (
              <Typography variant="body1" style={{margin: 20}}>Nenhum registro para exibir</Typography>
            ) : (
              currentItems.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Paper className={classes.itemContainer}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Tooltip title={item.produto} placement="top" arrow>
                          <Typography variant="subtitle1" className={classes.truncatedText}>
                            {item.produto}
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2">Quantidade: {item.quantidade}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <StatusBadge status={item.descStatus} />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))
            )}
            {totalPages > 1 && (
              <Grid container justifyContent="center" style={{ marginTop: theme.spacing(2) }}>
                <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
                  <ArrowBackIcon />
                </Button>
                <Typography variant="body2" style={{ padding: '0 16px' }}>
                  Página {currentPage} de {totalPages}
                </Typography>
                <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
                  <ArrowForwardIcon />
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );
}
