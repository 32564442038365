import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Box, Button, Paper, TextField, Dialog,
  DialogActions,
  DialogContent, Divider,
  DialogTitle, CircularProgress
} from '@material-ui/core'
import BlueButton from '../components/button/BlueButton'
import { OSVistoriaPageTecnico } from '../ordemServico/os-vistoria-page-tecnico'
import AssignmentIcon from '@material-ui/icons/Assignment'
import './components/ComponentStyles.css'
import vistoriaService from '../Services/Vistoria/vistoria-service'
import MenuComponent from '../components/menu/menu-component'
import { MaterialTableCustom } from '../components/table/material-table-custom'
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import ordemServicoServico from '../Services/OrdemServico/ordem-servico-servico'
import Grid from '@material-ui/core/Grid'
import agendaService from '../Services/Agenda/agenda-service'
import authService from '../Services/auth/auth-service'
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CustomAlert from '../components/alert/CustomAlert'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FilesExportFilter from './components/filesExportFilter'
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  exportExcel: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 0px 15px',
    borderRadius: 10,
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px'
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px'
  },
}));

export default function ServicosPage({ handleCloseOrdemServico, microSigaID, nomeTecnico }) {
  const classes = useStyles();
  const [os, setOS] = useState(null);
  const [loadVistoria, setLoadVistoria] = useState(false);
  const [dadosVistoria, setDadosVistoria] = useState(null);
  const [itensOS, setItensOS] = useState([]);
  const [openDialogVistoria, setOpenDialogVistoria] = useState(false);
  const [_servicos, setServicos] = useState([]);
  const [dataExportacao, setDataExportacao] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isMobile, setISMobile] = useState(false)
  const mobile = useMediaQuery(useTheme().breakpoints.down(800))
  const [page, setPage] = useState(0);
  let [pageSize, setPageSize] = useState(10);
  const [totalDados, setTotalDados] = useState(0);
  const [carregandoTable, setCarregandoTable] = useState(false);
  const [tableKey, setTableKey] = useState(0)
  const [pesquisa, setPesquisa] = useState('');
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState('');
  const [tipoAlert, setTipoAlert] = useState('error');
  const [abrirModalSelecionarMesAnoExportacao, setAbrirModalSelecionarMesAnoExportacao] = useState(false);
  const [efeitoCarregarModalExportacao, setEfeitoCarregarModalExportacao] = useState(false);
  const [mes, setMes] = useState('');
  const [ano, setAno] = useState('');
  const [arquivo, setArquivo] = useState('');
  const [exportar, setExportar] = useState(false);
  const classificacaoPrestador = authService.getUserInfo().usuario.classificacaoPrestador;

  const mapCodigoToMotivo = (motivo) => motivoCodigoMap[motivo] || null;

  const motivoCodigoMap = {
    "Apagado": "419400000",
    "Display queimado": "419400001",
    "Teclas falhando": "419400002",
    "Não recebe comandos": "419400003",
    "Mau uso": "419400004",
    "Queimado": "419400005",
    "Infiltração d'água": "419400006",
    "Não comunica": "419400007",
    "Não aciona": "419400008",
    "Não bloqueia": "419400009",
    "Quebrado - Mau uso": "419400010",
    "Senha supervisor": "419400011"
  };

  const handleOpenDialogVistoria = async (numeroNA) => {
    let areaAtendimentoID;
    if (microSigaID) {
      areaAtendimentoID = microSigaID;
    } else {
      areaAtendimentoID = authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;
    }

    let na = numeroNA

    let osResult = await ordemServicoServico.detalhesOS(na, areaAtendimentoID);
    let osOrcamento = await ordemServicoServico.listaOrcamentoOS(na, areaAtendimentoID);

    if (osResult) {
      let _os = {
        osID: osResult[0].tz_Ordem_ServicoId || '',
        os: osResult[0].os || '',
        activityId: osResult[0].activityId || '',
        cliente: osResult[0].cliente || '',
        clienteEmail: osResult[0].clienteEmail?.toLowerCase() || '',
        solicitante: osResult[0].tz_nome_solicitante || '',
        telefone_solicitante: osResult[0].tz_telefone_solicitante || '',
        endereco: osResult[0].tz_Endereco_Rua || '',
        numero: osResult[0].tz_Endereco_Numero || '',
        cep: osResult[0].tz_endereco_cepIdName || '',
        cidade: osResult[0].tz_endereco_cidadeIdName || '',
        estado: osResult[0].tz_endereco_EstadoIdName || '',
        referencia: osResult[0].tz_referencia || '',
        razaoStatus: osResult[0].razaoStatus || '',
        inicio: osResult[0].data_inicio,
        fim: osResult[0].data_fim,
        na: osResult[0].na || '',
        plataforma: osResult[0].tz_PlataformaIdName || '',
        rastreador: osResult[0].tz_RastreadorIdName || '',
        plano: osResult[0].tz_PlanoIdName || '',
        num_serie_contrato: osResult[0].tz_Numero_Serie_Contrato || '',
        fim_garantia: osResult[0].tz_Data_Fim_Garantia,
        observacao: osResult[0].tz_Observacoes || '',
        status: osResult[0].descStatus || '',
        obsCliente: osResult[0].detalheCliente || '',
        bairro: osResult[0].tz_endereco_bairro || '',
        modificacao: osResult[0].modifiedOn || '',
        servico: osResult[0].serviceIdName || '',
        antena: osResult[0].tz_Numero_Serie_Antena_Contrato || '',
        clienteID: osResult[0].clienteID || '',
        id_area_atendimento: osResult[0].tz_area_atendimento_microsigaId || areaAtendimentoID,
        NomeClienteVistoria: osResult[0].cpf_rg || '',
        tipoServico: osResult[0].tipoServico || '',
        localAtendimento: osResult[0].localAtendimento || '',
        tipoServicoAgendamento: osResult[0].tipoServicoAgendamento || '',
        placa: osResult[0].placa || '',
        cor: osResult[0].cor || '',

      }
      setOS(_os)

      if (osOrcamento) {
        let _itensOS = [];
        osOrcamento.map((o) => {
          let item = {
            ordemID: o.tz_Ordem_ServicoId,
            itemID: o.tz_item_ordem_servicoId,
            produtoId: o.tz_ItemId,
            numeroProduto: o.produtoNumero,
            produto: o.produtoNome,
            quantidade: o.quantidade,
            descStatus: o.descStatus_aprovacao,
            statusAprovacao: o.status_aprovacao,
            motivoOrcamento: mapCodigoToMotivo(o.tz_motivo_orcamento)
          };

          _itensOS.push(item);
        });
        setItensOS(_itensOS);
      }

      setOpenDialogVistoria(true)
    }
  }

  const handleCloseDialogVistoria = () => setOpenDialogVistoria(false)

  const handleAbrirModalSelecionarMesAnoExportacao = () => {
    setAbrirModalSelecionarMesAnoExportacao(true);
  };

  const handleFecharModalSelecionarMesAnoExportacao = () => {
    setAbrirModalSelecionarMesAnoExportacao(false);
    setDataExportacao([]);
    setExportar(false);
    setMes('');
    setAno('');
    setArquivo('');
  }

  useEffect(() => {
    if (mobile) {
      setTableKey(tableKey + 1);
    } else {
      setTableKey(tableKey + 1);
    }
    servicos(startDate, endDate, page, pageSize, pesquisa);
  }, [])

  useEffect(() => {
    // quando a OS existir, busca os dados do vistoria
    if (mobile) {
      setTableKey(tableKey + 1);
    } else {
      setTableKey(tableKey + 1);
    }
    if (os) getVistoria()
  }, [os])

  // pega os dados do vistoria se existir
  async function getVistoria() {
    try {
      setLoadVistoria(true)
      const { osID, activityId } = os

      const responseVistoria = await vistoriaService.consultaVistoria(activityId, osID)
      if (responseVistoria?.error) {
        setMensagemAlert({ title: 'Erro!', body: 'Não foi possível obter o status do vistoria.' });
        setTipoAlert('error');
        setAbrirAlert(true);
      } else {
        if (responseVistoria) setDadosVistoria(responseVistoria)
      }
    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Não foi possível obter o status do vistoria.' });
      setTipoAlert('error');
      setAbrirAlert(true);
    } finally {
      setLoadVistoria(false)
    }
  }

  const valorPesquisa = (nome) => {
    setPesquisa(nome);
    servicos(startDate, endDate, 0, pageSize, nome);
  };

  const mudarPagina = (event, newPage) => {
    setPage(newPage);
    servicos(startDate, endDate, newPage, pageSize, pesquisa);
  };

  const mudarTamanhoPagina = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
    servicos(startDate, endDate, 0, parseInt(event.target.value, 10), pesquisa);
  };

  async function getOrcamento(numeroNA) {
    let areaAtendimentoID;
    if (microSigaID) {
      areaAtendimentoID = microSigaID;
    } else {
      areaAtendimentoID = authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;
    }

    let na = numeroNA

    let osResult = await ordemServicoServico.listaOrcamentoOS(na, areaAtendimentoID)

    if (osResult) {
      let _itensOS = []
      osResult.map((o) => {
        let item = {
          produtoId: o.tz_ItemId,
          produto: o.produtoNome,
          valorTotal: o.valor_total,
          na: o.numeroAgendamento,
          quantidade: o.quantidade,
          descStatus: o.descStatus_aprovacao
        }
        _itensOS.push(item)
      })
      return _itensOS
    }
  }

  const servicos = async (dataInicial, dataFinal, page, pageSize, pesquisa) => {
    if (dataInicial && dataFinal && moment(dataInicial).isAfter(dataFinal)) {
      setMensagemAlert({ title: 'Falha!', body: 'Data inicial não pode ser maior que a data final.' });
      setTipoAlert('fail');
      setAbrirAlert(true);
      setCarregandoTable(false);
      return;
    }
    setCarregandoTable(true);
    try {
      const dados = {
        dataInicial: dataInicial ? dataInicial : '',
        dataFinal: dataFinal ? dataFinal : '',
        pagina: page ? page : 0,
        limite: pageSize ? pageSize : 5,
        nome: pesquisa ? pesquisa : '',
      };

      let servicosResult = await agendaService.getNas(dados, microSigaID);

      let data = [];
      if (servicosResult.dados) {
        const formatoData = 'DD/MM/yyyy HH:mm:ss';
        let dadosServicos = servicosResult.dados;

        for (let servico of dadosServicos) {
          let statusAprovacaoOrcamento = [];
          let servicoOrcamento = [];
          let quantidadeServicoOrcamento = [];

          if (classificacaoPrestador !== 2) {
            let orcamento = await getOrcamento(servico.na);

            if (orcamento && orcamento.length > 0) {
              orcamento.forEach((o) => {
                if (o.produto) {
                  statusAprovacaoOrcamento.push(o.descStatus);
                  servicoOrcamento.push(o.produto);
                  quantidadeServicoOrcamento.push(o.quantidade);
                }
              });
            }
          }

          let servicoObjeto = {
            NA: servico.na,
            Cliente: servico.nomeCliente,
            Assunto: servico.assunto,
            Serviço: servico.servico,
            Status_aprovacao_orcamento: statusAprovacaoOrcamento,
            Servico_Orcamento: servicoOrcamento,
            Quantidade_Servico_Orcamento: quantidadeServicoOrcamento,
            Início: servico.inicio ? moment(servico.inicio).format(formatoData) : null,
            Término: servico.termino ? moment(servico.termino).format(formatoData) : null,
            PrevisaoInicio: servico.previsaoInicio ? moment(servico.previsaoInicio).format(formatoData) : null,
            PrevisaoTermino: servico.previsaoTermino ? moment(servico.previsaoTermino).format(formatoData) : null,
            Status: servico.status,
            OS: servico.os,
            Observação: servico.osObservacoes,
            Endereco: '-',
            Logradouro: servico.enderecoRua,
            Numero: servico.enderecoNumero,
            CEP: servico.enderecoCepIdName,
            Bairro: servico.enderecoBairro,
            Cidade: servico.enderecoCidadeIdName,
            Estado: servico.enderecoEstadoIdName,
            Complemento: servico.enderecoComplemento,
            Veiculo: '-',
            Placa: servico.veiculoIdName,
            PlacaCarreta: servico.placaCarreta,
            Série: servico.numSerieRastreadorInstalado,
          };
          data.push(servicoObjeto);
        }
        setTotalDados(servicosResult.total);
        setServicos(data);
      } else {
        setMensagemAlert('Erro ao consultar serviços.');
        setAbrirAlert(true);
      }
    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Erro ao consultar serviços.' });
      setTipoAlert('error');
      setAbrirAlert(true);
    }
    setCarregandoTable(false);
  };

  const exportarDadosSelecionados = async () => {
    if ((mes != null && mes !== '') && (ano != null && ano !== '') && (arquivo != null && arquivo !== '')) {
      setEfeitoCarregarModalExportacao(true);

      let servicosExportacaoResultado = await agendaService.buscarDadosNAPorPeriodo(microSigaID, mes, ano);
      let data = [];

      if (servicosExportacaoResultado.mensagem === "Não há registros para serem exibidos para o mês e ano selecionado.") {
        setMensagemAlert({ title: 'Erro!', body: servicosExportacaoResultado.mensagem });
        setTipoAlert('error');
        setAbrirAlert(true);
        setDataExportacao([]);
        setEfeitoCarregarModalExportacao(false);
        return;
      } else {
        const formatoData = 'DD/MM/yyyy HH:mm:ss';

        for (let servico of servicosExportacaoResultado) {
          let servicoExcelObjeto = {
            'NA': servico.na,
            'Cliente': servico.nomeCliente,
            'Serviço': servico.servico,
            'Previsão de Início': servico.previsaoInicio ? moment(servico.previsaoInicio).format(formatoData) : 'Não iniciado',
            'Previsão de Término': servico.previsaoTermino ? moment(servico.previsaoTermino).format(formatoData) : 'Não finalizado',
            'Status': await statusNAExportacao(servico.status),
            'OS': servico.os,
          };
          data.push(servicoExcelObjeto);
        }

        setDataExportacao(data);

        setTimeout(() => {
          setExportar(true);
        }, 2000);

        setTimeout(() => {
          handleFecharModalSelecionarMesAnoExportacao(true);
        }, 3000);

        setEfeitoCarregarModalExportacao(false);
      }
    } else {
      setMensagemAlert({ title: 'Falha!', body: 'É necessário selecionar um mês, ano e tipo de arquivo para exportar os dados.' });
      setTipoAlert('fail');
      setAbrirAlert(true);
    }
  };

  const statusNAExportacao = async (status) => {
    switch (status) {
      case 0:
        return (
          'Aberta'
        )
      case 1:
        return (
          'Concluído'
        )
      case 2:
        return (
          'Cancelado'
        )
      case 3:
        return (
          'Agendado'
        )
      default:
        return (
          'Agendado'
        )
    }
  }

  const styleStatus = (status) => {
    switch (status) {
      case 0:
        return (
          <div
            style={{
              textAlign: 'center',
              display: 'block',
              borderRadius: 10,
              color: 'white',
              background: '#f2a821',
              width: '100px'
            }}
          >
            Aberta
          </div>
        )
      case 1:
        return (
          <div
            style={{
              textAlign: 'center',
              display: 'block',
              borderRadius: 10,
              color: 'white',
              background: '#73ae2e',
              width: '100px'
            }}
          >
            Concluído
          </div>
        )
      case 2:
        return (
          <div
            style={{
              textAlign: 'center',
              display: 'block',
              borderRadius: 6,
              color: 'white',
              background: 'red',
              width: '100px'
            }}
          >
            Cancelado
          </div>
        )
      case 3:
        return (
          <div
            style={{
              textAlign: 'center',
              display: 'block',
              borderRadius: 6,
              color: 'black',
              background: '#98FB98',
              width: '100px'
            }}
          >
            Agendado
          </div>
        )
      default:
        return (
          <div
            style={{
              textAlign: 'center',
              display: 'block',
              borderRadius: 6,
              color: 'white',
              background: '#4682B4',
              width: '100px'
            }}
          >
            Agendado
          </div>
        )
    }
  }

  return !openDialogVistoria ? (
    <>
      <MenuComponent />
      <CustomAlert
        open={abrirAlert}
        message={mensagemAlert}
        type={tipoAlert}
        onClose={() => setAbrirAlert(false)}
      />
      <div style={{ margin: 30 }}>
        <h3 className={classes.tituloStyle}>Serviços</h3>
        <h4 className={classes.subtituloStyle}>
          <span
            onClick={() => {
              const userInfo = authService.getUserInfo();
              if (userInfo.usuario.gestor) {
                window.location.assign('/gestor');
              } else {
                window.location.assign('/agenda');
              }
            }}
            className={classes.linkStyle}
            style={{ cursor: 'pointer' }}
          >
            Home
          </span>
          {' > '}
          Serviços
          {authService.getUserInfo().usuario.gestor === true &&
            nomeTecnico &&
            nomeTecnico.trim() !== '' ? (
            <>
              {' > '}
              {nomeTecnico}
            </>
          ) : null}
        </h4>

        {authService.getUserInfo().usuario.gestor === true &&
          nomeTecnico &&
          nomeTecnico.trim() !== '' ? (
          <Box sx={{ marginLeft: 1 }}>
            <p
              style={{ fontWeight: 'bold', fontSize: '1rem' }}
              className={classes.subtituloStyle}
            >
              <IconButton onClick={handleCloseOrdemServico} aria-label="voltar">
                <ArrowBackIosIcon />
              </IconButton>
              Voltar
            </p>
          </Box>
        ) : null}

        <div>
          <Dialog
            aria-labelledby="customized-dialog-title"
            onClose={handleFecharModalSelecionarMesAnoExportacao}
            open={abrirModalSelecionarMesAnoExportacao}
            style={{ marginTop: 35, marginBottom: 10 }}
            disableBackdropClick
          >
            <DialogTitle>
              <Grid container alignItems="center">
                <Grid item xs={10} sm={11}>
                  Selecione o mês, ano e o formato para exportação:
                </Grid>
                <Grid item xs={2} sm={1}>
                  <IconButton onClick={handleFecharModalSelecionarMesAnoExportacao}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent dividers>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel id="mes-label">Mês <span style={{ color: 'red' }}> * </span></InputLabel>
                <Select
                  labelId="mes-label"
                  value={mes}
                  onChange={(e) => setMes(e.target.value)}
                  label="Mês"
                >
                  {Array.from({ length: 12 }, (_, i) => (
                    <MenuItem key={i + 1} value={i + 1}>
                      {new Date(0, i).toLocaleString('pt-BR', { month: 'long' })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel id="ano-label">Ano <span style={{ color: 'red' }}> * </span></InputLabel>
                <Select
                  labelId="ano-label"
                  value={ano}
                  onChange={(e) => setAno(e.target.value)}
                  label="Ano"
                >
                  {Array.from({ length: 10 }, (_, i) => {
                    const year = new Date().getFullYear() - i;
                    return (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel id="arquivo-label">Arquivo <span style={{ color: 'red' }}> * </span></InputLabel>
                <Select
                  labelId="arquivo-label"
                  value={arquivo}
                  onChange={(e) => setArquivo(e.target.value)}
                  label="Arquivo"
                >
                  <MenuItem value={'csv'}>Exportar em CSV</MenuItem>
                  <MenuItem value={'pdf'}>Exportar em PDF</MenuItem>
                  <MenuItem value={'excel'}>Exportar em EXCEL</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>

            <DialogActions style={{ justifyContent: 'space-around' }}>
              <Button
                variant="contained"
                fullWidth
                disabled={efeitoCarregarModalExportacao}
                onClick={handleFecharModalSelecionarMesAnoExportacao}
                style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
              >
                Cancelar
              </Button>
              <BlueButton
                variant="contained"
                color="primary"
                size="large"
                disabled={efeitoCarregarModalExportacao}
                onClick={exportarDadosSelecionados}
                style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
              >
                {efeitoCarregarModalExportacao ? 'Exportando...' : 'Exportar'} {efeitoCarregarModalExportacao && <CircularProgress size={20} />}
              </BlueButton>
            </DialogActions>
          </Dialog>

          {exportar && <FilesExportFilter data={dataExportacao} fileName={'Serviços'} fileExport={arquivo} />}
        </div>

        <div style={{ marginBottom: 30 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={mobile ? 4 : 5}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  id="start-date"
                  label="Data inicial"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={mobile ? 4 : 5}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  id="end-date"
                  label="Data final"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={mobile ? 4 : 2}>
              <BlueButton
                variant="contained"
                color="primary"
                size="large"
                onClick={() => servicos(startDate, endDate, page, pageSize, pesquisa)}
                fullWidth
                startIcon={carregandoTable ? <CircularProgress size={20} /> : <SearchIcon />}
                style={{ height: 55 }}
              >
                <span>{carregandoTable ? ' BUSCANDO...' : ' BUSCAR'}</span>
              </BlueButton>
            </Grid>
          </Grid>
        </div>

        <Paper className={classes.paper}>
          <div key={tableKey} style={{ marginBottom: '16px', overflowX: 'auto' }}>
            <MaterialTableCustom
              key={pageSize}
              title=""
              columns={[
                { title: 'NA', field: 'NA' },
                { title: 'Cliente', field: 'Cliente', render: rowData => rowData.Cliente || 'Não informado', hidden: mobile, export: true },
                { title: 'Assunto', field: 'Assunto', hidden: true, export: true },
                { title: 'Serviço', field: 'Serviço', hidden: true, export: true },
                { title: 'Início', field: 'Início', hidden: true, export: true },
                { title: 'Término', field: 'Término', hidden: true, export: true },
                { title: 'Previsão de Início', field: 'PrevisaoInicio', hidden: true, export: true },
                { title: 'Previsão de Término', field: 'PrevisaoTermino', hidden: true, export: true },
                { title: 'Número OS', field: 'OS', hidden: true, export: true },
                { title: 'Observações', field: 'Observação', hidden: true, export: true },
                {
                  title: 'Status',
                  field: 'Status',
                  render: (rowData) => styleStatus(rowData.Status)
                },
                {
                  title: 'Vistoria',
                  field: '',
                  width: 10,
                  hidden: classificacaoPrestador === 2,
                  render: (rowData) => (
                    <Button onClick={() => handleOpenDialogVistoria(rowData.NA)}>
                      <AssignmentIcon />
                    </Button>
                  ),
                  sorting: false,
                  // hidden: mobile || authService.getUserInfo().usuario.classificacaoPrestador == 2,
                },
              ]}
              onSearchChange={valorPesquisa}
              data={_servicos}
              localization={{
                body: {
                  emptyDataSourceMessage: carregandoTable ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                      <CircularProgress />
                    </div>
                  ) : 'Nenhum registro para exibir'
                },
                toolbar: {
                  searchTooltip: 'Pesquisar',
                  searchPlaceholder: 'Pesquisar',
                  exportCSVName: 'Exportar em CSV',
                  exportPDFName: 'Exportar em PDF',
                  exportTitle: 'Exportar'
                },
                pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{from}-{to} de {count}',
                  firstTooltip: 'Primeira página',
                  previousTooltip: 'Página anterior',
                  nextTooltip: 'Próxima página',
                  lastTooltip: 'Última página'
                }
              }}
              actions={[
                {
                  icon: () => (
                    <BlueButton
                      variant="contained"
                      color="primary"
                      startIcon={<DownloadIcon />}
                      onClick={handleAbrirModalSelecionarMesAnoExportacao}
                    >
                      Exportar
                    </BlueButton>
                  ),
                  isFreeAction: true
                }
              ]}
              options={{
                actionsColumnIndex: -1,
                search: true,
                exportAllData: true,
                pageSize: pageSize,
                toolbarButtonAlignment: 'right'
              }}
              detailPanel={[
                (rowData) => ({
                  render: (rowData) => {
                    return (
                      <div style={{ width: '100%' }}>
                        <DialogContent dividers style={{ width: '100%', height: '100%' }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                              <strong>Data Início:</strong>{' '}
                              {rowData.Início ? rowData.Início : ''}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <strong>Data Término:</strong>{' '}
                              {rowData.Término ? rowData.Término : 'Não finalizado'}
                            </Grid>
                            {authService.getUserInfo().usuario.classificacaoPrestador !== 2 && (
                              <Grid item xs={12} sm={12}>
                                <strong>Número OS:</strong> {rowData.OS}
                              </Grid>
                            )}
                          </Grid>

                          {authService.getUserInfo().usuario.classificacaoPrestador !== 2 && (
                            <div style={{ width: '100%', height: '100%' }}>
                              <Divider style={{ marginTop: 20, marginBottom: 10 }} />
                              <h3>Orçamento</h3>
                              {rowData.Servico_Orcamento && rowData.Servico_Orcamento.length > 0 ? (
                                <div style={{ overflowX: 'auto', width: '100%', height: '100%' }}>
                                  <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                    <thead>
                                      <tr style={{ borderBottom: '1px solid #ddd' }}>
                                        <th style={{ padding: '8px', textAlign: 'left' }}>Serviço/Produto</th>
                                        <th style={{ padding: '8px', textAlign: 'left' }}>Status</th>
                                        <th style={{ padding: '8px', textAlign: 'left' }}>Quantidade</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {rowData.Servico_Orcamento.map((orcamento, index) => (
                                        <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
                                          <td style={{ padding: '8px' }}>{orcamento}</td>
                                          <td style={{ padding: '8px' }}>
                                            {rowData.Status_aprovacao_orcamento && rowData.Status_aprovacao_orcamento[index]}
                                          </td>
                                          <td style={{ padding: '8px' }}>
                                            {rowData.Quantidade_Servico_Orcamento && rowData.Quantidade_Servico_Orcamento[index]}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <p>Nenhum orçamento encontrado.</p>
                              )}
                            </div>
                          )}
                        </DialogContent>
                      </div>
                    );
                  },
                }),
              ]}
              components={{
                Pagination: () => (
                  <TablePagination
                    style={{ padding: '0 0px', overflow: 'hidden', marginLeft: -25 }}
                    onPageChange={mudarPagina}
                    page={page}
                    count={totalDados}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                    rowsPerPage={pageSize}
                    labelRowsPerPage="Linhas"
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    onRowsPerPageChange={mudarTamanhoPagina}
                  />
                ),
              }}
            />
          </div>
        </Paper>
      </div >
    </>
  ) : (
    <>
      <MenuComponent />
      <OSVistoriaPageTecnico ordemServico={os} handleBack={handleCloseDialogVistoria} tipoVistoria="saida" itensOS={itensOS} nomeTecnico={nomeTecnico} />
    </>
  )
}
