import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class OrdemServicoService {
  async detalhesOS(na, areaAtendimentoID) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(
        `${_configAPITecnico.URI_API}/ordemservico/detalhes/${na}/${areaAtendimentoID}`,
        {
          method: 'GET',
          headers: _headerToken,
        },
      );

      if (response.status === 200) return await response.json();
    } catch (error) {
      console.log(error);
    }
  }

  async listaOrcamentoOS(na, areaAtendimentoID) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(
        `${_configAPITecnico.URI_API}/ordemservico/orcamento?AreaAtendimentoId=${areaAtendimentoID}&NumeroAgendamento=${na}`,
        {
          method: 'GET',
          headers: _headerToken,
        },
      );

      if (response.status === 200) return await response.json();
    } catch (error) {
      console.log(error);
    }
  }

  async addProduto(ordemservicoProduto) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/ordemservico/produto`, {
        method: 'Post',
        headers: _headerToken,
        body: ordemservicoProduto,
      });

      const resultado = await response.json();

      if (response.status === 200) {
        return {
          error: false,
          result: resultado,
        }
      }
      return {
        error: true,
        message: resultado.erro,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Erro ao executar a operação.',
      };
    }
  }

  async addVistoria(checkList) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/vistoria`, {
        method: 'Post',
        headers: _headerToken,
        body: checkList,
      });

      if (response.status === 201) return await response.json();
    } catch (error) {
      console.log(error);
    }
  }

  async consultaVistoria(agendamentoID, ordemservicoID) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/vistoria/${agendamentoID}/${ordemservicoID}`, {
        method: 'Get',
        headers: _headerToken,
      });

      if (response.status === 200) return await response.json();
    } catch (error) {
      console.log(error);
    }
  }

  async checkList(imgs) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/ordemservico/vistoriaimg`, {
        method: 'Post',
        headers: _headerToken,
        body: imgs,
      });

      if (response.status === 200) return await response.json();
    } catch (error) {
      console.log(error);
    }
  }

  async observacao(observacao) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/ordemservico/observacao`, {
        method: 'Put',
        headers: _headerToken,
        body: observacao,
      });

      if (response.status === 200)
        return {
          error: false,
          result: response.json(),
        };

      return { error: true, message: 'Não foi possível salvar a OS.' };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um problema ao tentar salvar a OS.',
      };
    }
  }

  async updateAnotacao(anotacao) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/atividadeDeServico/anotacao`, {
        method: 'Put',
        headers: _headerToken,
        body: anotacao,
      });

      if (response.status === 200)
        return {
          error: false,
          result: response.json(),
        };

      return { error: true, message: 'Não foi possível salvar a anotação.' };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um problema ao tentar salvar a anotação.',
      };
    }
  }

  async alteraProduto(ordemservicoProduto, itemOrdemID) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/ordemservico/produto/${itemOrdemID}`, {
        method: 'Put',
        headers: _headerToken,
        body: ordemservicoProduto,
      });

      const resultado = await response.json();

      if (response.status === 200) {
        return {
          error: false,
          result: resultado,
        }
      }
      return {
        error: true,
        message: resultado.erro,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Erro ao executar operação.',
      };
    }
  }

  async atualizar(ordemServico) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/ordemservico/atualizar`, {
        method: 'Put',
        headers: _headerToken,
        body: ordemServico,
      });

      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async iniciarAtividadeDeServico(atividadeId) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/atividadeDeServico/${atividadeId}/iniciar`, {
        method: 'Put',
        headers: _headerToken,
      });

      return await response.json();
    } catch (error) {
      console.log(error);
    }
  }

  async terminarAtividadeDeServico(atividadeId) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/atividadeDeServico/${atividadeId}/terminar`, {
        method: 'Put',
        headers: _headerToken,
      });

      if (response.status === 200) return await response.json();
    } catch (error) {
      console.log(error);
    }
  }

  async salvarAnotacao(anotacao) {
    const _headerToken = authService.headerAPITecnico();
    let response = await fetch(`${_configAPITecnico.URI_API}/atividadeDeServico/anotacao`, {
      method: 'Put',
      headers: _headerToken,
      body: anotacao,
    });

    return response;
  }

  async atualizarAnotacao(anotacaoId, anotacao) {
    const _headerToken = authService.headerAPITecnico();
    let response = await fetch(`${_configAPITecnico.URI_API}/atividadeDeServico/anotacao/${anotacaoId}`, {
      method: 'Patch',
      headers: _headerToken,
      body: anotacao,
    });

    return response;
  }

  async salvarImagensOS(Imagens, OrdemServicoID) {
    try {
      let dados = {
        Imagens,
        OrdemServicoID,
      };
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/ordemservico/checklistimg`, {
        method: 'Post',
        headers: _headerToken,
        body: JSON.stringify(dados),
      });

      if (response.status === 200)
        return {
          error: false,
          result: response.json(),
        };

      return { error: true, message: 'Não foi possível salvar as imagens, tente novamente mais tarde.' };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um problema ao tentar salvar as imagens.',
      };
    }
  }

  async buscarCorpoImagemOS(OrdemServicoID, nomeImagem) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/ordemservico/imagem/${OrdemServicoID}/${nomeImagem}`, {
        method: 'GET',
        headers: _headerToken,
      });
      if (response.status === 200)
        return {
          error: false,
          result: response.json(),
        };
      return { error: true, message: 'Não foi possível buscar a imagem, tentar novamente mais tarde.' };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um problema ao tentar buscar a imagem.',
      };
    }
  }
}
export default new OrdemServicoService();
