import React, { useEffect, useState } from 'react';
import { Container, Grid, Box, CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import authService from '../Services/auth/auth-service';
import estoqueService from '../Services/Estoque/estoque-service';
import MenuComponent from '../components/menu/menu-component';
import { MaterialTableCustom } from '../components/table/material-table-custom';
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import usuarioService from '../Services/Usuario/usuario-service';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AssignmentIcon from '@material-ui/icons/Assignment'
import CloseIcon from '@material-ui/icons/Close';
import TablePagination from '@material-ui/core/TablePagination';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '0 80px 40px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px 40px',
    },
  },
  searchIcon: {
    marginRight: 4,
  },
  searchInput: {
    flex: 1,
    paddingVertical: 8,
    fontSize: 18,
  },
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 0px 15px',
    borderRadius: 10,
  },
  mensagemStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
  },
  infos: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  search: {
    marginBottom: 40,
  },
  item: {
    marginBottom: 30,
  },
  avatar: {
    padding: 6,
    borderRadius: 50,
    backgroundColor: theme.palette.grey[500],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 5,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px',
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px',
  },
  blueButton: {
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      bottom: '10px',
      right: '10px',
      width: '30%',
    },
  },
}));

export default function EstoquePage({ microSigaID, handleClose, nomeTecnico }) {
  const classes = useStyles();
  const [estoqueItens, setEstoque] = useState([]);
  const permissaoTCL = authService.getUserInfo().usuario.classificacaoPrestador;
  const [usuarioGestor, setUsuarioGestor] = useState(false);
  const [isMobile, setISMobile] = useState(false)
  const mobile = useMediaQuery(useTheme().breakpoints.down(800))
  const [tableKey, setTableKey] = useState(0)
  const [usuarioEstoqueAtivo, setUsuarioEstoqueAtivo] = useState(false);
  const [carregandoTable, setCarregandoTable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openModalSeriais, setOpenModalSeriais] = useState(false);
  const [carregandoTableSeriais, setCarregandoTableSeriais] = useState(false);
  const [seriais, setSeriais] = useState([]);
  const [totalDadosSerial, setTotalDadosSerial] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSerial, setPageSerial] = useState(0);
  let [pageSize, setPageSize] = useState(10);
  let [pageSizeSerial, setPageSizeSerial] = useState(10);
  const [totalDados, setTotalDados] = useState(0);
  const [pesquisa, setPesquisa] = useState('');
  const [pesquisaSerial, setPesquisaSerial] = useState('');
  const [codigoProduto, setCodigoProduto] = useState('');
  const buscarUsuarioEstoqueAtivo = async () => {
    const response = await usuarioService.usuarioEstoqueAtivo(authService.getUserInfo().usuario.id);
    if (response.error) {
      return;
    } else {
      setUsuarioEstoqueAtivo(response);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await estoque();
      await buscarUsuarioEstoqueAtivo();
      setUsuarioGestor(authService.getUserInfo().usuario.gestor);
      setTableKey(tableKey + 1);
      setLoading(false);
    };

    fetchData();
  }, [mobile]);

  //CONSULTA ESTOQUE
  const estoque = async () => {
    setCarregandoTable(true);
    let area;
    if (microSigaID) {
      area = microSigaID;
    } else {
      area = authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;
    }

    const dados = {
      AreaAtendimentoId: area ? area : '',
      Limite: pageSize ? pageSize : 10,
      Pesquisa: pesquisa ? pesquisa : '',
      Pagina: page ? page : 0,
    }

    let estoqueLista = await estoqueService.listarEstoqueConsolidado(dados);

    if (estoqueLista.dados) {
      let estoqueAgrupado = {};

      estoqueLista.dados.forEach((item) => {
        const produtoKey = `${item.produtoNome}`;

        estoqueAgrupado[produtoKey] = {
          produto: item.produtoNome,
          codProduto: item.produtoCodigo,
          recusadoTecnico: item.quantidadeRecusadoTecnico,
          reversa: item.quantidadeReversa,
          devolver: item.quantidadeDevolver,
          reservado: item.quantidadeReservado,
          disponivel: item.quantidadeDisponivel,
          recusadoFabrica: item.quantidadeRecusadoFabrica,
        };
      });

      const estoqueFinal = Object.values(estoqueAgrupado);

      setEstoque(estoqueFinal);
      setTotalDados(estoqueLista.total)
      setCarregandoTable(false);
    }
    setCarregandoTable(false);
  };

  const buscarSeriaisEquipamentos = async (produtoCodigo, page, pageSize, pesquisa) => {
    setCarregandoTableSeriais(true);

    let area;
    if (microSigaID) {
      area = microSigaID;
    } else {
      area = authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;
    }

    const dados = {
      AreaAtendimentoId: area ? area : '',
      CodigoProduto: produtoCodigo ? produtoCodigo : '',
      Limite: pageSize ? pageSize : 10,
      Pesquisa: pesquisa ? pesquisa : '',
      Pagina: page ? page : 0,
    }

    const response = await estoqueService.listarSeriaisEquipamentoConsolidado(dados);
    if (response.dados) {
      setTotalDadosSerial(response.total)
      setSeriais(response.dados);
      setCarregandoTableSeriais(false);
    } else {
      setCarregandoTableSeriais(false);
      return;
    }
  }

  const abrirModalSeriais = async (produtoCodigo) => {
    setCodigoProduto(produtoCodigo);
    buscarSeriaisEquipamentos(produtoCodigo, pageSerial, pageSizeSerial, pesquisaSerial)
    setOpenModalSeriais(true);
  }

  const fecharModalSeriais = () => {
    setOpenModalSeriais(false);
    setSeriais([]);
    setCodigoProduto('');
    setPesquisaSerial('');
  }

  const valorPesquisaSerial = (nome) => {
    setPesquisaSerial(nome);
    buscarSeriaisEquipamentos(codigoProduto, 0, pageSizeSerial, nome);
  };

  const mudarPaginaSerial = (event, newPage) => {
    setPageSerial(newPage);
    buscarSeriaisEquipamentos(codigoProduto, newPage, pageSizeSerial, pesquisaSerial);
  };

  const mudarTamanhoPaginaSerial = (event) => {
    setPageSizeSerial(parseInt(event.target.value, 10));
    setPageSerial(0);
    buscarSeriaisEquipamentos(codigoProduto, 0, parseInt(event.target.value, 10), pesquisaSerial);
  };

  const valorPesquisa = (nome) => {
    setPesquisa(nome);
    estoque(0, pageSize, nome);
  };

  const mudarPagina = (event, newPage) => {
    setPage(newPage);
    estoque(newPage, pageSize, pesquisa);
  };

  const mudarTamanhoPagina = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
    estoque(0, parseInt(event.target.value, 10), pesquisa);
  };

  const getStatus = (rowData) => {
    if (rowData.quantidadeDevolver === 1) return 'Devolver';
    if (rowData.quantidadeDisponivel === 1) return 'Disponível';
    if (rowData.quantidadeReservado === 1) return 'Reservado';
    if (rowData.quantidadeReversa === 1) return 'Reversa';
    return 'Status Desconhecido';
  };

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <MenuComponent />
      {(permissaoTCL !== 2 && usuarioEstoqueAtivo == true) || authService.getUserInfo().usuario.gestor == true ? (
        <div style={{ margin: 30 }}>
          <h3 className={classes.tituloStyle}>Estoque</h3>
          <h4 className={classes.subtituloStyle}>
            <span
              onClick={() => {
                const userInfo = authService.getUserInfo();
                if (userInfo.usuario.gestor) {
                  window.location.assign('/gestor');
                } else {
                  window.location.assign('/agenda');
                }
              }}
              className={classes.linkStyle}
              style={{ cursor: 'pointer' }}
            >
              Home
            </span> {'>'} Estoque
          </h4>

          {authService.getUserInfo().usuario.gestor === true && nomeTecnico && nomeTecnico.trim() !== "" ? (
            <Box sx={{ marginLeft: 1 }}>
              <p style={{ fontWeight: 'bold', fontSize: '1rem' }} className={classes.subtituloStyle}>
                <IconButton onClick={handleClose} aria-label="voltar">
                  <ArrowBackIosIcon />
                </IconButton>
                {nomeTecnico}
              </p>
            </Box>
          ) : null}

          <Dialog
            aria-labelledby="customized-dialog-title"
            onClose={(event, reason) => {
              if (reason !== 'backdropClick') {
                fecharModalSeriais();
              }
            }}
            open={openModalSeriais}
            style={{ marginTop: 45, marginBottom: 10 }}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle style={{ position: 'sticky', top: 0, zIndex: 1100, backgroundColor: 'white' }}>
              <Grid container alignItems="center">
                <Grid item xs={10} sm={11}>
                  Seriais
                </Grid>
                <Grid item xs={2} sm={1}>
                  <IconButton onClick={fecharModalSeriais}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>

            <DialogContent dividers style={{ padding: 0 }}>
              <div key={tableKey} style={{ marginBottom: '5px', width: '100%', height: '100%' }}>
                <MaterialTableCustom
                  title={''}
                  key={tableKey}
                  columns={[
                    { title: 'Serial', field: 'produtoSerial' },
                    {
                      title: 'Status',
                      field: '',
                      render: rowData => getStatus(rowData)
                    }
                  ]}
                  data={seriais.length > 0 ? seriais : []}
                  onSearchChange={valorPesquisaSerial}
                  options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 25, 50, 100],
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage: carregandoTableSeriais ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                          <CircularProgress />
                        </div>
                      ) : 'Nenhum registro para exibir'
                    },
                    toolbar: {
                      searchTooltip: 'Pesquisar',
                      searchPlaceholder: 'Pesquisar',
                    },
                    pagination: {
                      labelRowsSelect: 'linhas',
                      labelDisplayedRows: '{from}-{to} de {count}',
                      firstTooltip: 'Primeira página',
                      previousTooltip: 'Página anterior',
                      nextTooltip: 'Próxima página',
                      lastTooltip: 'Última página',
                    },
                  }}
                  components={{
                    Pagination: () => (
                      <TablePagination
                        style={{ padding: '0 0px', overflow: 'hidden', marginLeft: -10 }}
                        onPageChange={mudarPaginaSerial}
                        page={pageSerial}
                        count={totalDadosSerial}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                        rowsPerPage={pageSizeSerial}
                        labelRowsPerPage="Linhas"
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        onRowsPerPageChange={mudarTamanhoPaginaSerial}
                      />
                    ),
                  }}
                />
              </div>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                style={{ float: 'right' }}
                onClick={fecharModalSeriais}
              >
                Fechar
              </Button>
            </DialogActions>
          </Dialog>

          <Paper className={classes.paper}>
            <div key={tableKey} style={{ marginBottom: '16px', overflowX: 'auto' }}>
              <MaterialTableCustom
                title=""
                columns={[
                  { title: 'Produto', field: 'produto' },
                  { title: 'Disponível', field: 'disponivel', hidden: mobile },
                  { title: 'Reservado', field: 'reservado', hidden: mobile },
                  { title: 'Devolver', field: 'devolver', hidden: mobile },
                  { title: 'Reversa', field: 'reversa', hidden: mobile },
                  { title: 'Recusado pelo Técnico', field: 'recusadoTecnico', hidden: mobile },
                  { title: 'Recusado pelo Fábrica', field: 'recusadoFabrica', hidden: mobile },
                  {
                    title: 'Serial', field: '', render: (rowData) => (
                      <Button onClick={() => abrirModalSeriais(rowData.codProduto)}>
                        <AssignmentIcon />
                      </Button>
                    ),
                    sorting: false
                  },
                ]}
                data={estoqueItens}
                onSearchChange={valorPesquisa}
                options={{
                  pageSize: 10,
                  pageSizeOptions: [10, 25, 50, 100],
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: carregandoTable ? (
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                        <CircularProgress />
                      </div>
                    ) : 'Nenhum registro para exibir'
                  },
                  toolbar: {
                    searchTooltip: 'Pesquisar',
                    searchPlaceholder: 'Pesquisar'
                  },
                  pagination: {
                    labelRowsSelect: 'linhas',
                    labelDisplayedRows: '{from}-{to} de {count}',
                    firstTooltip: 'Primeira página',
                    previousTooltip: 'Página anterior',
                    nextTooltip: 'Próxima página',
                    lastTooltip: 'Última página'
                  }
                }}
                detailPanel={[
                  (rowData) => ({
                    disabled: !mobile,
                    render: (rowData) => {
                      return (
                        <div style={{ width: '100%' }}>
                          <DialogContent dividers style={{ width: '100%', height: '100%' }}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12}>
                                <strong>Quantidade disponível:</strong>{' '}
                                {rowData.disponivel}
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <strong>Quantidade reservado:</strong>{' '}
                                {rowData.reservado}
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <strong>Quantidade devolvido:</strong>{' '}
                                {rowData.devolver}
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <strong>Quantidade reversa:</strong>{' '}
                                {rowData.reversa}
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <strong>Quantidade recusado pelo técnico:</strong>{' '}
                                {rowData.recusadoTecnico}
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <strong>Quantidade recusado pela fábrica:</strong>{' '}
                                {rowData.recusadoFabrica}
                              </Grid>
                            </Grid>
                          </DialogContent>
                        </div>
                      );
                    },
                  }),
                ]}
                components={{
                  Pagination: () => (
                    <TablePagination
                      style={{ padding: '0 0px', overflow: 'hidden', marginLeft: -10 }}
                      onPageChange={mudarPagina}
                      page={page}
                      count={totalDados}
                      labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                      rowsPerPage={pageSize}
                      labelRowsPerPage="Linhas"
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      onRowsPerPageChange={mudarTamanhoPagina}
                    />
                  ),
                }}
              />
            </div>
          </Paper>
        </div>
      ) : (
        <div style={{ margin: 30 }}>
          <h3 className={classes.tituloStyle}>Estoque</h3>
          <h4 className={classes.subtituloStyle}>
            <span
              onClick={() => {
                const userInfo = authService.getUserInfo();
                if (userInfo.usuario.gestor) {
                  window.location.assign('/gestor');
                } else {
                  window.location.assign('/agenda');
                }
              }}
              className={classes.linkStyle}
              style={{ cursor: 'pointer' }}
            >
              Home
            </span> {'>'} Estoque
          </h4>

          <Paper className={classes.paper}>
            <Grid container justifyContent="center">
              <h2 className={classes.mensagemStyle} style={{ padding: 10 }}>Você não tem permissão para acessar esta página!</h2>
            </Grid>
          </Paper>
        </div>
      )}
    </>
  );
}
