import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    TextField,
    useMediaQuery, useTheme,
    FormControl,
    Dialog, CircularProgress,
    DialogActions, DialogContent,
    DialogTitle, Button, DialogContentText,
    InputLabel, Select, MenuItem
} from '@material-ui/core';
import MenuComponent from '../components/menu/menu-component';
import { MaterialTableCustom } from '../components/table/material-table-custom';
import BlueButton from '../components/button/BlueButton';
import TablePagination from '@material-ui/core/TablePagination';
import Autocomplete from '@material-ui/lab/Autocomplete';
import authService from '../Services/auth/auth-service';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomAlert from '../components/alert/CustomAlert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import auditoriaService from '../Services/Auditoria/auditoria-service';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import liberacaoEstoqueGestorService from '../Services/liberacaoEstoque/liberacao-estoque-gestor-service';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: '0 80px 40px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 20px 40px',
        },
    },
    paper: {
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 0px 15px',
        borderRadius: 10,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '15px 10px',
        },
    },
    tituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
        marginBottom: '5px'
    },
    mensagemStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
    },
    subtituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#7F7F7F',
        fontSize: '16px',
        fontWeight: 'inherit',
        textAlign: 'start',
        padding: '0 0px',
        marginTop: '0px'
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    }
}));

export default function LiberacaoEstoqueGestorPage() {
    const [isMobile, setISMobile] = useState(false);
    const classes = useStyles();
    const mobile = useMediaQuery(useTheme().breakpoints.down(800));
    const [tecnicoID, setTecnicoID] = useState('');
    const [tecnicos, setTecnicos] = useState([]);
    const [page, setPage] = useState(0);
    let [pageSize, setPageSize] = useState(10);
    const [totalDados, setTotalDados] = useState(0);
    const [dadosInventario, setDadosInventario] = useState([]);
    const [carregandoTable, setCarregandoTable] = useState(false);
    const [carregandoTela, setCarregandoTela] = useState(true);
    const [carregandoCadastro, setCarregandoCadastro] = useState(false);
    const [carregandoEdicao, setCarregandoEdicao] = useState(false);
    const [permissaoGestor, setPermissaoGestor] = useState(0);
    const [tableKey, setTableKey] = useState(0);
    const [pesquisa, setPesquisa] = useState('');
    const [nomeTecnico, setNomeTecnico] = useState('');
    const [tecnicoStatus, setTecnicoStatus] = useState(false);
    const [abrirModalCadastro, setAbrirModalCadastro] = useState(false);
    const [abrirModalEditar, setAbrirModalEditar] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ title: '', body: '' });
    const [alertType, setAlertType] = useState('success');

    const abrirModalCadastroTecnico = async () => {
        setAbrirModalCadastro(true);
    }

    const fecharModalCadastroTecnico = async () => {
        setNomeTecnico('');
        setTecnicoID('');
        setAbrirModalCadastro(false);
    }

    const abrirModalEditarTecnico = async (tecnico) => {
        setNomeTecnico(tecnico.nome);
        setTecnicoStatus(tecnico.status);
        setAbrirModalEditar(true);
        setTecnicoID(tecnico.usuarioId);
    }

    const fecharModalEditarTecnico = async () => {
        setAbrirModalEditar(false);
        setNomeTecnico('');
        setTecnicoStatus('');
    }

    const handleAdicionarTecnico = async () => {
        if ((tecnicoID != null && tecnicoID !== '')) {
            setCarregandoCadastro(true);
            const dados = {
                usuarioId: tecnicoID,
            }

            const response = await liberacaoEstoqueGestorService.criarRegistroEstoqueLiberado(JSON.stringify(dados));

            if (response.error == false) {
                setAlertMessage({ title: 'Sucesso!', body: 'Técnico inserido com sucesso!' });
                setAlertType('success');
                setAlertOpen(true);
                setNomeTecnico('');
                fecharModalCadastroTecnico();
                buscarTecnicosEstoqueLiberado(page, pageSize, pesquisa);
            } else {
                setAlertMessage({ title: 'Erro!', body: response.message });
                setAlertType('error');
                setAlertOpen(true);
            }

            setCarregandoCadastro(false);
        } else {
            setAlertMessage({ title: 'Falha!', body: 'É necessário selecionar um técnico para realizar o cadastro.' });
            setAlertType('fail');
            setAlertOpen(true);
        }
    }

    const editarTecnico = async () => {
        setCarregandoEdicao(true);

        const response = await liberacaoEstoqueGestorService.editarStatusTecnicoLiberacaoEstoque(tecnicoID);
        if (response.error === false) {
            setAlertMessage({ title: 'Sucesso!', body: 'Status alterado com sucesso!' });
            setAlertType('success');
            setAlertOpen(true);
            buscarTecnicosEstoqueLiberado();
            fecharModalEditarTecnico();
        } else {
            setAlertMessage({ title: 'Erro!', body: response.message });
            setAlertType('error');
            setAlertOpen(true);

        }
        setCarregandoEdicao(false);
    }

    const buscarTecnicosEstoqueLiberado = async (page, pageSize, pesquisa) => {
        setCarregandoTable(true);
        try {
            const dados = {
                pagina: page ? page : 0,
                limite: pageSize ? pageSize : 10,
                nome: pesquisa ? pesquisa : '',
            }

            const response = await liberacaoEstoqueGestorService.tecnicosListagemEstoqueLiberado(authService.getUserInfo().usuario.localid, dados);

            if (response.dados) {
                setTotalDados(response.total)
                setDadosInventario(response.dados);
            }
        } catch (error) {
            setAlertMessage({ title: 'Erro!', body: 'Erro ao buscar dados do inventário.' });
            setAlertType('error');
            setAlertOpen(true);
        }
        setCarregandoTable(false)
    }

    const usuariosGestor = async () => {
        const response = await auditoriaService.listarTecnicosAuditoria();
        if (response.error) {
            setAlertMessage({ title: 'Erro!', body: response.message });
            setAlertType('error');
            setAlertOpen(true);
            return;
        } else {
            setTecnicos(response);
        }
    };

    const valorPesquisa = (nome) => {
        setPesquisa(nome);
        buscarTecnicosEstoqueLiberado(0, pageSize, nome);
    };

    const mudarPagina = (event, newPage) => {
        setPage(newPage);
        buscarTecnicosEstoqueLiberado(newPage, pageSize, pesquisa);
    };

    const mudarTamanhoPagina = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
        buscarTecnicosEstoqueLiberado(0, parseInt(event.target.value, 10), pesquisa);
    };

    useEffect(() => {
        setCarregandoTela(true);
        buscarTecnicosEstoqueLiberado(page, pageSize, pesquisa);
        usuariosGestor();
        setPermissaoGestor(authService.getUserInfo().usuario.gestor);
        setCarregandoTela(false);
        setTableKey(tableKey + 1)
    }, [mobile]);

    if (carregandoTela) {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            <MenuComponent />
            <CustomAlert
                open={alertOpen}
                message={alertMessage}
                type={alertType}
                onClose={() => setAlertOpen(false)}
            />
            {permissaoGestor === true ? (
                <>
                    <div style={{ margin: 30 }}>
                        <h3 className={classes.tituloStyle}>Liberação do Estoque</h3>
                        <h4 className={classes.subtituloStyle}>
                            <span
                                onClick={() => {
                                    const userInfo = authService.getUserInfo();
                                    if (userInfo.usuario.gestor) {
                                        window.location.assign('/gestor');
                                    } else {
                                        window.location.assign('/agenda');
                                    }
                                }}
                                className={classes.linkStyle}
                                style={{ cursor: 'pointer' }}
                            >
                                Home
                            </span> {'>'} Liberação do Estoque
                        </h4>

                        <Grid item xs={12} sm={3} style={{ float: 'right', marginBottom: 15 }}>
                            <BlueButton
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                                onClick={abrirModalCadastroTecnico}
                                style={{ height: 55 }}
                                startIcon={<PersonAddIcon />}
                            >
                                Cadastrar Técnico
                            </BlueButton>
                        </Grid>

                        <Dialog
                            aria-labelledby="customized-dialog-title"
                            onClose={fecharModalCadastroTecnico}
                            open={abrirModalCadastro}
                            style={{ marginTop: 35, marginBottom: 10 }}
                            disableBackdropClick
                            fullWidth
                            maxWidth="sm"
                        >
                            <DialogTitle>
                                <Grid container alignItems="center">
                                    <Grid item xs={10} sm={11}>
                                        Adicionar Técnico
                                    </Grid>
                                    <Grid item xs={2} sm={1}>
                                        <IconButton onClick={fecharModalCadastroTecnico}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <DialogContentText>
                                            Informe o técnico que será inserido:
                                        </DialogContentText>
                                        <FormControl variant="outlined" fullWidth className="limited-select" style={{ marginBottom: '16px' }}>
                                            <Autocomplete
                                                id="tecnicoID"
                                                options={tecnicos}
                                                getOptionLabel={(option) => option.nome}
                                                defaultValue={tecnicos.find((option) => option.id === tecnicoID)}
                                                onChange={(event, newValue) => {
                                                    setTecnicoID(newValue ? newValue.id : '');
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={<span>Selecione um técnico <span style={{ color: 'red' }}> *</span></span>}
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder="Selecione um técnico"
                                                        InputLabelProps={{
                                                            htmlFor: 'tecnicoID',
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            id: 'tecnicoID',
                                                        }}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </DialogContent>

                            <DialogActions style={{ justifyContent: 'space-around' }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    disabled={carregandoCadastro}
                                    onClick={fecharModalCadastroTecnico}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                >
                                    Cancelar
                                </Button>
                                <BlueButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={carregandoCadastro}
                                    onClick={handleAdicionarTecnico}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                >
                                    {carregandoCadastro ? 'Cadastrando...' : 'Cadastrar'} {carregandoCadastro && <CircularProgress size={20} />}
                                </BlueButton>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            aria-labelledby="customized-dialog-title"
                            onClose={fecharModalEditarTecnico}
                            open={abrirModalEditar}
                            style={{ marginTop: 35, marginBottom: 10 }}
                            disableBackdropClick
                            fullWidth
                            maxWidth="sm"
                        >
                            <DialogTitle>
                                <Grid container alignItems="center">
                                    <Grid item xs={10} sm={11}>
                                        Editar Técnico
                                    </Grid>
                                    <Grid item xs={2} sm={1}>
                                        <IconButton onClick={fecharModalEditarTecnico}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        {tecnicoStatus === true ? (
                                            <DialogContentText>
                                                Deseja <strong>bloquear</strong> o acesso ao estoque do técnico <strong>{nomeTecnico}</strong>?
                                            </DialogContentText>
                                        ) : (
                                            <DialogContentText>
                                                Deseja <strong>liberar</strong> o acesso ao estoque do técnico <strong>{nomeTecnico}</strong>?
                                            </DialogContentText>
                                        )}
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: 'space-around' }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    disabled={carregandoEdicao}
                                    onClick={fecharModalEditarTecnico}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                >
                                    Cancelar
                                </Button>
                                <BlueButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={carregandoEdicao}
                                    onClick={editarTecnico}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                >
                                    {carregandoEdicao ? 'Editando...' : (tecnicoStatus === true ? 'Bloquear' : 'Liberar')}
                                    {carregandoEdicao && <CircularProgress size={20} />}
                                </BlueButton>
                            </DialogActions>
                        </Dialog>

                        <Paper className={classes.paper}>
                            <div key={tableKey} style={{ marginBottom: '16px', overflowX: 'auto' }}>
                                <MaterialTableCustom
                                    key={pageSize}
                                    title=""
                                    columns={[
                                        { title: 'Código', field: 'id' },
                                        { title: 'Nome do Técnico', field: 'nome' },
                                        {
                                            title: 'Status',
                                            field: 'status',
                                            hidden: mobile,
                                            render: (rowData) => {
                                                let statusText = '';
                                                let bgColor = '';

                                                if (rowData.status === true) {
                                                    statusText = 'Liberado';
                                                    bgColor = 'rgba(3, 201, 169, 1)';
                                                } else if (rowData.status === false) {
                                                    statusText = 'Bloqueado';
                                                    bgColor = 'rgba(255, 99, 132, 1)';
                                                }

                                                return (
                                                    <div style={{
                                                        padding: '5px',
                                                        fontSize: '75%',
                                                        fontWeight: '700',
                                                        color: '#fff',
                                                        textAlign: 'center',
                                                        whiteSpace: 'nowrap',
                                                        verticalAlign: 'baseline',
                                                        borderRadius: '10px',
                                                        backgroundColor: bgColor
                                                    }}>
                                                        {statusText}
                                                    </div>
                                                );
                                            },
                                        },
                                        { title: 'Data Cadastro', field: 'dataCadastro', render: (rowData) => rowData.dataCadastro ? moment(rowData.dataCadastro).format('DD/MM/YYYY HH:mm:ss') : '', hidden: mobile },
                                        { title: 'Data Atualização', field: 'dataAtualizacao', render: (rowData) => rowData.dataAtualizacao ? moment(rowData.dataAtualizacao).format('DD/MM/YYYY HH:mm:ss') : '', hidden: mobile },
                                        {
                                            title: 'Ações',
                                            field: '',
                                            render: (rowData) => (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '40px',
                                                        height: '40px',
                                                        backgroundColor: '#209BDE',
                                                        borderRadius: '5px',
                                                        border: '2px solid #209BDE',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => abrirModalEditarTecnico(rowData)}
                                                >
                                                    <EditIcon
                                                        style={{ color: '#fff' }}
                                                    />
                                                </div>
                                            ),
                                            hidden: mobile,
                                        }
                                    ]}
                                    isLoading={carregandoTable}
                                    data={dadosInventario}
                                    options={{
                                        search: true,
                                        pageSize: pageSize,
                                    }}
                                    onSearchChange={valorPesquisa}
                                    localization={{
                                        body: {
                                            emptyDataSourceMessage: 'Nenhum registro para exibir',
                                        },
                                        toolbar: {
                                            searchTooltip: 'Pesquisar',
                                            searchPlaceholder: 'Pesquisar',
                                        },
                                    }}
                                    detailPanel={[
                                        (rowData) => ({
                                            disabled: !mobile,
                                            render: (rowData) => {
                                                return (
                                                    <div style={{ width: '100%' }}>
                                                        <DialogContent dividers style={{ width: '100%', height: '100%' }}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} sm={12}>
                                                                    <strong>Status:</strong>{' '}
                                                                    <div style={{
                                                                        padding: '5px',
                                                                        fontSize: '75%',
                                                                        fontWeight: '700',
                                                                        color: '#fff',
                                                                        textAlign: 'center',
                                                                        whiteSpace: 'nowrap',
                                                                        verticalAlign: 'baseline',
                                                                        borderRadius: '10px',
                                                                        backgroundColor: rowData.status === true
                                                                            ? 'rgba(3, 201, 169, 1)'
                                                                            : 'rgba(255, 99, 132, 1)'
                                                                    }}>
                                                                        {rowData.status === true
                                                                            ? 'Liberado'
                                                                            : 'Bloqueado'}
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12} sm={12}>
                                                                    <strong>Data Cadastro:</strong>{' '}
                                                                    {moment(rowData.dataCadastro).format('DD/MM/YYYY HH:mm:ss')}
                                                                </Grid>
                                                                <Grid item xs={12} sm={12}>
                                                                    <strong>Data Atualização:</strong>{' '}
                                                                    {moment(rowData.dataAtualizacao).format('DD/MM/YYYY HH:mm:ss')}
                                                                </Grid>
                                                                <Grid container spacing={2} alignItems="center" justifyContent="flex-end" style={{ margin: 5 }}>
                                                                    <Grid item xs={12} md={6} lg={3}>
                                                                        <BlueButton
                                                                            fullWidth
                                                                            variant="contained"
                                                                            color="primary"
                                                                            size="large"
                                                                            startIcon={<EditIcon />}
                                                                            onClick={() => abrirModalEditarTecnico(rowData)}>
                                                                            Alterar Status
                                                                        </BlueButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </DialogContent>
                                                    </div>
                                                );
                                            },
                                        }),
                                    ]}
                                    components={{
                                        Pagination: () => (
                                            <TablePagination
                                                style={{ padding: '0 0px', overflow: 'hidden', marginLeft: -15 }}
                                                component="div"
                                                onPageChange={mudarPagina}
                                                page={page}
                                                count={totalDados}
                                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                                rowsPerPage={pageSize}
                                                labelRowsPerPage="Linhas"
                                                rowsPerPageOptions={[10, 25, 50, 100]}
                                                onRowsPerPageChange={mudarTamanhoPagina}
                                            />
                                        ),
                                    }}
                                />
                            </div>
                        </Paper>
                    </div>
                </>
            ) : (
                <div style={{ margin: 30 }}>
                    <h3 className={classes.tituloStyle}>Inventário</h3>
                    <h4 className={classes.subtituloStyle}>
                        <span
                            onClick={() => {
                                const userInfo = authService.getUserInfo();
                                if (userInfo.usuario.gestor) {
                                    window.location.assign('/gestor');
                                } else {
                                    window.location.assign('/agenda');
                                }
                            }}
                            className={classes.linkStyle}
                            style={{ cursor: 'pointer' }}
                        >
                            Home
                        </span> {'>'} Liberação do Estoque
                    </h4>
                    <Paper className={classes.paper}>
                        <Grid container justifyContent="center">
                            <h2 className={classes.mensagemStyle}>Você não tem permissão para acessar esta página!</h2>
                        </Grid>
                    </Paper>
                </div>
            )}
        </>
    );
}
