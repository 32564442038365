import React, { useState, useEffect } from 'react';
import MenuComponent from '../components/menu/menu-component';
import Box from '@material-ui/core/Box';
import BlueButton from '../components/button/BlueButton';
import Paper from '@material-ui/core/Paper';
import LockIcon from '@material-ui/icons/Lock';
import TrocarSenha from './trocar-senha';
import { makeStyles } from '@material-ui/core/styles';
import perfilService from '../Services/Perfil/perfil-service';
import { CircularProgress, Container } from '@material-ui/core';
import authService from '../Services/auth/auth-service';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(2),
	},
	paper: {
		marginBottom: 20,
		display: 'flex',
		flexDirection: 'column',
		padding: '15px 20px 15px',
		borderRadius: 10,
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			padding: '20px 10px',
		},
		position: 'relative',
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(4),
	},
	infoBox: {
		margin: theme.spacing(2),
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'start',
		width: '100%',
	},
	infoItem: {
		marginBottom: theme.spacing(1),
	},
	sectionTitle: {
		fontSize: '1.2rem',
		fontWeight: 'bold',
		marginBottom: theme.spacing(1),
	},
	loadingContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
	},
	smallButton: {
		width: '150px',
	},
	tituloStyle: {
		fontFamily: 'Mont, sans-serif',
		color: '#209BDE',
		fontSize: '22px',
		textAlign: 'start',
		padding: '0 0px',
		marginBottom: '5px',
	},
	subtituloStyle: {
		fontFamily: 'Mont, sans-serif',
		color: '#7F7F7F',
		fontSize: '16px',
		fontWeight: 'inherit',
		textAlign: 'start',
		padding: '0 0px',
		marginTop: '0px',
	},
	blueButton: {
		[theme.breakpoints.up('sm')]: {
			position: 'absolute',
			bottom: '10px',
			right: '10px',
			width: '30%',
		},
	},
}));

const PerfilPage = (props) => {
	const classes = useStyles();
	const [openSenha, setOpenSenha] = useState(false);
	const [loading, setLoading] = useState(true);
	const [perfilInfo, setPerfilInfo] = useState({});

	useEffect(() => {
		async function fetchPerfilInfo() {
			setLoading(true);
			try {
				const response = await perfilService.getInformacoesTecnico();
				setPerfilInfo(response);
			} catch (error) {
				console.error('Erro ao buscar informações do perfil', error);
			}
			setLoading(false);
		}

		fetchPerfilInfo();
	}, []);

	const handleOpenSenha = () => {
		setOpenSenha(true);
	};

	const handleCloseSenha = () => {
		setOpenSenha(false);
	};

	if (loading) {
		return (
			<div className={classes.loadingContainer}>
				<CircularProgress />
			</div>
		);
	}

	return (
		<>
			<MenuComponent />
			<div style={{ margin: 30 }}>
				<TrocarSenha isOpen={openSenha} handleClose={handleCloseSenha} />
				<h3 className={classes.tituloStyle}>Minha conta</h3>
				<h4 className={classes.subtituloStyle}>
					<span
						onClick={() => {
							const userInfo = authService.getUserInfo();
							if (userInfo.usuario.gestor) {
								window.location.assign('/gestor');
							} else {
								window.location.assign('/agenda');
							}
						}}
						className={classes.linkStyle}
						style={{ cursor: 'pointer' }}
					>
						Home
					</span>{' '}
					{'>'} Minha Conta
				</h4>
				<Paper style={{ display: 'flex' }} className={classes.paper}>
					<Box>
						<Box className={classes.infoBox}>
							<div className={classes.sectionTitle}>Dados Pessoais</div>
							<div className={classes.infoItem}>
								<strong>Nome:</strong> {perfilInfo?.nome}
							</div>
							<div className={classes.infoItem}>
								<strong>E-mail:</strong> {perfilInfo?.email}
							</div>
							<div className={classes.infoItem}>
								<strong>Telefone:</strong> {perfilInfo?.telefone1}{' '}
								{perfilInfo?.telefone2 != null
									? '| ' + perfilInfo?.telefone2
									: ''}
							</div>
						</Box>
						<Box className={classes.infoBox}>
							<div className={classes.sectionTitle}>Endereço</div>
							<div className={classes.infoItem}>
								<strong>Endereço:</strong> {perfilInfo?.rua}, {perfilInfo?.numero}
							</div>
							<div className={classes.infoItem}>
								<strong>Bairro:</strong> {perfilInfo?.bairro}
							</div>
							<div className={classes.infoItem}>
								<strong>CEP:</strong> {perfilInfo?.cep}
							</div>
							<div className={classes.infoItem}>
								<strong>Cidade:</strong> {perfilInfo?.cidade} - {perfilInfo?.uf}
							</div>
							<div className={classes.infoItem}>
								<strong>Pais:</strong> {perfilInfo?.pais}
							</div>
						</Box>
					</Box>

					<BlueButton
						variant="contained"
						color="primary"
						size="large"
						onClick={handleOpenSenha}
						startIcon={<LockIcon />}
						className={classes.blueButton}
					>
						Trocar Senha
					</BlueButton>
				</Paper>
			</div>
		</>
	);
};

export default PerfilPage;
