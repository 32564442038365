import config from '../../config';

const configAPIGateway = config.configAPIGateway();
const _configAPITecnico = config.configAPITecnico();

class AuthService {

  async tokenGateway() {

    try {

      let response = await fetch(`${configAPIGateway.URI_GATEWAY}/token?usuario=${configAPIGateway.API_USER}&senha=${configAPIGateway.API_PASS}`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
      });

      if (response.status === 200)
        return await response.json();

      return null;

    } catch (error) {
      console.log(error);
    }
  }

  async login(usuario) {
    try {


      let response = await fetch(`${_configAPITecnico.URI_API}/usuario/login`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: usuario
      });

      if (response.status === 200)
        return await response.json();

      return null;


    } catch (error) {
      console.log(error);

    }
  }

  logOut() {
    localStorage.removeItem('userInfo');
    return null;
  }

  getUserInfo() {
    return JSON.parse(localStorage.getItem('userInfo'));
  }

  headerAPITecnico() {
    if (!localStorage.getItem('userInfo'))
      return {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      };

    return ({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`
    });
  }
}
export default new AuthService();
