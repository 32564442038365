import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  List,
  Box,
  ListItem,
  Dialog,
  DialogTitle,
  ListItemIcon,
  DialogActions,
  Button,
  DialogContent,
  ListItemText,
  Typography,
  Divider,
  Grid,
  IconButton,
} from '@material-ui/core';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import DataUtils from '../../util/dataUtils';
import { AgendamentoDialog } from './agendamento-dialog';
import ordemServicoServico from '../../Services/OrdemServico/ordem-servico-servico';
import { CircularProgress } from '@mui/material';
import CustomAlert from '../../components/alert/CustomAlert';
import CloseIcon from '@material-ui/icons/Close';
import BlueButton from '../../components/button/BlueButton';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
    marginTop: 37,
    height: 465,
  },
  cardHeader: {
    backgroundColor: '#209BDE',
    color: 'white',
    fontSize: '16px',
  },
  listItem: {
    cursor: 'pointer',
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
  },
  scrollableList: {
    maxHeight: 400,
    overflowY: 'auto',
  },
  title: {
    fontWeight: 'bold',
  },
  value: {
    fontWeight: 'normal',
  },
  status: {
    textAlign: 'center',
    display: 'block',
    borderRadius: 5,
    color: 'white',
    width: 'fit-content',
    padding: '5px',
  },
}));

const DailyAgenda = ({ eventos, dataSelecionada, loading }) => {
  const classes = useStyles();
  const history = useHistory();

  const [atividadeServico, setAtividadeServico] = useState(null);
  const [openDialogConfirmaInicioAtividade, setOpenDialogConfirmaInicioAtividade] = useState(false);
  const [alert, setAlert] = useState({ open: false, message: '', type: '' });

  useEffect(() => {
    if (alert.open) {
      const timer = setTimeout(() => {
        setAlert({ open: false, message: '', type: '' });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [alert]);

  const fechaDetalhesAtividade = () => {
    setAtividadeServico(null);
  };

  const handleOrdemServico = () => {
    setOpenDialogConfirmaInicioAtividade(true);
  };

  const handleDetalhesOrdemServico = () => {
    history.push({
      pathname: '/ordemservico',
      state: {
        na: atividadeServico.na,
      },
    });
  };

  const handleDetalheAtividade = async (e) => {
    const evento = eventos.find((x) => x.atividadeID == e.atividadeID);

    setAtividadeServico(evento);
  };

  const handleIniciarAtividadeDeServico = async () => {
    const iniciaAtividadeDeServico = await ordemServicoServico.iniciarAtividadeDeServico(
      atividadeServico.atividadeID,
    );

    if (iniciaAtividadeDeServico) {
      if (iniciaAtividadeDeServico.erro) {
        setAlert({ open: true, message: 'Erro ao iniciar atividade de serviço!', type: 'error' });
      } else {
        history.push({
          pathname: '/ordemservico',
          state: {
            na: atividadeServico.na,
          },
        });
      }
    } else {
      setAlert({ open: true, message: 'A atividade já foi iniciada!', type: 'fail' });
    }
  };

  const getStatusStyle = (status) => {
    const statusStyles = {
      0: {
        background: '#3174ad',
        label: 'Agendado',
      },
      1: {
        background: '#73ae2e',
        label: 'Concluído',
      },
      2: {
        background: 'red',
        label: 'Cancelado',
      },
      3: {
        background: '#3174ad',
        label: 'Agendado',
      },
    };

    return statusStyles[status] || statusStyles.default;
  };

  return (
    <>
      <CustomAlert
        open={alert.open}
        message={{ title: alert.type === 'error' ? 'Erro!' : 'Falha!', body: alert.message }}
        type={alert.type}
        onClose={() => setAlert({ open: false, message: '', type: '' })}
      />

      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={
            <Typography>
              Atividades da Agenda - {dataSelecionada.toLocaleDateString('pt-BR')}
            </Typography>
          }
        />
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Divider />
            {eventos.length === 0 ? (
              <Typography variant="body2" style={{ padding: '16px' }}>
                Não foram encontradas atividades nesse período.
              </Typography>
            ) : (
              <List className={classes.scrollableList}>
                {eventos.map((evento, index) => (
                  <React.Fragment key={evento.id}>
                    <ListItem className={classes.listItem} onClick={() => handleDetalheAtividade(evento)}>
                      <ListItemIcon>
                        <EventNoteIcon />
                      </ListItemIcon>
                      <Box>
                        <ListItemText
                          primary={
                            <Typography className={classes.title}>
                              {evento.assunto}
                            </Typography>
                          }
                          secondary={
                            <>
                              <Typography variant="body2" color="textSecondary">
                                <span className={classes.title}>Cliente:</span>{' '}
                                <span className={classes.value}>{evento.nomeCliente}</span>
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                <span className={classes.title}>NA:</span>{' '}
                                <span className={classes.value}>{evento.na}</span>
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                <span className={classes.title}>Série:</span>{' '}
                                {evento.numSerieRastreadorInstalado} <span className={classes.title}>Placa:</span>{' '}
                                <span className={classes.value}>{evento.veiculoIdName}</span>
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                <span className={classes.title}>Servico:</span>{' '}
                                <span className={classes.value}>{evento.servico}</span>
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                <span className={classes.title}>Previsão de Início:</span>{' '}
                                {DataUtils.formatarHora(evento.previsaoInicio)}
                              </Typography>
                            </>
                          }
                        />
                        {getStatusStyle(evento.status) && (
                          <Box
                            className={classes.status}
                            style={{
                              background: getStatusStyle(evento.status).background,
                            }}
                          >
                            {getStatusStyle(evento.status).label}
                          </Box>
                        )}
                      </Box>
                    </ListItem>
                    {index < eventos.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            )}
          </>
        )}
      </Card>

      <Dialog
        aria-labelledby="customized-dialog-title"
        onClose={() => setOpenDialogConfirmaInicioAtividade(false)}
        open={openDialogConfirmaInicioAtividade}
        style={{ marginTop: 45, marginBottom: 10 }}
        disableBackdropClick
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle style={{ position: 'sticky', top: 0, zIndex: 1100, backgroundColor: 'white' }}>
          <Grid container alignItems="center">
            <Grid item xs={10} sm={11}>
              Iniciar Atividade de Serviço
            </Grid>
            <Grid item xs={2} sm={1}>
              <IconButton onClick={() => setOpenDialogConfirmaInicioAtividade(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent dividers>
          <Typography variant="subtitle1" gutterBottom style={{ textAlign: 'start', marginBottom: '16px' }}>
            Depois de iniciada, a atividade não poderá mais ser interrompida. Tem certeza de que deseja
            iniciar a atividade?
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setOpenDialogConfirmaInicioAtividade(false)}
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', float: 'right' }}
          >
            Fechar
          </Button>

          <BlueButton
            variant="contained"
            fullWidth
            onClick={handleIniciarAtividadeDeServico}
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%' }}
          >
            Confirmar
          </BlueButton>
        </DialogActions>
      </Dialog>

      {atividadeServico && (
        <AgendamentoDialog
          fullscreen
          atividadeServico={atividadeServico}
          handleClose={fechaDetalhesAtividade}
          handleOrdemServico={handleOrdemServico}
          handleDetalhesOrdemServico={handleDetalhesOrdemServico}
        />
      )}
    </>
  );
};

export default DailyAgenda;