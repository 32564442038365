import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    useMediaQuery, useTheme,
    CircularProgress,
    DialogContent
} from '@material-ui/core';
import MenuComponent from '../components/menu/menu-component';
import { MaterialTableCustom } from '../components/table/material-table-custom';
import TablePagination from '@material-ui/core/TablePagination';
import authService from '../Services/auth/auth-service';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomAlert from '../components/alert/CustomAlert';
import inventarioService from '../Services/inventario/inventario-service';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: '0 80px 40px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 20px 40px',
        },
    },
    paper: {
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 0px 15px',
        borderRadius: 10,
    },
    tituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
        marginBottom: '5px'
    },
    mensagemStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
    },
    subtituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#7F7F7F',
        fontSize: '16px',
        fontWeight: 'inherit',
        textAlign: 'start',
        padding: '0 0px',
        marginTop: '0px'
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    }
}));

export default function InventarioPage() {
    const [isMobile, setISMobile] = useState(false);
    const classes = useStyles();
    const mobile = useMediaQuery(useTheme().breakpoints.down(800));
    const [page, setPage] = useState(0);
    let [pageSize, setPageSize] = useState(10);
    const [totalDados, setTotalDados] = useState(0);
    const [dadosInventario, setDadosInventario] = useState([]);
    const [carregandoTable, setCarregandoTable] = useState(false);
    const [carregandoTela, setCarregandoTela] = useState(true);
    const [permissaoGestor, setPermissaoGestor] = useState(0);
    const [tableKey, setTableKey] = useState(0);
    const [pesquisa, setPesquisa] = useState('');
    const [abrirAlert, setAbrirAlert] = useState(false);
    const [mensagemAlert, setMensagemAlert] = useState('');
    const [tipoAlert, setTipoAlert] = useState('error');

    const buscarInventarios = async (page, pageSize, pesquisa) => {
        setCarregandoTable(true);
        try {
            const dados = {
                pagina: page ? page : 0,
                limite: pageSize ? pageSize : 10,
                nome: pesquisa ? pesquisa : '',
            }

            const response = await inventarioService.listarInventario(authService.getUserInfo().usuario.id, dados);

            if (response.dados) {
                setTotalDados(response.total)
                setDadosInventario(response.dados);
            }
        } catch (error) {
            setMensagemAlert({ title: 'Erro!', body: 'Erro ao buscar dados do inventário.' });
            setTipoAlert('error');
            setAbrirAlert(true);
        }
        setCarregandoTable(false)
    }

    const valorPesquisa = (nome) => {
        setPesquisa(nome);
        buscarInventarios(0, pageSize, nome);
    };

    const mudarPagina = (event, newPage) => {
        setPage(newPage);
        buscarInventarios(newPage, pageSize, pesquisa);
    };

    const mudarTamanhoPagina = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
        buscarInventarios(0, parseInt(event.target.value, 10), pesquisa);
    };

    useEffect(() => {
        setCarregandoTela(true);
        buscarInventarios(page, pageSize, pesquisa);
        setPermissaoGestor(authService.getUserInfo().usuario.gestor);
        setCarregandoTela(false);
        setTableKey(tableKey + 1)
    }, [mobile]);

    if (carregandoTela) {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            <MenuComponent />
            <CustomAlert
                open={abrirAlert}
                message={mensagemAlert}
                type={tipoAlert}
                onClose={() => setAbrirAlert(false)}
            />
            {permissaoGestor === true ? (
                <>
                    <div style={{ margin: 30 }}>
                        <h3 className={classes.tituloStyle}>Inventário</h3>
                        <h4 className={classes.subtituloStyle}>
                            <span
                                onClick={() => {
                                    const userInfo = authService.getUserInfo();
                                    if (userInfo.usuario.gestor) {
                                        window.location.assign('/gestor');
                                    } else {
                                        window.location.assign('/agenda');
                                    }
                                }}
                                className={classes.linkStyle}
                                style={{ cursor: 'pointer' }}
                            >
                                Home
                            </span> {'>'} Inventário
                        </h4>

                        <Paper className={classes.paper}>
                            <div key={tableKey} style={{ marginBottom: '16px', overflowX: 'auto' }}>
                                <MaterialTableCustom
                                    key={pageSize}
                                    title=""
                                    columns={[
                                        { title: 'Produto Número', field: 'produtoCodigo' },
                                        { title: 'Produto Nome', field: 'produtoNome' },
                                        { title: 'Serial', field: 'serie', hidden: mobile },
                                        { title: 'Data Cadastro', field: 'dataCadastro', render: (rowData) => rowData.dataCadastro ? moment(rowData.dataCadastro).format('DD/MM/YYYY HH:mm:ss') : '', hidden: mobile },
                                        { title: 'Data Atualização', field: 'dataAlteracao', render: (rowData) => rowData.dataAlteracao ? moment(rowData.dataAlteracao).format('DD/MM/YYYY HH:mm:ss') : '', hidden: mobile },
                                    ]}
                                    isLoading={carregandoTable}
                                    data={dadosInventario}
                                    options={{
                                        search: true,
                                        pageSize: pageSize,
                                    }}
                                    onSearchChange={valorPesquisa}
                                    localization={{
                                        body: {
                                            emptyDataSourceMessage: 'Nenhum registro para exibir',
                                        },
                                        toolbar: {
                                            searchTooltip: 'Pesquisar',
                                            searchPlaceholder: 'Pesquisar',
                                        },
                                    }}
                                    detailPanel={[
                                        () => ({
                                            disabled: !mobile,
                                            render: (rowData) => {
                                                return (
                                                    <div style={{ width: '100%' }}>
                                                        <DialogContent dividers style={{ width: '100%', height: '100%' }}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} sm={12}>
                                                                    <strong>Serial:</strong>{' '}
                                                                    {rowData.serie}
                                                                </Grid>
                                                                <Grid item xs={12} sm={12}>
                                                                    <strong>Data Cadastro:</strong>{' '}
                                                                    {moment(rowData.dataCadastro).format('DD/MM/YYYY HH:mm:ss')}
                                                                </Grid>
                                                                <Grid item xs={12} sm={12}>
                                                                    <strong>Data Atualização:</strong>{' '}
                                                                    {moment(rowData.dataAtualizacao).format('DD/MM/YYYY HH:mm:ss')}
                                                                </Grid>
                                                            </Grid>
                                                        </DialogContent>
                                                    </div>
                                                );
                                            },
                                        }),
                                    ]}
                                    components={{
                                        Pagination: () => (
                                            <TablePagination
                                                style={{ padding: '0 0px' }}
                                                component="div"
                                                onPageChange={mudarPagina}
                                                page={page}
                                                count={totalDados}
                                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                                rowsPerPage={pageSize}
                                                labelRowsPerPage="Linhas"
                                                rowsPerPageOptions={[10, 25, 50, 100]}
                                                onRowsPerPageChange={mudarTamanhoPagina}
                                            />
                                        ),
                                    }}
                                />
                            </div>
                        </Paper>
                    </div>
                </>
            ) : (
                <div style={{ margin: 30 }}>
                    <h3 className={classes.tituloStyle}>Inventário</h3>
                    <h4 className={classes.subtituloStyle}>
                        <span
                            onClick={() => {
                                const userInfo = authService.getUserInfo();
                                if (userInfo.usuario.gestor) {
                                    window.location.assign('/gestor');
                                } else {
                                    window.location.assign('/agenda');
                                }
                            }}
                            className={classes.linkStyle}
                            style={{ cursor: 'pointer' }}
                        >
                            Home
                        </span> {'>'} Inventário
                    </h4>
                    <Paper className={classes.paper}>
                        <Grid container justifyContent="center">
                            <h2 className={classes.mensagemStyle} style={{ padding: 10 }}>Você não tem permissão para acessar esta página!</h2>
                        </Grid>
                    </Paper>
                </div>
            )}
        </>
    );
}
