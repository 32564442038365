import React, { useEffect, useState } from 'react';
import { Button, TextField, Box, CircularProgress, FormControl, DialogContent, TablePagination } from '@material-ui/core';
import MenuComponent from '../components/menu/menu-component';
import authService from '../Services/auth/auth-service';
import notaFiscalService from '../Services/NotaFiscal/nota-fiscal-service';
import DetalheNotaFiscal from './components/detalhe-nota-fiscal';
import ContestacaoNotaFiscal from './components/contestacao-nota-fiscal';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { MaterialTableCustom } from '../components/table/material-table-custom';
import BlueButton from '../components/button/BlueButton';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import usuarioService from '../Services/Usuario/usuario-service';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CustomAlert from '../components/alert/CustomAlert';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '0 80px 40px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px 40px',
    },
  },
  searchIcon: {
    marginRight: 4,
  },
  searchInput: {
    flex: 1,
    paddingVertical: 8,
    fontSize: 18,
  },
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 0px 15px',
    borderRadius: 10,
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px'
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px'
  },
  mensagemStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
  },
  infos: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  search: {
    marginBottom: 40,
  },
  item: {
    marginBottom: 30,
  },
  avatar: {
    padding: 6,
    borderRadius: 50,
    backgroundColor: theme.palette.grey[500],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 5,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
}));

export default function NotasFiscaisPage({ microSigaID, handleCloseNFGestorTecnico, nomeTecnico }) {
  const classes = useStyles();
  const [notasItens, setNotasItens] = useState([]);
  const [notasFiltradas, setNotasFiltradas] = useState([]);
  const [nf, setNF] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [ContestacaoNF, setContestacaoNF] = useState(null);
  const [permissaoTCL, setPermissaoTCL] = useState(0);
  const [isMobile, setISMobile] = useState(false)
  const mobile = useMediaQuery(useTheme().breakpoints.down(800))
  const [tableKey, setTableKey] = useState(0);
  const [usuarioEstoqueAtivo, setUsuarioEstoqueAtivo] = useState(false);
  const [totalDados, setTotalDados] = useState(0);
  const [carregandoTable, setCarregandoTable] = useState(false);
  const [pesquisa, setPesquisa] = useState('');
  const [page, setPage] = useState(0);
  let [pageSize, setPageSize] = useState(10);
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState('');
  const [tipoAlert, setTipoAlert] = useState('error');
  const [loading, setLoading] = useState(true);
  const usuarioGestor = authService.getUserInfo().usuario.gestor == true ? true : false;
  const tecnicoPrestador = authService.getUserInfo().usuario.tecnico;

  const buscarUsuarioEstoqueAtivo = async () => {
    const response = await usuarioService.usuarioEstoqueAtivo(authService.getUserInfo().usuario.id);
    if (response.error) {
      return;
    } else {
      setUsuarioEstoqueAtivo(response);
    }
  }

  const handleClick = (nota) => {
    setNF(nota);
    setShowDetails(!showDetails);
  };

  const handleClickContestacaoNF = (nota) => {
    setContestacaoNF(nota);
    setShowDetails(!showDetails);
  };

  const handleClose = () => {
    setNF(null);
    valorPesquisa('');
    setTableKey(tableKey + 1);
    setContestacaoNF(null);
    setShowDetails(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      notasFiscais();
      buscarUsuarioEstoqueAtivo();
      setPermissaoTCL(authService.getUserInfo().usuario.classificacaoPrestador);
      if (mobile) {
        setTableKey(tableKey + 1);
      } else {
        setTableKey(tableKey + 1);
      }
      setLoading(false);
    };

    fetchData();
  }, [mobile]);

  const notasFiscais = async (dataInicial, dataFinal, page, pageSize, pesquisa) => {
    setCarregandoTable(true);

    if (dataInicial && dataFinal && moment(dataInicial).isAfter(dataFinal)) {
      setMensagemAlert({ title: 'Falha!', body: 'Data inicial não pode ser maior que a data final!' });
      setTipoAlert('fail');
      setAbrirAlert(true);
      setStartDate('');
      setEndDate('');

      setCarregandoTable(false);
      return;
    }

    const dados = {
      dataInicial: dataInicial ? dataInicial : '',
      dataFinal: dataFinal ? dataFinal : '',
      pagina: page ? page : 0,
      limite: pageSize ? pageSize : 10,
      nome: pesquisa ? pesquisa : '',
    }

    let notasResult = await notaFiscalService.Notas(dados, microSigaID);

    if (notasResult.dados) {
      let _notasItens = [];
      let dadosNF = notasResult.dados

      dadosNF.map((nt) => {
        _notasItens.push({
          id: nt.id,
          cnpj: nt.cnpj,
          numero: nt.numero,
          serie: nt.serie,
          dataEmissao: nt.dataEmissao || '',
          TipoNota: nt.tipoNota,
          DataSistema: nt.dataSistema,
          Descricao: nt.descricao,
          idStatus: nt.idStatus,
          chave: nt.chave,
          transportadoraCodigo: nt.transportadoraCodigo,
          dataPrevisaoEntrega: nt.dataPrevisaoEntrega ? new Date(nt.dataPrevisaoEntrega) : null,
          dataUltimaAtualizacaoTransportadora: nt.dataUltimaAtualizacaoTransportadora
            ? new Date(nt.dataUltimaAtualizacaoTransportadora) : null,
          dataEntrega: nt.dataEntrega ? new Date(nt.dataEntrega) : null
        });
      });
      setTotalDados(notasResult.total);
      setNotasItens(_notasItens);
      setNotasFiltradas(_notasItens);
      setCarregandoTable(false);
    }
    setCarregandoTable(false);
  };

  const valorPesquisa = (nome) => {
    setPesquisa(nome);
    notasFiscais(startDate, endDate, 0, pageSize, nome);
  };

  const mudarPagina = (event, newPage) => {
    setPage(newPage);
    notasFiscais(startDate, endDate, newPage, pageSize, pesquisa);
  };

  const mudarTamanhoPagina = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
    notasFiscais(startDate, endDate, 0, parseInt(event.target.value, 10), pesquisa);
  };

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <MenuComponent />
      <CustomAlert
        open={abrirAlert}
        message={mensagemAlert}
        type={tipoAlert}
        onClose={() => setAbrirAlert(false)}
      />
      {permissaoTCL !== 2 && tecnicoPrestador == null || tecnicoPrestador == false ? (
        <div style={{ margin: 30 }}>
          <h3 className={classes.tituloStyle}>Notas Fiscais</h3>
          <h4 className={classes.subtituloStyle}>
            <span
              onClick={() => (window.location.href = '/agenda')}
              className={classes.linkStyle}
              style={{ cursor: 'pointer' }}
            >
              Home
            </span>
            {' > '}
            Notas Fiscais
            {authService.getUserInfo().usuario.gestor === true &&
              nomeTecnico &&
              nomeTecnico.trim() !== '' ? (
              <>
                {' > '}
                {nomeTecnico}
              </>
            ) : null}
          </h4>

          {!showDetails && (
            <>
              {authService.getUserInfo().usuario.gestor === true &&
                nomeTecnico &&
                nomeTecnico.trim() !== '' ? (
                <Box sx={{ marginLeft: 1 }}>
                  <p
                    style={{ fontWeight: 'bold', fontSize: '1rem' }}
                    className={classes.subtituloStyle}
                  >
                    <IconButton onClick={handleCloseNFGestorTecnico} aria-label="voltar">
                      <ArrowBackIosIcon />
                    </IconButton>
                    Voltar
                  </p>
                </Box>
              ) : null}

              <div style={{ marginBottom: 30 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={mobile ? 4 : 5}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        id="start-date"
                        label="Data inicial"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={mobile ? 4 : 5}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        id="end-date"
                        label="Data final"
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={mobile ? 4 : 2}>
                    <BlueButton
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => notasFiscais(startDate, endDate, page, pageSize, pesquisa)}
                      fullWidth
                      disabled={carregandoTable}
                      startIcon={carregandoTable ? <CircularProgress size={20} /> : <SearchIcon />}
                      style={{ height: 55 }}
                    >
                      <span>{carregandoTable ? ' BUSCANDO...' : ' BUSCAR'}</span>
                    </BlueButton>
                  </Grid>
                </Grid>
              </div>

              <Paper className={classes.paper}>
                <div key={tableKey} style={{ marginBottom: '16px', overflowX: 'auto' }}>
                  <MaterialTableCustom
                    key={pageSize}
                    title=""
                    columns={[
                      { title: 'Número', field: 'numero' },
                      { title: 'Status', field: 'Descricao' },
                      {
                        title: 'Detalhes da NF',
                        field: '',
                        width: 10,
                        hidden: mobile,
                        render: (rowData) => (
                          <Button onClick={() => handleClick(rowData)}>
                            <AssignmentIcon />
                          </Button>
                        ),
                        sorting: false
                      },
                      {
                        title: 'Contestações da NF',
                        field: '',
                        width: 10,
                        hidden: mobile || (!usuarioGestor && !usuarioEstoqueAtivo),
                        render: (rowData) => (
                          <Button onClick={() => handleClickContestacaoNF(rowData)}>
                            <AssignmentIcon />
                          </Button>
                        ),
                        sorting: false
                      },
                      { title: 'Data de Emissão', field: 'dataEmissao', hidden: mobile },
                    ]}
                    data={notasFiltradas}
                    onSearchChange={valorPesquisa}
                    options={{
                      search: true,
                      pageSize: pageSize,
                    }}
                    localization={{
                      body: {
                        emptyDataSourceMessage: carregandoTable ? (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                            <CircularProgress />
                          </div>
                        ) : 'Nenhum registro para exibir'
                      },
                      toolbar: {
                        searchTooltip: 'Pesquisar',
                        searchPlaceholder: 'Pesquisar',
                      },
                      pagination: {
                        labelRowsSelect: 'linhas',
                        labelDisplayedRows: '{from}-{to} de {count}',
                        firstTooltip: 'Primeira página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Próxima página',
                        lastTooltip: 'Última página',
                      },
                    }}
                    detailPanel={[
                      (rowData) => ({
                        disabled: !mobile,
                        render: (rowData) => {
                          return (
                            <div style={{ width: '100%' }} hidden={!mobile}>
                              <DialogContent dividers style={{ width: '100%', height: '100%' }}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={12}>
                                    <strong>Data de Emissão:</strong>{' '}
                                    {rowData.dataEmissao}
                                  </Grid>
                                  <Grid item xs={12} sm={12}>
                                    <Grid item xs={12} md={6} lg={3}>
                                      <BlueButton
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        startIcon={<AssignmentIcon />}
                                        onClick={() => handleClick(rowData)}
                                      >
                                        Detalhes da NF
                                      </BlueButton>
                                    </Grid>
                                  </Grid>
                                  {(usuarioEstoqueAtivo || usuarioGestor) &&
                                    <Grid item xs={12} sm={12}>
                                      <Grid item xs={12} md={6} lg={3}>
                                        <BlueButton
                                          fullWidth
                                          variant="contained"
                                          color="primary"
                                          size="large"
                                          startIcon={<AssignmentIcon />}
                                          onClick={() => handleClickContestacaoNF(rowData)}
                                        >
                                          Contestações da NF
                                        </BlueButton>
                                      </Grid>
                                    </Grid>
                                  }
                                </Grid>
                              </DialogContent>
                            </div>
                          );
                        },
                      }),
                    ]}
                    components={{
                      Pagination: () => (
                        <TablePagination
                          style={{ padding: '0 0px', overflow: 'hidden', marginLeft: -10 }}
                          onPageChange={mudarPagina}
                          page={page}
                          count={totalDados}
                          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                          rowsPerPage={pageSize}
                          labelRowsPerPage="Linhas"
                          rowsPerPageOptions={[10, 25, 50, 100]}
                          onRowsPerPageChange={mudarTamanhoPagina}
                        />
                      ),
                    }}
                  />
                </div>
              </Paper>
            </>
          )}

        </div>
      ) : (
        <div style={{ margin: 30 }}>
          <h3 className={classes.tituloStyle}>Notas Fiscais</h3>
          <h4 className={classes.subtituloStyle}>
            <span
              onClick={() => window.location.href = '/agenda'}
              className={classes.linkStyle}
              style={{ cursor: 'pointer' }}
            >
              Home
            </span> {'>'} Notas Fiscais
          </h4>
          <Paper className={classes.paper}>
            <Grid container justifyContent="center">
              <h2 className={classes.mensagemStyle} style={{ padding: 10 }}>Você não tem permissão para acessar esta página!</h2>
            </Grid>
          </Paper>
        </div>
      )
      }
      {nf && showDetails && <DetalheNotaFiscal microSigaID={microSigaID} nf={nf} handleClose={handleClose} />}
      {(usuarioEstoqueAtivo == true || usuarioGestor == true) && ContestacaoNF && showDetails && <ContestacaoNotaFiscal nf={ContestacaoNF} handleClose={handleClose} />}
    </>
  );
}
