import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import BlueButton from '../../components/button/BlueButton';
import ordemServicoServico from '../../Services/OrdemServico/ordem-servico-servico';
import { CircularProgress } from '@material-ui/core';

const Anotacao = ({ ordemServico }) => {
  const [inputValue, setInputValue] = useState(ordemServico.anotacao.descricao || '');
  const [loadSave, setLoadSave] = useState(false);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  const handleSave = async () => {
    setLoadSave(true);

    if (inputValue.trim() !== '') {

      const anotacaoBody = {
        AtividadeId: ordemServico.activityId,
        Descricao: inputValue.trim()
      };

      if (ordemServico.anotacao.id) {
        const responseAtualizarAnotacao = await ordemServicoServico.atualizarAnotacao(ordemServico.anotacao.id, JSON.stringify(anotacaoBody));
        if (responseAtualizarAnotacao.status === 200) {
          ordemServico.anotacao[0] = inputValue.trim();
        }
      } else {
        const responseSalvarAnotacao = await ordemServicoServico.salvarAnotacao(JSON.stringify(anotacaoBody));
        if (responseSalvarAnotacao.status === 200) {
          ordemServico.anotacao[0] = inputValue.trim();
        }
      }

      setInputValue(inputValue.trim());

    }
    setLoadSave(false);
  };

  return (
    <div>
      <Grid container spacing={2} sx={{ alignItems: 'center' }}>
        <Grid item xs={12} sm={10}>
          <TextField
            fullWidth
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            label="Inserir Anotação"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          {loadSave ? (
            <Grid container alignItems="center" justifyContent="center">
              <CircularProgress size={25} style={{ color: '#209BDE' }} />
            </Grid>
          ) : (
            <BlueButton
              fullWidth
              style={{ minHeight: '56px', maxHeight: '56px' }}
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSave}
            >
              <span style={{ fontSize: '0.7rem' }}>
                Salvar anotação
              </span>
            </BlueButton>
          )}
        </Grid>
      </Grid>

    </div>
  );
};

export default Anotacao;
