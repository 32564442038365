import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class AuditoriaService {

  async auditoria(dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
			const tz_area_atendimento_microsigaid = authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;
			let response = await fetch(`${_configAPITecnico.URI_API}/auditoriaGestor/listar?AreaAtendimentoId=${tz_area_atendimento_microsigaid}&DataInicial=${dados.dataInicial}&DataFinal=${dados.dataFinal}&TecnicoID=${dados.tecnicoID}&Pagina=${dados.pagina}&Limite=${dados.limite}&Pesquisa=${dados.nome}`,
				{
					method: 'GET',
					headers: _headerToken,
				},
			);
      const data = await response.json();
      let errorsMessage = null;
      if(data.errors){
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }

  async listarTecnicosAuditoria() {
    try {
      const _headerToken = authService.headerAPITecnico();
      const codigo_gestor = authService.getUserInfo().usuario.localid;
      let response = await fetch(`${_configAPITecnico.URI_API}/usuario/${codigo_gestor}`,
        {
          method: 'GET',
          headers: _headerToken,
        },
      );
      const data = await response.json();
      let errorsMessage = null;
      if(data.errors){
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  } 

  async listarEmpresasGestor() {
    try {
      const _headerToken = authService.headerAPITecnico();
      const codigo_gestor = authService.getUserInfo().usuario.localid;
      let response = await fetch(`${_configAPITecnico.URI_API}/usuario/gestor/${codigo_gestor}/empresas`,
        {
          method: 'GET',
          headers: _headerToken,
        },
      );
      const data = await response.json();
      let errorsMessage = null;
      if(data.errors){
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  } 

  async listarUsuariosGestor(dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      const codigo_gestor = authService.getUserInfo().usuario.localid;
      let response = await fetch(`${_configAPITecnico.URI_API}/usuario/gestor?LocalID=${codigo_gestor}&Pagina=${dados.pagina}&Limite=${dados.limite}&Pesquisa=${dados.nome}`,
        {
          method: 'GET',
          headers: _headerToken,
        },
      );
      const data = await response.json();
      let errorsMessage = null;
      if(data.errors){
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  } 
}

export default new AuditoriaService();
