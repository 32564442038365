import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Container, TextField, useMediaQuery, useTheme, DialogContent, CircularProgress } from '@material-ui/core';
import MenuComponent from '../components/menu/menu-component';
import { MaterialTableCustom } from '../components/table/material-table-custom';
import auditoriaService from '../Services/Auditoria/auditoria-service';
import BlueButton from '../components/button/BlueButton';
import TablePagination from '@material-ui/core/TablePagination';
import Autocomplete from '@material-ui/lab/Autocomplete';
import authService from '../Services/auth/auth-service';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomAlert from '../components/alert/CustomAlert';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: '0 80px 40px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 20px 40px',
        },
    },
    searchIcon: {
        marginRight: 4,
    },
    searchInput: {
        flex: 1,
        paddingVertical: 8,
        fontSize: 18,
    },
    paper: {
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 0px 15px',
        borderRadius: 10,
    },
    tituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
        marginBottom: '5px'
    },
    mensagemStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
    },
    subtituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#7F7F7F',
        fontSize: '16px',
        fontWeight: 'inherit',
        textAlign: 'start',
        padding: '0 0px',
        marginTop: '0px'
    },
    infos: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    search: {
        marginBottom: 40,
    },
    item: {
        marginBottom: 30,
    },
    avatar: {
        padding: 6,
        borderRadius: 50,
        backgroundColor: theme.palette.grey[500],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 5,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }
}));

export default function AuditoriaPage() {
    // eslint-disable-next-line no-undef
    const [isMobile, setISMobile] = useState(false)
    const classes = useStyles();
    const mobile = useMediaQuery(useTheme().breakpoints.down(800))
    const [auditoriaDados, setAuditoriaDados] = useState([]);
    const [dataInicial, setDataInicial] = useState('');
    const [dataFinal, setDataFinal] = useState('');
    const [tecnicoID, setTecnicoID] = useState('');
    const [tecnicos, setTecnicos] = useState([]);
    const [page, setPage] = useState(0);
    let [pageSize, setPageSize] = useState(10);
    const [totalDados, setTotalDados] = useState(0);
    const [carregandoTable, setCarregandoTable] = useState(false);
    const [permissaoGestor, setPermissaoGestor] = useState(0);
    const [tableKey, setTableKey] = useState(0)
    const [pesquisa, setPesquisa] = useState('');
    const [abrirAlert, setAbrirAlert] = useState(false);
    const [mensagemAlert, setMensagemAlert] = useState('');
    const [tipoAlert, setTipoAlert] = useState('error');
    const [loading, setLoading] = useState(true);

    const dadosAuditoria = async (dataInicial, dataFinal, tecnicoID, page, pageSize, pesquisa) => {
        setCarregandoTable(true);

        if (dataInicial && dataFinal && moment(dataInicial).isAfter(dataFinal)) {
            setMensagemAlert({ title: 'Falha!', body: 'Data inicial não pode ser maior que a data final!' });
            setTipoAlert('fail');
            setAbrirAlert(true);

            setCarregandoTable(false);
            return;
        }

        const dados = {
            dataInicial: dataInicial ? dataInicial : '',
            dataFinal: dataFinal ? dataFinal : '',
            tecnicoID: tecnicoID ? tecnicoID : '',
            pagina: page ? page : 0,
            limite: pageSize ? pageSize : 10,
            nome: pesquisa ? pesquisa : '',
        }

        const response = await auditoriaService.auditoria(dados);

        if (response.dados) {
            let _auditoriaDados = [];
            let dados = response.dados;

            dados.map((nt) => {
                let acao;
                switch (nt.acao) {
                    case 'Adicao':
                        acao = 'Adição';
                        break;
                    case 'Edicao':
                        acao = 'Edição';
                        break;
                    case 'Exclusao':
                        acao = 'Exclusão';
                        break;
                    default:
                        acao = nt.acao;
                }

                _auditoriaDados.push({
                    id: nt.id,
                    login: nt.login,
                    descricao: nt.descricao,
                    data: nt.data,
                    acao: acao
                });
            });

            setAuditoriaDados(_auditoriaDados);
            setTotalDados(response.total);
        }
        setCarregandoTable(false);
    }

    const usuarios = async () => {
        const response = await auditoriaService.listarTecnicosAuditoria();
        if (response.error) {
            setMensagemAlert({ title: 'Erro!', body: response.message });
            setTipoAlert('fail');
            setAbrirAlert(true);
            return;
        } else {
            setTecnicos(response);
        }
    }

    const valorPesquisa = (nome) => {
        setPesquisa(nome);
        dadosAuditoria(dataInicial, dataFinal, tecnicoID, 0, pageSize, nome);
    };

    const mudarPagina = (event, newPage) => {
        setPage(newPage);
        dadosAuditoria(dataInicial, dataFinal, tecnicoID, newPage, pageSize, pesquisa);
    };

    const mudarTamanhoPagina = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
        dadosAuditoria(dataInicial, dataFinal, tecnicoID, 0, parseInt(event.target.value, 10), pesquisa);
    };

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          await dadosAuditoria();
          await usuarios();
          setPermissaoGestor(authService.getUserInfo().usuario.gestor);
          setTableKey(tableKey + 1)
          setLoading(false);
        };
    
        fetchData();
      }, [mobile]);

    if (loading) {
        return (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        );
      }

    return (
        <>
            <MenuComponent />
            <CustomAlert
                open={abrirAlert}
                message={mensagemAlert}
                type={tipoAlert}
                onClose={() => setAbrirAlert(false)}
            />
            <>
                {permissaoGestor == true ? (
                    <>
                        <div style={{ margin: 30 }}>
                            <h3 className={classes.tituloStyle}>Auditoria</h3>
                            <h4 className={classes.subtituloStyle}>
                                <span
                                    onClick={() => window.location.href = '/gestor'}
                                    className={classes.linkStyle}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Home
                                </span> {'>'} Auditoria
                            </h4>

                            <Grid container spacing={2} alignItems="center" style={{ marginBottom: 20 }}>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        id="start-date"
                                        label="Data inicial"
                                        type="date"
                                        value={dataInicial}
                                        onChange={(e) => setDataInicial(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        id="end-date"
                                        label="Data final"
                                        type="date"
                                        value={dataFinal}
                                        onChange={(e) => setDataFinal(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Autocomplete
                                        id="tecnicoID"
                                        options={tecnicos}
                                        getOptionLabel={(option) => option.login}
                                        defaultValue={tecnicos.find((option) => option.id === tecnicoID)}
                                        onChange={(event, newValue) => {
                                            setTecnicoID(newValue ? newValue.id : '');
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={<span>Técnico</span>}
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Selecione um técnico"
                                                InputLabelProps={{
                                                    htmlFor: 'tecnicoID',
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    id: 'tecnicoID',
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <BlueButton
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={() => dadosAuditoria(dataInicial, dataFinal, tecnicoID)}
                                        style={{ height: 55 }}
                                        fullWidth
                                    >
                                        Buscar
                                    </BlueButton>
                                </Grid>
                            </Grid>
                            <Paper className={classes.paper}>

                                <div key={tableKey} style={{ marginBottom: '16px', overflowX: 'auto' }}>
                                    <MaterialTableCustom
                                        key={pageSize}
                                        title=""
                                        columns={[
                                            { title: 'Código', field: 'id', hidden: mobile },
                                            { title: 'Usuário', field: 'login', hidden: mobile },
                                            { title: 'Descrição', field: 'descricao' },
                                            { title: 'Data', field: 'data', render: (rowData) => moment(rowData.data).format('DD/MM/YYYY HH:mm:ss'), hidden: mobile },
                                            { title: 'Ação', field: 'acao', hidden: mobile }
                                        ]}
                                        isLoading={carregandoTable}
                                        data={auditoriaDados}
                                        options={{
                                            search: true,
                                            pageSize: pageSize,
                                        }}
                                        onSearchChange={valorPesquisa}
                                        localization={{
                                            body: {
                                                emptyDataSourceMessage: 'Nenhum registro para exibir',
                                            },
                                            toolbar: {
                                                searchTooltip: 'Pesquisar',
                                                searchPlaceholder: 'Pesquisar',
                                            },
                                        }}
                                        detailPanel={[
                                            (rowData) => ({
                                                disabled: !mobile,
                                                render: (rowData) => {
                                                    return (
                                                        <div style={{ width: '100%' }}>
                                                            <DialogContent dividers style={{ width: '100%', height: '100%' }}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <strong>Código:</strong>{' '}
                                                                        {rowData.id}
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <strong>Usuário:</strong>{' '}
                                                                        {rowData.login}
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <strong>Data:</strong>{' '}
                                                                        {moment(rowData.data).format('DD/MM/YYYY HH:mm:ss')}
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <strong>Ação:</strong>{' '}
                                                                        {rowData.acao}
                                                                    </Grid>
                                                                </Grid>
                                                            </DialogContent>
                                                        </div>
                                                    )
                                                },
                                            }),
                                        ]
                                        }
                                        components={{
                                            Pagination: () => (
                                                <TablePagination
                                                style={{ padding: '0 0px', overflow: 'hidden' }}
                                                    component="div"
                                                    onPageChange={mudarPagina}
                                                    page={page}
                                                    count={totalDados}
                                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                                    rowsPerPage={pageSize}
                                                    labelRowsPerPage="Linhas"
                                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                                    onRowsPerPageChange={mudarTamanhoPagina}
                                                />
                                            ),
                                        }}
                                    />
                                </div>
                            </Paper>
                        </div>
                    </>)
                    : (
                        <div style={{ margin: 30 }}>
                            <h3 className={classes.tituloStyle}>Auditoria</h3>
                            <h4 className={classes.subtituloStyle}>
                                <span
                                    onClick={() => window.location.href = '/gestor'}
                                    className={classes.linkStyle}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Home
                                </span> {'>'} Auditoria
                            </h4>


                            <Paper className={classes.paper}>
                                <Grid container justifyContent="center">
                                    <h2 className={classes.mensagemStyle} style={{padding: 10}}>Você não tem permissão para acessar esta página!</h2>
                                </Grid>
                            </Paper>
                        </div>
                    )}
            </>
        </>
    );
}
