import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import 'moment/locale/pt-br';
import noticiaService from '../../Services/Noticias/noticia-service';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Badge, Box, Dialog, DialogActions, DialogContent, DialogTitle, Menu, Typography, Grid, InputBase, InputAdornment } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ContactsIcon from '@material-ui/icons/Contacts';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TodayIcon from '@mui/icons-material/Today';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ShopIcon from '@material-ui/icons/Shop';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LineWeight from '@material-ui/icons/LineWeight';
import NotificationsIcon from '@material-ui/icons/Notifications';
import GroupIcon from '@mui/icons-material/Group';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import authService from '../../Services/auth/auth-service';
import usuarioService from '../../Services/Usuario/usuario-service';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import AirplayIcon from '@material-ui/icons/Airplay';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InventoryIcon from '@mui/icons-material/Inventory';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import FileCopy from '@material-ui/icons/FileCopy';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import DescriptionIcon from '@mui/icons-material/Description';

var menuItems = [
  {
    text: 'Clientes',
    icon: <ContactsIcon />,
    url: '/clientes',
    allowedRoles: ['revenda'],
  },
  {
    text: 'Financeiro',
    icon: <AttachMoneyIcon />,
    url: '/financeiro',
    allowedRoles: ['tecnico'],
  },
  {
    text: 'Oportunidade',
    icon: <ShopIcon />,
    url: '/oportunidades',
    allowedRoles: ['revenda'],
  },
  {
    text: 'Venda',
    icon: <ShoppingCartIcon />,
    url: '/venda',
    allowedRoles: ['revenda'],
  },
  {
    text: 'Gestor',
    icon: <TodayIcon />,
    url: '/gestor',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Agenda',
    icon: <DateRangeIcon />,
    url: '/agenda',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Auditoria',
    icon: <ReceiptLongIcon />,
    url: '/auditoria',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Inventário',
    icon: <InventoryIcon />,
    url: '/inventario',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Ordens de Serviço',
    icon: <ListAltIcon />,
    url: '/servicos',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Comunicação',
    icon: <AirplayIcon />,
    url: '/comunicacao',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Notas Fiscais',
    icon: <DescriptionIcon />,
    url: '/notasfiscais',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Estoque',
    icon: <ShoppingBagIcon />,
    allowedRoles: ['revenda', 'tecnico'],
    subMenuItems: [
      {
        text: 'Meu Estoque',
        icon: <InventoryIcon />,
        url: '/estoque',
        allowedRoles: ['revenda', 'tecnico'],
      },
      {
        text: 'Solicitações de Reversa',
        icon: <SettingsBackupRestoreIcon />,
        url: '/solicitacaoreversa',
        allowedRoles: ['revenda', 'tecnico'],
      },
    ],
  },
  {
    text: 'Usuários',
    icon: <GroupIcon />,
    url: '/gestorTecnico',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Técnicos',
    icon: <EngineeringIcon />,
    url: '/perfiltecnico',
    allowedRoles: ['tecnico', 'revenda'],
  },
  {
    text: 'Notícias',
    icon: <LineWeight />,
    url: '/noticia',
    allowedRoles: ['revenda', 'gestor', 'tecnico'],
  },
  {
    text: 'Manuais',
    icon: <FileCopy />,
    url: '/manuais',
    allowedRoles: ['revenda', 'gestor', 'tecnico'],
  },
  {
    text: 'Minha Conta',
    icon: <AccountBoxIcon />,
    url: '/perfil',
    allowedRoles: ['revenda', 'gestor', 'tecnico'],
  },
  {
    text: 'Liberação do Estoque',
    icon: <LockOpenIcon />,
    url: '/liberacaoEstoqueGestor',
    allowedRoles: ['gestor'],
  },
];

menuItems.sort((a, b) => a.text.localeCompare(b.text));

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  submenu: {
    paddingLeft: theme.spacing(3),
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: 1500,
    background: '#209BDE',
  },
  appBarShift: {
    width: `calc(100% - 240px)`,
    marginLeft: 240,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  button: {
    color: 'white',
    border: '1px solid white',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: 240,
    flexShrink: 0,
    zIndex: 1500,
  },
  logo: {
    margin: '20px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawerPaper: {
    width: 240,
    background: '#209BDE',
    color: 'white',
    marginTop: '64px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '50px'
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -240
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  grow: {
    flexGrow: 1,
  },
  menu: {
    marginTop: theme.spacing(6),
  },
  listItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: 'white',
  },
  searchInput: {
    color: 'white',
    marginLeft: theme.spacing(1),
    flex: 1,
    marginTop: 5
  },
}));

export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState({});
  const [searchText, setSearchText] = useState('');
  const [usuarioInfo, setUsuarioInfo] = useState(null);
  const [_notificacao, setNotificacao] = useState(null);
  const [notificacoes, setNotificacoes] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [usuarioEstoqueAtivo, setUsuarioEstoqueAtivo] = useState(false);
  const anchorRef = React.useRef(null);
  const [openAnch, setOpenAnch] = useState(false);
  const handleToggleSubmenu = (text) => {
    setSubmenuOpen((prev) => ({ ...prev, [text]: !prev[text] }));
  };

  const filteredMenuItems = menuItems
    .filter((item) => {
      const isGestor = usuarioInfo && usuarioInfo.gestor === true;
      const isRevenda = usuarioInfo && usuarioInfo.revenda === true;
      const isTecnico = !isGestor && !isRevenda;

      if (item.allowedRoles.includes('gestor') && isGestor) {
        return true;
      } else if (item.allowedRoles.includes('revenda') && isRevenda) {
        return true;
      } else if (item.allowedRoles.includes('tecnico') && isTecnico) {
        return true;
      }

      return false;
    })
    .filter(item => !(!usuarioEstoqueAtivo && item.text === 'Estoque'))
    .filter(item => {
      const tecnicoPrestador = authService.getUserInfo().usuario.tecnico;
      if (tecnicoPrestador) {
        if (item.subMenuItems) {
          item.subMenuItems = item.subMenuItems.filter(subItem => subItem.text !== 'Solicitações de Reversa');
        }
        if (item.text === 'Solicitações de Reversa') {
          return false;
        }
      }
      return true;
    })
    .filter(item => {
      const tecnicoPrestador = authService.getUserInfo().usuario.tecnico;
      if (tecnicoPrestador && item.text === 'Técnicos') {
        return false;
      }
      return true;
    })
    .filter(item => {
      const tecnicoPrestador = authService.getUserInfo().usuario.tecnico;
      if (tecnicoPrestador && item.text === 'Financeiro') {
        return false;
      }
      return true;
    })
    .filter(item => {
      const tecnicoPrestador = authService.getUserInfo().usuario.tecnico;
      const permissaoTCL = authService.getUserInfo().usuario.classificacaoPrestador;
      if (tecnicoPrestador && item.text === 'Notas Fiscais') {
        return false;
      }
      if (permissaoTCL === 2 && item.text === 'Notas Fiscais') {
        return false;
      }
      return true;
    })
    .filter(item => item.text.toLowerCase().includes(searchText.toLowerCase()));

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSair = () => {
    authService.logOut();
    window.location.href = '/';
  };

  const handleToggle = () => {
    setOpenAnch((prevOpen) => !prevOpen);
  };

  const handleDialogNotificacaoClose = () => {
    setNotificacao(null);
    setOpenDialog(false);
  };

  const handleDialogNotificacao = (notificacao) => {
    handleToggle();
    setNotificacao(notificacao);
    setOpenDialog(true);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenAnch(false);
  };

  const buscarUsuarioEstoqueAtivo = async () => {
    const response = await usuarioService.usuarioEstoqueAtivo(authService.getUserInfo().usuario.id);
    if (response.error) {
      return;
    } else {
      setUsuarioEstoqueAtivo(response);
    }
  }

  useEffect(() => {
    buscarUsuarioEstoqueAtivo();
    setUsuarioInfo(JSON.parse(localStorage.getItem('userInfo')).usuario);
    consultarNoticias();
  }, []);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const consultarNoticias = async () => {
    let noticias = await noticiaService.noticias();

    if (noticias) setNotificacoes(noticias);
  };

  const dialogNotificacao = (notificacao) => (
    <Dialog
      aria-labelledby="customized-dialog-title"
      onClose={handleDialogNotificacaoClose}
      open={true}
      style={{ marginTop: 35, marginBottom: 10 }}
      disableBackdropClick={true}
    >
      <DialogTitle>
        <Grid container alignItems="center">
          <Grid item xs={10} sm={11}>
            Notícias
          </Grid>

          <Grid item xs={2} sm={1}>
            <IconButton onClick={handleDialogNotificacaoClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <div>
          <Typography component="span" variant="body2" color="textPrimary">
            {notificacao.txtTexto}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          style={{ float: 'right', marginTop: '5px' }}
          onClick={handleDialogNotificacaoClose}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(!open)}
            edge="start"
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <a onClick={() => {
            const userInfo = authService.getUserInfo();
            if (userInfo.usuario.gestor) {
              window.location.assign('/gestor');
            } else {
              window.location.assign('/agenda');
            }
          }}>
            <img src="./logo/LogotipoOmnilinkWHITE.png" height="45" alt="Logo" />
          </a>


          <div className={classes.grow} />
          <div style={{ display: 'flex' }}>
            <div style={{ paddingRight: 10 }}>
              <IconButton
                ref={anchorRef}
                aria-controls="fade-menu"
                aria-haspopup="true"
                color="inherit"
                onClick={handleToggle}
              >
                <Badge badgeContent={notificacoes.length} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </div>

            <Menu
                keepMounted
                onClose={handleClose}
                open={openAnch}
                anchorEl={anchorRef.current}
                component="nav"
                className={classes.menu}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} 
                transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                id="menu-list-grow"
                getContentAnchorEl={null} 
              >
              <List component="nav" aria-label="secondary mailbox folders">
                {notificacoes.length > 0 &&
                  notificacoes.map((x) => (
                    <React.Fragment key={x.id}>  
                      <ListItem button alignItems="flex-start" onClick={() => handleDialogNotificacao(x)} className={classes.listItem}>

                        <ListItemText
                          primary={
                            <div onClick={() => handleDialogNotificacao(x)}>
                              <Typography component="span" variant="body2" color="textPrimary">
                                {moment(x.dtInclusao).format('DD/MM/YYYY')}
                              </Typography>
                              <Typography component="span" variant="body2" color="textPrimary">
                                {` — ${x.txtTitulo && x.txtTitulo.length > 30 ? `${x.txtTitulo.substring(0, 30)}...` : x.txtTitulo}`}
                              </Typography>
                            </div>
                          }
                        />
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))}

                  {notificacoes.length === 0 && (
                    <ListItem button alignItems="flex-start">
                      <ListItemText
                        secondary={
                          <Typography component="span" variant="body2" color="textPrimary">
                            {'Você não possui notificações.'}
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}
                </List>
              </Menu>

            <Button onClick={handleSair} startIcon={<ExitToAppIcon />} style={{ color: 'white' }}>
              Sair
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={handleDrawerClose}
      >
        <ListItem style={{ background: '#008bd7' }}>
          <InputBase
            className={classes.searchInput}
            placeholder="Buscar..."
            inputProps={{ 'aria-label': 'search' }}
            startAdornment={
              <InputAdornment position="start" style={{ marginLeft: '-10px' }}>
                <SearchIcon style={{ color: 'white' }} />
              </InputAdornment>
            }
            value={searchText}
            onChange={handleSearchChange}
          />
        </ListItem>

        <Divider />

        <Box>
          {filteredMenuItems.map((item) => (
            <div key={`${item.text}-${item.url}`}>
              <ListItem
                button
                onClick={() => {
                  if (item.text === 'Sair') {
                    handleSair();
                  } else if (item.subMenuItems) {
                    handleToggleSubmenu(item.text);
                  } else {
                    window.location.href = item.url;
                  }
                }}
                style={{ marginRight: '16px' }}
              >
                <ListItemIcon style={{ color: 'white' }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
                {item.subMenuItems && (
                  <IconButton edge="end">
                    {submenuOpen[item.text] ? <ExpandLess style={{ color: 'white' }} /> : <ExpandMore style={{ color: 'white' }} />}
                  </IconButton>
                )}
              </ListItem>

              {item.subMenuItems && submenuOpen[item.text] && (
                <List className={classes.submenu}>
                  {item.subMenuItems.map((submenuItem) => (
                    <ListItem
                      key={`${submenuItem.text}-${submenuItem.url}`}
                      button
                      onClick={() => {
                        window.location.href = submenuItem.url;
                      }}
                    >
                      <ListItemIcon style={{ color: 'white' }}>{submenuItem.icon}</ListItemIcon>
                      <ListItemText primary={submenuItem.text} />
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          ))}
        </Box>
      </Drawer>

      <main className={classes.content}>
        <div className={classes.drawerHeader} />
      </main>
      {_notificacao && dialogNotificacao(_notificacao)}
    </div>
  );
}
