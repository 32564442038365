import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { CircularProgress, Paper, Grid, Divider, Box, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RefreshIcon from '@material-ui/icons/Refresh';
import SaveIcon from '@material-ui/icons/Save';
import RestoreOutlined from '@material-ui/icons/RestoreOutlined';
import { Alert, AlertTitle } from '@material-ui/lab';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ContraSenhaComponent from '../atividade-servico/component/contra-senha-component';
import MenuComponent from '../components/menu/menu-component';
import BlueButton from '../components/button/BlueButton';
import GreenButton from '../components/button/GreenButton';
import BlueTextField from '../components/textfield/BlueTextField';
import ordemServicoServico from '../Services/OrdemServico/ordem-servico-servico';
import estoqueService from '../Services/Estoque/estoque-service';
import authService from '../Services/auth/auth-service';
import vistoriaService from '../Services/Vistoria/vistoria-service';
import { OSVistoriaPage } from './os-vistoria-page';
import { ProdutosTable } from './components/os/produtos-table-component';
import { statusVistoria } from '../util/statusVistoria';
import Anotacao from '../atividade-servico/component/anotacao';
import painelAtivacaoService from '../Services/PainelAtivacao/painel-ativacao-service';
import CustomAlert from '../components/alert/CustomAlert';
import SenhaSupervisorComponent from '../atividade-servico/component/senha-supervisor-component';
import senhaSupervisorService from '../Services/SenhaSupervisor/senha-supervisor-service';
import { CheckCircleOutline, HighlightOff, HourglassEmpty } from '@material-ui/icons';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: 10,
  },
  actions: {
    padding: '0 6px',
    marginBottom: 6,
  },
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 15px',
    borderRadius: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 10px',
    },
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px'
  },
  mensagemStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px'
  },
  title: {
    fontSize: 23,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 19,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  expansionSubtitle: {
    fontSize: 23,
    fontWeight: 'bold',
  },
  itemText: {
    fontSize: 16,
  },
}));

export default function OrdemServicoPage(props,) {
  const classes = useStyles();
  const [os, setOS] = useState(null);
  const [vistoria, setVistoria] = useState(null);
  const [itensOS, setItensOS] = useState([]);
  const [estoqueItens, setEstoque] = useState([]);
  const [observacao, setObservacao] = useState(null);
  const [openDialogVistoria, setOpenDialogVistoria] = useState(false);
  const [openDialogContraSenha, setOpenDialogContraSenha] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadSave, setLoadSave] = useState(false);
  const [loadVistoria, setLoadVistoria] = useState(false);
  const [dadosVistoria, setDadosVistoria] = useState(null);
  const [alertVistoriaAprovada, setAlertVistoriaAprovada] = useState(false);
  const [alerItensAprovada, setAlertItensAprovada] = useState(false);
  const [anotacao, setAnotacao] = useState(null);
  const [efeitoCarregando, setEfeitoCarregando] = useState(false);
  const [efeitoCarregandoAtualizarBase, setEfeitoCarregandoAtualizarBase] = useState(false);
  const [painelAtivacaoEquipamento, setPainelAtivacaoEquipamento] = useState(false);
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState('');
  const [tipoAlert, setTipoAlert] = useState('error');
  const [senhaSupervisor, setSenhaSupervisor] = useState('');
  const [mensagemSenhaNaoEncontrada, setMensagemSenhaNaoEncontrada] = useState('');
  const areaAtendimentoID = authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;
  // Verifica tipo do usuário - Se for igual a 2 o usuário é do tipo TCL
  const classificacaoPrestador = authService.getUserInfo().usuario.classificacaoPrestador;

  const tiposImagens = [
    {
      id: 'fotoFrontal',
      label: 'Foto Frontal',
      arquivo: 'foto_frontal'
    },
    {
      id: 'fotoMarca',
      label: 'Foto Marca/Modelo',
      arquivo: 'foto_marca'
    },
    {
      id: 'fotoOdometro',
      label: 'Foto Hodômetro',
      arquivo: 'foto_odometro'
    },
    {
      id: 'fotoLateral',
      label: 'Foto Lateral',
      arquivo: 'foto_lateral'
    },
    {
      id: 'fotoMalUso',
      label: 'Foto de Mau Uso',
      arquivo: 'foto_mal_uso'
    },
  ]

  const motivoCodigoMap = {
    "419400000": "Apagado",
    "419400001": "Display queimado",
    "419400002": "Teclas falhando",
    "419400003": "Não recebe comandos",
    "419400004": "Mau uso",
    "419400005": "Queimado",
    "419400006": "Infiltração d'água",
    "419400007": "Não comunica",
    "419400008": "Não aciona",
    "419400009": "Não bloqueia",
    "419400010": "Quebrado - Mau uso",
    "419400011": "Senha supervisor"
  };

  const mapCodigoToMotivo = (motivo) => motivoCodigoMap[motivo] || null;

  useEffect(() => {
    consultaDetalheOS();
    if (classificacaoPrestador !== 2) {
      produtosEstoque();
    }
    painelAtivacao();
  }, []);

  useEffect(() => {
    if (classificacaoPrestador !== 2) {
      if (os) getVistoria();
    }
  }, [os]);

  const painelAtivacao = async () => {
    let na = props.location.state.na;
    const response = await painelAtivacaoService.painelAtivacao(na);

    if (response.error != true) {
      setPainelAtivacaoEquipamento(response.result)
    }
  }

  const buscaSenhaSupervisor = async (numeroContrato) => {
    const response = await senhaSupervisorService.buscaSenhaSupervisor(numeroContrato);
    if (!response.errorLogin) {
      if (response.error == false) {
        setSenhaSupervisor(response.dados)
      } else {
        setMensagemSenhaNaoEncontrada('Não foi possível encontrar a senha registrada nesse serial.')
      }
    }
  };

  const ativaBotaoAtualizarBase = () => {
    let disabled = true;

    if (classificacaoPrestador === 2) {
      disabled = false;
    } else {

      if (vistoria?.statusEntrada != 'Aceito') {
        setAlertVistoriaAprovada(true);
      } else {
        setAlertVistoriaAprovada(false);
      }

      const allItensAprovados = itensOS.every((i) => i.statusAprovacao === 2);

      if (!allItensAprovados) {
        setAlertItensAprovada(true);
      }

      if (allItensAprovados && vistoria && vistoria.statusEntrada === 'Aceito') {
        disabled = false;
      }
    }

    return disabled;
  };

  const disabledAtualizarBase = useMemo(() => ativaBotaoAtualizarBase(), [itensOS, vistoria]);

  const produtosEstoque = async () => {
    try {
      let areaAtendimentoID =
        authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;

      let estoqueConsolidadoEServicosResult = await estoqueService.listarEstoqueConsolidadoEServicos(areaAtendimentoID);

      if (estoqueConsolidadoEServicosResult) {
        let estoqueAgrupado = {};

        estoqueConsolidadoEServicosResult.forEach((item) => {
          let chave = item.produtoNome;

          estoqueAgrupado[chave] = {
            produtoId: item.produtoId,
            produtoNumero: item.produtoCodigo,
            nome: item.produtoNome,
            ehServico: item.ehServico,
            qtdDisponivel: item.quantidadeDisponivel > 0 ? item.quantidadeDisponivel : 1,
          }
        });

        let _estoqueItens = Object.values(estoqueAgrupado);
        setEstoque(_estoqueItens);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const consultaDetalheOS = async () => {
    try {
      setLoad(true);

      let na = props.location.state.na;

      let osResult = await ordemServicoServico.detalhesOS(na, areaAtendimentoID);

      let osOrcamento = '';
      if (classificacaoPrestador !== 2) {
        osOrcamento = await ordemServicoServico.listaOrcamentoOS(na, areaAtendimentoID)
      }

      if (osResult) {
        let annotations = osResult[0].annotations;

        let _os = {
          osID: osResult[0].tz_Ordem_ServicoId || '',
          os: osResult[0].os || '',
          activityId: osResult[0].activityId || '',
          cliente: osResult[0].cliente || '',
          clienteCpfCnpj: osResult[0].clienteCpfCnpj || '',
          clienteEmail: osResult[0].clienteEmail?.toLowerCase() || '',
          solicitante: osResult[0].tz_nome_solicitante || '',
          telefone_solicitante: osResult[0].tz_telefone_solicitante || '',
          endereco: osResult[0].tz_Endereco_Rua || '',
          numero: osResult[0].tz_Endereco_Numero || '',
          cep: osResult[0].tz_endereco_cepIdName || '',
          cidade: osResult[0].tz_endereco_cidadeIdName || '',
          estado: osResult[0].tz_endereco_EstadoIdName || '',
          tecnico: osResult[0].tz_nome_tecnico || '',
          placa: osResult[0].placa || '',
          referencia: osResult[0].tz_referencia || '',
          razaoStatus: osResult[0].razaoStatus || '',
          inicio: osResult[0].data_inicio,
          fim: osResult[0].data_fim,
          na: osResult[0].na || '',
          cor: osResult[0].cor || '',
          plataforma: osResult[0].tz_PlataformaIdName || '',
          rastreador: osResult[0].tz_RastreadorIdName || '',
          plano: osResult[0].tz_PlanoIdName || '',
          num_serie_contrato: osResult[0].tz_Numero_Serie_Contrato || '',
          num_serie_antena_contrato: osResult[0].tz_Numero_Serie_Antena_Contrato || '',
          fim_garantia: osResult[0].tz_Data_Fim_Garantia,
          observacao: osResult[0].tz_Observacoes || '',
          status: osResult[0].descStatus || '',
          obsCliente: osResult[0].detalheCliente || '',
          bairro: osResult[0].tz_endereco_bairro || '',
          modificacao: osResult[0].modifiedOn || '',
          servico: osResult[0].serviceIdName || '',
          antena: osResult[0].tz_Numero_Serie_Antena_Contrato || '',
          clienteID: osResult[0].clienteID || '',
          id_area_atendimento:
            osResult[0].tz_area_atendimento_microsigaId || areaAtendimentoID,
          anotacao: annotations && annotations.length > 0 ? annotations[0] : {},
          cenarioVenda: osResult[0].tz_Cenario_Venda || '',
          marca: osResult[0].marca || '',
          marcaID: osResult[0].marcaid || '',
          modelo: osResult[0].modelo || '',
          modeloID: osResult[0].modeloid || '',
          tipoServico: osResult[0].tipoServico || '',
          localAtendimento: osResult[0].localAtendimento || '',
          tipoServicoAgendamento: osResult[0].tipoServicoAgendamento || '',
        };

        if (osResult[0].tipoServicoAgendamento === 'MANUTENCAO') {
          buscaSenhaSupervisor(osResult[0].tz_Numero_Serie_Contrato);
        }

        setOS(_os);
        setObservacao(
          _os.observacao
            ? _os.observacao
            : 'Técnico - \nSérie - \nPlaca - \nSatélite - \nTem carreta sim ou não? \nPlaca da carreta -'
        );

        if (classificacaoPrestador !== 2) {
          if (osOrcamento) {
            let _itensOS = [];

            osOrcamento.map((o) => {
              let item = {
                itemID: o.tz_item_ordem_servicoId,
                produtoId: o.tz_ItemId,
                numeroProduto: o.produtoNumero,
                produto: o.produtoNome,
                quantidade: o.quantidade,
                descStatus: o.descStatus_aprovacao,
                statusAprovacao: o.status_aprovacao,
                motivoOrcamento: mapCodigoToMotivo(o.tz_motivo_orcamento)
              };

              _itensOS.push(item);

            });

            setItensOS(_itensOS);
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoad(false);
    }
  };

  // pega os dados da vistoria se existir
  async function getVistoria() {
    try {
      setLoadVistoria(true);
      const { osID, activityId } = os;

      const responseVistoria = await vistoriaService.consultaVistoria(
        activityId,
        osID
      );

      setVistoria(responseVistoria);

      if (responseVistoria?.error) {
        setMensagemAlert({ title: 'Erro!', body: 'Não foi possível obter o status da vistoria.' });
        setTipoAlert('error');
        setAbrirAlert(true);
      } else {
        if (responseVistoria) setDadosVistoria(responseVistoria);
      }
    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Não foi possível obter o status da vistoria.' });
      setTipoAlert('error');
      setAbrirAlert(true);
    } finally {
      setLoadVistoria(false);
    }
  }

  const salvarProdutos = async () => {
    setLoadSave(true);
    let salvarOBSResult = await ordemServicoServico.observacao(
      JSON.stringify({
        Observacao: observacao,
        OrdemServicoID: os.osID,
      })
    );

    setLoadSave(false);

    if (salvarOBSResult.error) {
      setMensagemAlert(salvarOBSResult.message);
      setAbrirAlert(true);
      return false;
    }

    setMensagemAlert({ title: 'Sucesso!', body: 'Os produtos foram enviados com sucesso!' });
    setTipoAlert('success');
    setAbrirAlert(true);

    return true;
  };

  const handleSalvarOS = async () => {
    setEfeitoCarregando(true);
    const salvou = await salvarOS();
    if (salvou) {
      setTimeout(() => {
        setEfeitoCarregando(false);
        window.location.reload();
      }, 5000);
    }
    setEfeitoCarregando(false);
  };

  const handleOpenDialogContraSenha = () => setOpenDialogContraSenha(true);

  const handleCloseDialogContraSenha = () => setOpenDialogContraSenha(false);

  const handleOpenDialogVistoria = () => setOpenDialogVistoria(true);

  const handleCloseDialogVistoria = () => setOpenDialogVistoria(false);

  const addProduto = async (produtoID, produto, quantidade, codProduto, ehServico, motivo) => {
    try {
      let produtoOrdemServico = {
        OrdemId: os.osID,
        produtoId: produtoID.trim(),
        produtoNome: produto,
        quantidade: parseInt(quantidade),
        TecnicoAddItem: authService.getUserInfo().usuario.login,
        UsuarioAprovacaoPortal: '',
        StatusItemOrdem: '1',
        CodProduto: codProduto,
        tz_area_atendimento_microsigaid:
          authService.getUserInfo().usuario.tz_area_atendimento_microsigaid,
        na: os.na,
        os: os.os,
        idUsuario: 12,
        numSerieContrato: os?.num_serie_contrato,
        numSerieAntenaContrato: os?.antena,
        ehServico: ehServico,
        motivo: motivo
      };

      let resultAddProduto = await ordemServicoServico.addProduto(
        JSON.stringify(produtoOrdemServico)
      );

      if (resultAddProduto.error === true) {
        setMensagemAlert({ title: 'Erro!', body: resultAddProduto.message });
        setTipoAlert('error');
        setAbrirAlert(true);

        return false;
      }

      setMensagemAlert({ title: 'Sucesso!', body: 'Produto adicionado com sucesso.' });
      setTipoAlert('success');
      setAbrirAlert(true);
      return true;
      
    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Erro ao executar operação.' });
      setTipoAlert('error');
      setAbrirAlert(true);

      return false;
    }
  };

  const alteraProduto = async (
    produtoId,
    produto,
    quantidade,
    codProduto,
    itemOrdemID,
    ehServico
  ) => {
    try {
      let produtoOrdemServico = {
        OrdemId: os.osID,
        produtoId: produtoId,
        produtoNome: produto,
        quantidade: parseInt(quantidade),
        TecnicoAddItem: authService.getUserInfo().usuario.login,
        UsuarioAprovacaoPortal: '',
        StatusItemOrdem: '1',
        CodProduto: codProduto,
        tz_area_atendimento_microsigaid:
          authService.getUserInfo().usuario.tz_area_atendimento_microsigaid,
        na: os.na,
        os: '',
        idUsuario: 12,
        estoqueVirtual: true,
        ItemOrdemId: itemOrdemID,
        numSerieContrato: os?.num_serie_contrato,
        numSerieAntenaContrato: os?.antena,
        ehServico: ehServico
      };

      let resultAltProduto = await ordemServicoServico.alteraProduto(
        JSON.stringify(produtoOrdemServico),
        itemOrdemID
      );

      if (resultAltProduto.error === true) {
        setMensagemAlert({ title: 'Erro!', body: resultAltProduto.message });
        setTipoAlert('error');
        setAbrirAlert(true);

        return false;
      }

      return true;
    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Erro ao executar operação.' });
      setTipoAlert('error');
      setAbrirAlert(true);

      return false;
    }
  };

  const salvarOS = async () => {
    setLoadSave(true);

    let salvarOBSResult = await ordemServicoServico.observacao(
      JSON.stringify({
        Observacao: observacao,
        OrdemServicoID: os.osID,
      })
    );

    setLoadSave(false);

    if (salvarOBSResult.error) {
      setMensagemAlert({ title: 'Erro!', body: salvarOBSResult.message });
      setTipoAlert('error');
      setAbrirAlert(true);

      return false;
    }

    setMensagemAlert({ title: 'Sucesso!', body: 'As alterações foram salvas.' });
    setTipoAlert('success');
    setAbrirAlert(true);

    return true;
  };

  const handleFecharOS = async () => {
    setEfeitoCarregandoAtualizarBase(true);
    // salva a OS antes de ir para tela de fechamento

    //Apenas atualiza OS se tecnico não for tcl
    if (classificacaoPrestador !== 2) {
      const salvou = await salvarOS();
      setEfeitoCarregandoAtualizarBase(false);
      if (!salvou) return;
    }

    props.history.push({
      pathname: '/atividadeservico',
      state: {
        na: os.na,
      },
    });
  };

  const renderVistoriaStatusAlert = (status, title) => {
    const alertStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' };

    switch (status) {
      case 'Aceito':
        return (
          <Alert severity="success" icon={<CheckCircleOutline style={{ color: 'green' }} />} style={alertStyle}>
            <AlertTitle>{title}</AlertTitle>
            Aceito
          </Alert>
        );

      case 'Recusado':
        return (
          <Alert severity="error" icon={<HighlightOff style={{ color: 'red' }} />} style={alertStyle}>
            <AlertTitle>{title}</AlertTitle>
            Recusado
          </Alert>
        );

      case 'Aguardando aprovação':
        return (
          <Alert severity="warning" icon={<HourglassBottomIcon style={{ color: 'orange' }} />} style={alertStyle}>
            <AlertTitle>{title}</AlertTitle>
            Aguardando aprovação
          </Alert>
        );

      default:
        return (
          <Alert severity="info" icon={<HourglassEmpty style={{ color: '#1976d2' }} />} style={alertStyle}>
            <AlertTitle>{title}</AlertTitle>
            Não Enviado
          </Alert>
        );
    }
  };

  return !openDialogVistoria ? (
    <>
      <MenuComponent />
      {
        load ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          <>
            <CustomAlert
              open={abrirAlert}
              message={mensagemAlert}
              type={tipoAlert}
              onClose={() => setAbrirAlert(false)}
            />
            <div style={{ margin: 30 }}>
              <h3 className={classes.tituloStyle}>Ordem de Serviço</h3>
              <h4 className={classes.subtituloStyle}>
                <span
                  onClick={() => window.location.href = '/agenda'}
                  className={classes.linkStyle}
                  style={{ cursor: 'pointer' }}
                >
                  Home
                </span> {'>'} Ordem de Serviço
              </h4>

              <Paper className={classes.paper}>
                <span className={classes.title}>Detalhes do Agendamento</span>
                <Grid container style={{ marginTop: 5 }} spacing={3}>
                  <Grid item xs={12} className={classes.itemText}>
                    <strong>Cliente:</strong> {os?.cliente}
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.itemText}>
                    <strong>Solicitante:</strong> {os?.solicitante}
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.itemText}>
                    <strong>Telefone do Solicitante:</strong> {os?.telefone_solicitante}
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.itemText}>
                    <strong>Endereço:</strong> {os?.endereco}
                  </Grid>
                  <Grid item xs={4} sm={6} className={classes.itemText}>
                    <strong>Número:</strong> {os?.numero}
                  </Grid>
                  <Grid item xs={4} sm={6} className={classes.itemText}>
                    <strong>Bairro:</strong> {os?.bairro}
                  </Grid>
                  <Grid item xs={4} sm={6} className={classes.itemText}>
                    <strong>CEP:</strong> {os?.cep}
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.itemText}>
                    <strong>Cidade/Estado:</strong> {os?.cidade} {os?.cidade && os?.estado && ' - '} {os?.estado}
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.itemText}>
                    <strong>Referência:</strong> {os?.referencia}
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.itemText}>
                    <strong>Razão Status:</strong> {os?.razaoStatus}
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.itemText}>
                    <strong>Última Modificação:</strong> {os?.modificacao ? moment(os?.modificacao).format('DD/MM/YYYY HH:MM:SS') : ''}
                  </Grid>
                  <Grid item xs={6} sm={6} className={classes.itemText}>
                    <strong>Data Início:</strong> {os?.inicio ? moment(os?.inicio).format('DD/MM/YYYY HH:MM:SS') : ''}
                  </Grid>
                  <Grid item xs={6} sm={6} className={classes.itemText}>
                    <strong>Data Fim:</strong> {os?.fim ? moment(os?.fim).format('DD/MM/YYYY HH:MM:SS') : ''}
                  </Grid>
                  <Grid item xs={6} sm={6} className={classes.itemText}>
                    <strong>NA:</strong> {os?.na}
                  </Grid>
                  <Grid item xs={6} sm={6} className={classes.itemText}>
                    <strong>Plataforma:</strong> {os?.plataforma}
                  </Grid>
                  <Grid item xs={6} sm={6} className={classes.itemText}>
                    <strong>Cenário de Venda:</strong> {os?.cenarioVenda}
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.itemText}>
                    <strong>Serviço:</strong> {os?.servico}
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.itemText}>
                    <strong>Rastreador:</strong> {os?.rastreador}
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.itemText}>
                    <strong>Plano:</strong> {os?.plano}
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.itemText}>
                    <strong>Placa do veículo:</strong> {os?.placa}
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.itemText}>
                    <strong>Número de Série do Contrato:</strong> {os?.num_serie_contrato}
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.itemText} hidden={os?.num_serie_antena_contrato == ''}>
                    <strong>Número de Série da Antena do Contrato:</strong> {os?.num_serie_antena_contrato}
                  </Grid>
                  <Grid item xs={6} sm={6} className={classes.itemText}>
                    <strong>Fim Garantia:</strong> {os?.fim_garantia ? os?.fim_garantia : ''}
                  </Grid>
                  {classificacaoPrestador !== 2 && (
                    <>
                      <Grid item xs={6} sm={6} className={classes.itemText}>
                        <strong>Status OS: </strong> {os?.status === 'Concluido' ? 'Concluído' : os?.status}
                      </Grid>
                      <Grid item xs={6} sm={6} className={classes.itemText}>
                        <strong>Número OS:</strong> {os?.os}
                      </Grid>
                    </>
                  )}
                  {painelAtivacaoEquipamento && (
                    <Grid item xs={12} sm={6} className={classes.itemText}>
                      <strong>Status de Ativação do Equipamento:</strong> {painelAtivacaoEquipamento.status}
                    </Grid>
                  )}
                  {classificacaoPrestador !== 2 && (
                    <>
                      <Grid item xs={12} className={classes.itemText}>
                        <strong>Observação do Cliente:</strong> {os?.obsCliente}
                      </Grid>
                      <Grid container spacing={2} alignItems="center" justifyContent="flex-end" style={{ margin: 5 }}>
                        <Grid item xs={12}>
                          <BlueTextField
                            name="txtobs"
                            fullWidth
                            label="Observação"
                            onChange={(event) => setObservacao(event.target.value)}
                            value={observacao}
                            multiline
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <BlueButton
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.button}
                            startIcon={efeitoCarregando ? <CircularProgress size={20} /> : <SaveIcon />}
                            onClick={handleSalvarOS}
                            disabled={efeitoCarregando}
                            style={{ height: 55 }}
                          >
                            <span style={{ fontSize: '0.7rem' }}>
                              {efeitoCarregando ? 'Salvando... ' : ' Salvar Observação'}
                            </span>
                          </BlueButton>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Paper>

              <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                <ExpansionPanel style={{ marginBottom: 20, borderRadius: 10 }}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid item xs={12} className={classes.expansionSubtitle}>
                      Contrassenha
                    </Grid>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <ContraSenhaComponent os={os} handleBack={handleCloseDialogContraSenha} />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>

              {os?.tipoServicoAgendamento == 'MANUTENCAO' ? (
                <>
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <ExpansionPanel style={{ marginBottom: 20, borderRadius: 10 }}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid item xs={12} className={classes.expansionSubtitle}>
                          Senha do Supervisor
                        </Grid>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <SenhaSupervisorComponent
                          usuario={os?.cliente}
                          numeroEquipamento={os?.num_serie_contrato}
                          dadosSenhaSupervisor={senhaSupervisor}
                          mensagem={mensagemSenhaNaoEncontrada}
                        />
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                </>
              ) : null}

              {classificacaoPrestador !== 2 && (
                <>
                  <Paper className={classes.paper}>
                    <span className={classes.title}>Status da Vistoria</span>
                    {loadVistoria ? (
                      <Grid container alignItems="center" justifyContent="center">
                        <CircularProgress color="#209BDE" style={{ color: '#209BDE' }} />
                      </Grid>
                    ) : (
                      <Grid container style={{ marginTop: 5, marginBottom: 10 }} spacing={2}>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                          <Grid item xs={12} md={6} style={{ padding: 16 }}>
                            {renderVistoriaStatusAlert(statusVistoria(dadosVistoria, 'entrada'), 'Entrada')}
                          </Grid>
                          <Grid item xs={12} md={6} style={{ padding: 16 }}>
                            {renderVistoriaStatusAlert(statusVistoria(dadosVistoria, 'saida'), 'Saída')}
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Paper>

                  <Paper className={classes.paper}>
                    <span className={classes.title}>Orçamento</span>
                    {loadVistoria ? (
                      <Grid container alignItems="center" justifyContent="center">
                        <CircularProgress color="#209BDE" style={{ color: '#209BDE' }} />
                      </Grid>
                    ) : (
                      <Grid container style={{ marginTop: 5 }} spacing={2}>
                        <Grid item xs={12} style={{ marginBottom: 10 }}>
                          <ProdutosTable
                            addProduto={addProduto}
                            alteraProduto={alteraProduto}
                            consultaDetalheOS={consultaDetalheOS}
                            estoqueItens={estoqueItens}
                            itensOS={itensOS}
                            os={os}
                            consultaProdutosEstoque={produtosEstoque}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Paper>
                </>
              )}

              {os && classificacaoPrestador === 2 && (
                <>
                  <Paper className={classes.paper}>
                    <Grid item xs={12} className={classes.expansionSubtitle}>
                      Anotações
                    </Grid>
                    <Box style={{ padding: 20 }}>
                      <Anotacao ordemServico={os} />
                    </Box>
                  </Paper>
                </>
              )}

              <Grid container spacing={2} alignItems="center" justifyContent="flex-end" style={{ marginTop: '30px' }}>
                {classificacaoPrestador !== 2 && (
                  <Grid item xs={12} sm={3} md={2}>
                    <BlueButton
                      fullWidth
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<AssignmentIcon />}
                      onClick={handleOpenDialogVistoria}
                      style={{ height: 55 }}
                    >
                      <span style={{ fontSize: '0.7rem' }}>{statusVistoria(dadosVistoria, 'entrada') == 'Aceito' ? 'Visualizar Vistoria' : 'Vistoria de Entrada'}</span>
                    </BlueButton>
                  </Grid>
                )}

                <Grid item xs={12} sm={3} md={2}>
                  <GreenButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={efeitoCarregandoAtualizarBase ? <CircularProgress size={20} /> : <RestoreOutlined />}
                    onClick={handleFecharOS}
                    disabled={disabledAtualizarBase || efeitoCarregandoAtualizarBase}
                    style={{ height: 55 }}
                  >
                    <span style={{ fontSize: '0.7rem' }}>
                      {efeitoCarregandoAtualizarBase ? 'ATUALIZANDO... ' : '  ATUALIZAR BASE'}
                    </span>
                  </GreenButton>
                </Grid>
                <Grid item xs={12} sm={3} md={2}>
                  <BlueButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      window.location.reload();
                    }}
                    startIcon={<RefreshIcon />}
                    style={{ height: 55 }}
                  >
                    <span style={{ fontSize: '0.7rem' }}>RECARREGAR PÁGINA</span>
                  </BlueButton>
                </Grid>
              </Grid>

              <Box
                sx={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-end' }}
                container
                style={{ marginTop: '30px' }}
              >

                {alerItensAprovada && <Alert severity='error'>
                  OBS: Botão atualizar base disponivel após todos os itens serem aprovados!
                </Alert>}

                {alertVistoriaAprovada && <Alert severity='error'>
                  OBS: Botão atualizar base disponivel apenas após vistoria de entrada ser aprovada!
                </Alert>}
              </Box>
            </div>
          </>
        )
      }
    </>
  ) : (
    <>
      <MenuComponent />
      <OSVistoriaPage
        ordemServico={os}
        handleBack={handleCloseDialogVistoria}
        itensOS={itensOS}
        tipoVistoria="entrada"
      />
    </>
  );
}
