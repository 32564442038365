import React, { useEffect, useState } from 'react';
import { useMediaQuery, useTheme, CircularProgress, DialogContent } from '@material-ui/core';
import MenuComponent from '../../components/menu/menu-component';
import auditoriaService from '../../Services/Auditoria/auditoria-service';
import BlueButton from '../../components/button/BlueButton';
import { MaterialTableCustom } from '../../components/table/material-table-custom';
import TablePagination from '@material-ui/core/TablePagination';
import ListAltIcon from '@mui/icons-material/ListAlt';
import authService from '../../Services/auth/auth-service';
import { Paper, Grid } from '@material-ui/core';
import InventoryIcon from '@mui/icons-material/Inventory';
import { makeStyles } from '@material-ui/core/styles';
import EstoquePage from '../../estoque/estoque-page';
import NotasFiscaisPage from '../../notafiscal/nota-fiscal-page';
import EstoqueSolicitacaReversaPage from '../../estoque/solicitacao-reversa-page';
import ServicosPage from '../../servicos/servicos-page';
import DescriptionIcon from '@mui/icons-material/Description';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import PerfilTecnicoPage from '../../perfilTecnico/perfil-tecnico-page';
import EngineeringIcon from '@mui/icons-material/Engineering';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Visibility from '@mui/icons-material/Visibility';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
    searchIcon: {
        marginRight: 4,
    },
    searchInput: {
        flex: 1,
        paddingVertical: 8,
        fontSize: 18,
    },
    paper: {
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 0px 15px',
        borderRadius: 10,
    },
    tituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
        marginBottom: '5px'
    },
    mensagemStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
    },
    subtituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#7F7F7F',
        fontSize: '16px',
        fontWeight: 'inherit',
        textAlign: 'start',
        padding: '0 0px',
        marginTop: '0px'
    },
    infos: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    search: {
        marginBottom: 40,
    },
    item: {
        marginBottom: 30,
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    }
}));

export default function GestorTecnicoPage() {
    // eslint-disable-next-line no-undef
    const [isMobile, setISMobile] = useState(false)
    const classes = useStyles();
    const mobile = useMediaQuery(useTheme().breakpoints.down(800))
    const [tecnicos, setTecnicos] = useState([]);
    const [estoque, setEstoque] = useState(false);
    const [ordemServico, setOrdemServico] = useState(false);
    const [notaFiscal, setNotaFiscal] = useState(false);
    const [reversa, setReversa] = useState(false);
    const [solicitacaoCadastro, setSolicitacaoCadastro] = useState(false);
    const [microSigaID, setMicroSigaID] = useState('');
    const [page, setPage] = useState(0);
    let [pageSize, setPageSize] = useState(10);
    const [totalDados, setTotalDados] = useState(0);
    const [carregandoTable, setCarregandoTable] = useState(false);
    const [permissaoGestor, setPermissaoGestor] = useState(0);
    const [tableKey, setTableKey] = useState(0)
    const [pesquisa, setPesquisa] = useState('');
    const [showDetails, setShowDetails] = useState(false);
    const [nomeTecnico, setNomeTecnico] = useState('');
    const [loading, setLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);

    const abrirMenu = (event, tz_area_atendimento_microsigaid, nomeTecnico) => {
        setMicroSigaID(tz_area_atendimento_microsigaid);
        setNomeTecnico(nomeTecnico);
        setAnchorEl(event.currentTarget);
    };

    const fecharMenu = () => {
        setAnchorEl(null);
    };

    const dadosTecnicos = async (page, pageSize, pesquisa) => {
        setCarregandoTable(true);

        const dados = {
            pagina: page ? page : 0,
            limite: pageSize ? pageSize : 10,
            nome: pesquisa ? pesquisa : '',
        }

        const response = await auditoriaService.listarUsuariosGestor(dados);

        if (response.dados) {
            setTecnicos(response.dados);
            setTotalDados(response.total);
        }
        setCarregandoTable(false);
    }

    const handleOrdemServico = (tz_area_atendimento_microsigaid, nomeTecnico) => {
        setOrdemServico(true);
        setShowDetails(!showDetails);
        setNomeTecnico(nomeTecnico);
        setMicroSigaID(tz_area_atendimento_microsigaid);
        fecharMenu();
    };

    const handleEstoque = (tz_area_atendimento_microsigaid, nomeTecnico) => {
        setEstoque(true);
        setNomeTecnico(nomeTecnico);
        setShowDetails(!showDetails);
        setMicroSigaID(tz_area_atendimento_microsigaid);
        fecharMenu();
    };

    const handleCloseOrdemServico = () => {
        setOrdemServico(null);
        valorPesquisa('');
        setShowDetails(false);
        setMicroSigaID('');
        fecharMenu();
    };

    const handleClose = () => {
        setEstoque(null);
        valorPesquisa('');
        setShowDetails(false);
        setMicroSigaID('');
        fecharMenu();
    };

    const handleCloseNFGestorTecnico = () => {
        setNotaFiscal(null);
        valorPesquisa('');
        setShowDetails(false);
        setMicroSigaID('');
        fecharMenu();
    };

    const handleCloseReversaGestorTecnico = () => {
        setReversa(null);
        valorPesquisa('');
        setShowDetails(false);
        setMicroSigaID('');
        fecharMenu();
    };

    const handleCloseSolicitacaoCadastroestorTecnico = () => {
        setSolicitacaoCadastro(null);
        valorPesquisa('');
        setShowDetails(false);
        setMicroSigaID('');
        fecharMenu();
    };

    const handleNF = (tz_area_atendimento_microsigaid, nomeTecnico) => {
        setNotaFiscal(true);
        setShowDetails(!showDetails);
        setNomeTecnico(nomeTecnico);
        setMicroSigaID(tz_area_atendimento_microsigaid);
        fecharMenu();
    };

    const handleReversa = (tz_area_atendimento_microsigaid, nomeTecnico) => {
        setReversa(true);
        setShowDetails(!showDetails);
        setNomeTecnico(nomeTecnico);
        setMicroSigaID(tz_area_atendimento_microsigaid);
        fecharMenu();
    };

    const handleSolicitacaoCadastroTecnico = (tz_area_atendimento_microsigaid, nomeTecnico) => {
        setSolicitacaoCadastro(true);
        setShowDetails(!showDetails);
        setNomeTecnico(nomeTecnico);
        setMicroSigaID(tz_area_atendimento_microsigaid);
        fecharMenu();
    };

    const valorPesquisa = (nome) => {
        setPesquisa(nome);
        dadosTecnicos(0, pageSize, nome);
    };

    const mudarPagina = (event, newPage) => {
        setPage(newPage);
        dadosTecnicos(newPage, pageSize, pesquisa);
    };

    const mudarTamanhoPagina = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
        dadosTecnicos(0, parseInt(event.target.value, 10), pesquisa);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await dadosTecnicos();
            setPermissaoGestor(authService.getUserInfo().usuario.gestor);
            setTableKey(tableKey + 1)
            setLoading(false);
        };

        fetchData();
    }, [mobile]);

    if (loading) {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            {permissaoGestor == true ? (
                <>
                    {!showDetails && (
                        <>
                            <MenuComponent />
                            <div style={{ margin: 30 }}>
                                <h3 className={classes.tituloStyle}>Usuários</h3>
                                <h4 className={classes.subtituloStyle}>
                                    <span
                                        onClick={() => window.location.href = '/gestor'}
                                        className={classes.linkStyle}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Home
                                    </span> {'>'} Usuários
                                </h4>

                                <Paper className={classes.paper}>
                                    <div key={tableKey} style={{ marginBottom: '16px', overflowX: 'auto' }}>
                                        <MaterialTableCustom
                                            key={pageSize}
                                            title=""
                                            columns={[
                                                { title: 'Código', field: 'id', width: '20%', hidden: mobile },
                                                { title: 'Nome', width: '20%', field: 'nome' },
                                                { title: 'Login', width: '20%', field: 'login', hidden: mobile },
                                                { title: 'Data Último Login', field: 'ultimoLogin', width: '20%', render: (rowData) => rowData.ultimoLogin ? moment(rowData.ultimoLogin).format('DD/MM/YYYY HH:mm:ss') : '', hidden: mobile },
                                                {
                                                    title: 'Visão Gestor',
                                                    field: '',
                                                    width: '20%',
                                                    hidden: mobile,
                                                    render: (rowData) => (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '40px',
                                                                height: '40px',
                                                                backgroundColor: '#209BDE',
                                                                borderRadius: '5px',
                                                                border: '2px solid #209BDE',
                                                                cursor: 'pointer',
                                                            }}
                                                        >

                                                            <IconButton
                                                                aria-label="more"
                                                                aria-controls="long-menu"
                                                                aria-haspopup="true"
                                                                onClick={(event) => abrirMenu(event, rowData.tz_area_atendimento_microsigaid, rowData.nome)}
                                                                style={{ color: '#fff' }}
                                                            >
                                                                <Visibility />
                                                            </IconButton>

                                                            <Menu
                                                                anchorEl={anchorEl}
                                                                keepMounted
                                                                open={Boolean(anchorEl)}
                                                                onClose={fecharMenu}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                                elevation={2}
                                                            >
                                                                <MenuItem onClick={() => handleOrdemServico(microSigaID, nomeTecnico)}>
                                                                    <ListAltIcon style={{ marginRight: '8px', color: '#209BDE' }} /> Ordens de Serviço
                                                                </MenuItem>
                                                                <MenuItem onClick={() => handleEstoque(microSigaID, nomeTecnico)}>
                                                                    <InventoryIcon style={{ marginRight: '8px', color: '#209BDE' }} /> Estoque
                                                                </MenuItem>
                                                                <MenuItem
                                                                    onClick={() => handleNF(microSigaID, nomeTecnico)}
                                                                    disabled={rowData.classificacaoPrestador === 2}
                                                                >
                                                                    <DescriptionIcon style={{ marginRight: '8px', color: '#209BDE' }} /> Notas Fiscais
                                                                </MenuItem>
                                                                <MenuItem
                                                                    onClick={() => handleReversa(microSigaID, nomeTecnico)}
                                                                    disabled={rowData.classificacaoPrestador === 2}
                                                                >
                                                                    <SettingsBackupRestoreIcon style={{ marginRight: '8px', color: '#209BDE' }} /> Solicitação Reversa
                                                                </MenuItem>
                                                                <MenuItem onClick={() => handleSolicitacaoCadastroTecnico(microSigaID, nomeTecnico)}>
                                                                    <EngineeringIcon style={{ marginRight: '8px', color: '#209BDE' }} /> Solicitação de Cadastro Técnico
                                                                </MenuItem>
                                                            </Menu>
                                                        </div>
                                                    ),
                                                    sorting: false,
                                                },
                                            ]}
                                            data={tecnicos}
                                            options={{
                                                search: true,
                                                pageSize: pageSize,
                                            }}
                                            localization={{
                                                body: {
                                                    emptyDataSourceMessage: carregandoTable ? (
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                                            <CircularProgress />
                                                        </div>
                                                    ) : 'Nenhum registro para exibir'
                                                },
                                                toolbar: {
                                                    searchTooltip: 'Pesquisar',
                                                    searchPlaceholder: 'Pesquisar',
                                                },
                                            }}
                                            detailPanel={[
                                                (rowData) => ({
                                                    disabled: !mobile,
                                                    render: (rowData) => {
                                                        return (
                                                            <div style={{ width: '100%' }}>
                                                                <DialogContent dividers style={{ width: '100%', height: '100%' }}>
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={12} sm={12}>
                                                                            <strong>Código:</strong>{' '}
                                                                            {rowData.id}
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12}>
                                                                            <strong>Login:</strong>{' '}
                                                                            {rowData.login}
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12}>
                                                                            <strong>Data Último Login:</strong>{' '}
                                                                            {rowData.ultimoLogin ? moment(rowData.ultimoLogin).format('DD/MM/YYYY HH:mm:ss') : ''}
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12}>
                                                                            <strong>Visão Gestor:</strong>{' '}

                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12}>
                                                                            <Grid item xs={12} md={12} lg={3}>
                                                                                <BlueButton
                                                                                    fullWidth
                                                                                    style={{ height: 55 }}
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    size="large"
                                                                                    startIcon={<InventoryIcon />}
                                                                                    onClick={() => handleEstoque(rowData.tz_area_atendimento_microsigaid, rowData.nome)}>
                                                                                    <span style={{ fontSize: '0.7rem' }}>
                                                                                        Estoque
                                                                                    </span>
                                                                                </BlueButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12}>
                                                                            <Grid item xs={12} md={12} lg={3}>
                                                                                <BlueButton
                                                                                    fullWidth
                                                                                    style={{ height: 55 }}
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    startIcon={<ListAltIcon />}
                                                                                    size="large"
                                                                                    onClick={() => handleOrdemServico(rowData.tz_area_atendimento_microsigaid, rowData.nome)}>
                                                                                    <span style={{ fontSize: '0.7rem' }}>
                                                                                        Ordens de Serviço
                                                                                    </span>
                                                                                </BlueButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12}>
                                                                            <Grid item xs={12} md={12} lg={3}>
                                                                                <BlueButton
                                                                                    fullWidth
                                                                                    style={{ height: 55 }}
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    startIcon={<DescriptionIcon />}
                                                                                    size="large"
                                                                                    onClick={() => handleNF(rowData.tz_area_atendimento_microsigaid, rowData.nome)}
                                                                                    disabled={rowData.classificacaoPrestador === 2}>
                                                                                    <span style={{ fontSize: '0.7rem' }}>
                                                                                        Notas Fiscais
                                                                                    </span>
                                                                                </BlueButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12}>
                                                                            <Grid item xs={12} md={12} lg={3}>
                                                                                <BlueButton
                                                                                    fullWidth
                                                                                    style={{ height: 55 }}
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    startIcon={<SettingsBackupRestoreIcon />}
                                                                                    size="large"
                                                                                    onClick={() => handleReversa(rowData.tz_area_atendimento_microsigaid, rowData.nome)}
                                                                                    disabled={rowData.classificacaoPrestador === 2}>
                                                                                    <span style={{ fontSize: '0.7rem' }}>
                                                                                        Solicitação de Reversa
                                                                                    </span>
                                                                                </BlueButton>

                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12}>
                                                                            <Grid item xs={12} md={12} lg={3}>
                                                                                <BlueButton
                                                                                    fullWidth
                                                                                    style={{ height: 55 }}
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    startIcon={<EngineeringIcon />}
                                                                                    size="large"
                                                                                    onClick={() => handleSolicitacaoCadastroTecnico(rowData.tz_area_atendimento_microsigaid, rowData.nome)}>
                                                                                    <span style={{ fontSize: '0.7rem' }}>
                                                                                        Solicitação de Cadastro Técnico
                                                                                    </span>
                                                                                </BlueButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </DialogContent>
                                                            </div>
                                                        );
                                                    },
                                                }),
                                            ]
                                            }
                                            components={{
                                                Pagination: () => (
                                                    <TablePagination
                                                        style={{ padding: '0 0px', overflow: 'hidden' }}
                                                        onPageChange={mudarPagina}
                                                        page={page}
                                                        count={totalDados}
                                                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                                        rowsPerPage={pageSize}
                                                        labelRowsPerPage="Linhas"
                                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                                        onRowsPerPageChange={mudarTamanhoPagina}
                                                    />
                                                ),
                                            }}
                                        />
                                    </div>
                                </Paper>
                            </div>
                        </>
                    )}
                </>)
                : (
                    <>
                        <MenuComponent />
                        <div style={{ margin: 30 }}>
                            <h3 className={classes.tituloStyle}>Usuários</h3>
                            <h4 className={classes.subtituloStyle}>
                                <span
                                    onClick={() => window.location.href = '/gestor'}
                                    className={classes.linkStyle}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Home
                                </span> {'>'} Usuários
                            </h4>


                            <Paper className={classes.paper}>
                                <Grid container justifyContent="center">
                                    <h2 className={classes.mensagemStyle} style={{ padding: 10 }}>Você não tem permissão para acessar esta página!</h2>
                                </Grid>
                            </Paper>
                        </div>
                    </>
                )}
            {ordemServico == true && showDetails && <ServicosPage microSigaID={microSigaID} handleCloseOrdemServico={handleCloseOrdemServico} nomeTecnico={nomeTecnico} />}
            {estoque == true && showDetails && <EstoquePage microSigaID={microSigaID} handleClose={handleClose} nomeTecnico={nomeTecnico} />}
            {notaFiscal == true && showDetails && <NotasFiscaisPage microSigaID={microSigaID} handleCloseNFGestorTecnico={handleCloseNFGestorTecnico} nomeTecnico={nomeTecnico} />}
            {reversa == true && showDetails && <EstoqueSolicitacaReversaPage microSigaID={microSigaID} handleCloseReversaGestorTecnico={handleCloseReversaGestorTecnico} nomeTecnico={nomeTecnico} />}
            {solicitacaoCadastro == true && showDetails && <PerfilTecnicoPage microSigaID={microSigaID} handleCloseSolicitacaoCadastroestorTecnico={handleCloseSolicitacaoCadastroestorTecnico} nomeTecnico={nomeTecnico} />}
        </>
    );
}
