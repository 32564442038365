import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';

const theme = createTheme({
  components: {
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: '14px',
          marginLeft: '5px',
        },
      },
    },
  },
});

function AtividadeDialog({ open, handleClose, atividade }) {
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        aria-labelledby="customized-dialog-title"
        onClose={handleClose}
        open={open}
        className="custom-dialog"
        style={{ marginTop: 35, marginBottom: 10 }}
        disableBackdropClick
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Grid container alignItems="center">
            <Grid item xs={10} sm={11}>
              Detalhes da Atividade
            </Grid>
            <Grid item xs={2} sm={1}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          {atividade && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    <strong>NA:</strong> {atividade.na}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    <strong>Nome do Cliente:</strong> {atividade.clienteNome}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    <strong>Nome do Técnico:</strong> {atividade.tecnicoNome}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    <strong>Checkin:</strong> {atividade.checkin ? moment(atividade.checkin).format('DD/MM/yyyy HH:mm:ss') : 'Não realizado'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    <strong>Checkout:</strong> {atividade.checkout ? moment(atividade.checkout).format('DD/MM/yyyy HH:mm:ss') : 'Não realizado'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    <strong>Prazo de Checkin:</strong> {atividade.prazoCheckin ? moment(atividade.prazoCheckin).format('DD/MM/yyyy HH:mm:ss') : ''}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    <strong>Prazo de Checkout:</strong> {atividade.prazoCheckout ? moment(atividade.prazoCheckout).format('DD/MM/yyyy HH:mm:ss') : ''}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    <strong>Status:</strong> {atividade.status}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'end' }}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleClose}
            style={{
              backgroundColor: '#E0E0E0',
              color: '#000',
              height: 55,
              marginBottom: '10px',
              marginTop: '10px',
              width: '40%'
            }}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

    </ThemeProvider>
  );
}

export default AtividadeDialog;
