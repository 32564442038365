import authService from '../auth/auth-service';
import config from '../../config';

const _userInfo = authService.getUserInfo();
const _configAPITecnico = config.configAPITecnico();

class PerfilService {
  async AlterarSenha(usuario) {
    try {
      let response = await fetch(
        `${_configAPITecnico.URI_API}/usuario/senha`,
        {
          method: 'PUT',
          headers: authService.headerAPITecnico(),
          body: usuario,
        },
      );

      if (response.status === 204) return { success: true };
      if (response.status === 400) return await response.json();

    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }

  async getInformacoesPerfilOuro() {
    try {
      let response = await fetch(
        `${_configAPITecnico.URI_API}/perfilValeOuro/${_userInfo.usuario.areaAtendimentoErp}`,
        {
          method: 'GET',
          headers: authService.headerAPITecnico(),
        },
      );
      if (response.status === 200) return await response.json();
    } catch (error) {
      console.log(error);
    }
  }

  async getInformacoesTecnico() {
    try {
      let response = await fetch(
        `${_configAPITecnico.URI_API}/tecnico/buscarTecnico?Email=${_userInfo.usuario.login}`,
        {
          method: 'GET',
          headers: authService.headerAPITecnico(),
        },
      );
      if (response.status === 200) return await response.json();
    } catch (error) {
      console.log(error);
    }
  }


  async consultaperfiltecnico(idtecnico) {
    try {
      let response = await fetch(`http://localhost:58584/api/consultaperfil/consultacadastroperfil${idtecnico}`, {
        method: 'GET',
        headers: authService.headerAPITecnico()
      });
      if (response.status === 200)
        return await response.json();

    } catch (error) {
      console.log(error);
    }
  }

  async redefinirSenha(dados) {
    try {
      let response = await fetch(
        `${_configAPITecnico.URI_API}/usuario/senha/atualizarSenha`,
        {
          method: 'PUT',
          headers: authService.headerAPITecnico(),
          body: JSON.stringify(dados),
        },
      );

      if (response.status === 204) return { success: true };
      if (response.status === 400) return await response.json();

    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }
}
export default new PerfilService();