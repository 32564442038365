import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    TextField,
    useMediaQuery, useTheme, InputAdornment,
    Paper, Dialog, CircularProgress,
    DialogActions, DialogContent,
    DialogTitle, Button, DialogContentText, Typography
} from '@material-ui/core';
import MenuComponent from '../components/menu/menu-component';
import BlueButton from '../components/button/BlueButton';
import CustomTablePagination from '../components/pagination/pagination-component';
import authService from '../Services/auth/auth-service';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomAlert from '../components/alert/CustomAlert';
import perfilTecnicoService from '../Services/perfilTecnico/perfil-tecnico-services';
import Box from '@mui/material/Box';

//Icons
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        overflowX: 'hidden',
        padding: '0 80px 40px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 20px 40px',
        },
    },
    paper: {
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        borderRadius: 10,
        width: '100%',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '10px',
        },
    },
    tituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
        marginBottom: '5px'
    },
    mensagemStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
    },
    subtituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#7F7F7F',
        fontSize: '16px',
        fontWeight: 'inherit',
        textAlign: 'start',
        padding: '0 0px',
        marginTop: '0px'
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    }

}));

const useStylesSenhaCriterios = makeStyles((theme) => ({
    passwordCriteria: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
}));

const PasswordCriteria = ({ label, valid }) => {
    const classesCriterioSenha = useStylesSenhaCriterios();
    return (
        <Typography variant="body2" className={classesCriterioSenha.passwordCriteria}>
            {valid ? <CheckIcon color="primary" /> : <CloseIcon color="error" />}
            {label}
        </Typography>
    );
};

export default function PerfilTecnicoPage({ microSigaID, handleCloseSolicitacaoCadastroestorTecnico, nomeTecnico }) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const mobile = useMediaQuery(theme.breakpoints.down(800));
    const [registroTecnicoID, setRegistroTecnicoID] = useState('');
    const [page, setPage] = useState(0);
    let [pageSize, setPageSize] = useState(8);
    const [totalDados, setTotalDados] = useState(0);
    const [dadosTecnico, setDadosTecnico] = useState([]);
    const [carregandoTable, setCarregandoTable] = useState(false);
    const [carregandoTela, setCarregandoTela] = useState(true);
    const [carregandoCadastro, setCarregandoCadastro] = useState(false);
    const [carregandoEdicao, setCarregandoEdicao] = useState(false);
    const [carregandoDelete, setCarregandoDelete] = useState(false);
    const [tableKey, setTableKey] = useState(0);
    const [pesquisa, setPesquisa] = useState('');
    const [tecnicoNome, setTecnicoNome] = useState('');
    const [tecnicoLogin, setTecnicoLogin] = useState('');
    const [tecnicoCPF, setTecnicoCPF] = useState('');
    const [tecnicoSenha, setTecnicoSenha] = useState('');
    const [senhaValida, setSenhaValida] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        special: false,
    });
    const [abrirModalCadastro, setAbrirModalCadastro] = useState(false);
    const [abrirModalEditar, setAbrirModalEditar] = useState(false);
    const [abrirModalDeletar, setAbrirModalDeletar] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ title: '', body: '' });
    const [alertType, setAlertType] = useState('success');

    const abrirModalCadastroTecnico = async () => {
        setAbrirModalCadastro(true);
    }

    const fecharModalCadastroTecnico = async () => {
        setTecnicoLogin('');
        setTecnicoNome('');
        setTecnicoCPF('');
        setTecnicoSenha('');
        setAbrirModalCadastro(false);
    }

    const abrirModalEditarTecnico = async (tecnico) => {
        setTecnicoCPF(tecnico.cpf);
        setTecnicoNome(tecnico.nome);
        setTecnicoLogin(tecnico.login);
        setRegistroTecnicoID(tecnico.id);
        setAbrirModalEditar(true);
    }

    const fecharModalEditarTecnico = async () => {
        setAbrirModalEditar(false);
        setTecnicoLogin('');
        setTecnicoNome('');
        setTecnicoCPF('');
        setRegistroTecnicoID('');
    }

    const abrirModalDeletarTecnico = async (produto) => {
        setRegistroTecnicoID(produto.id)
        setTecnicoNome(produto.nome);
        setAbrirModalDeletar(true);
    }

    const fecharModalDeletarTecnico = async () => {
        setRegistroTecnicoID('')
        setTecnicoNome('');
        setAbrirModalDeletar(false);
    }

    const limparPesquisa = () => {
        setPesquisa('');
        buscarTecnicos(0, pageSize, '');
    };

    const cpfChange = (event) => {
        let cpf = event.target.value;

        cpf = cpf.replace(/\D/g, '');

        cpf = cpf.slice(0, 11);

        let cpfFormatado = '';
        for (let i = 0; i < cpf.length; i++) {
            if (i === 3 || i === 6) {
                cpfFormatado += '.';
            }
            else if (i === 9) {
                cpfFormatado += '-';
            }
            cpfFormatado += cpf[i];
        }

        setTecnicoCPF(cpfFormatado)
    }

    const handleAdicionarTecnico = async () => {
        if (tecnicoCPF && tecnicoSenha && tecnicoLogin && tecnicoNome) {
            setCarregandoCadastro(true);

            const dados = {
                nome: tecnicoNome,
                login: tecnicoLogin,
                cpf: tecnicoCPF.replace(/\D/g, ''),
                senha: tecnicoSenha,
                areaAtendimentoId: authService.getUserInfo().usuario.tz_area_atendimento_microsigaid,
                localId: authService.getUserInfo().usuario.localid
            }

            try {
                const response = await perfilTecnicoService.adicionarTecnico(dados);

                if (!response.error) {
                    setAlertMessage({ title: 'Sucesso!', body: 'Técnico cadastrado com sucesso!' });
                    setAlertType('success');
                    setAlertOpen(true);
                    setTecnicoLogin('');
                    setTecnicoNome('');
                    setTecnicoCPF('');
                    setTecnicoSenha('');
                    setRegistroTecnicoID('');
                    fecharModalCadastroTecnico();
                    buscarTecnicos(page, pageSize, pesquisa);
                } else {
                    if (response.message.includes('CPF já está cadastrado')) {
                        setAlertMessage({ title: 'Erro!', body: 'Este CPF já está cadastrado. Por favor, verifique os dados.' });
                    } else if (response.message.includes('Login já está cadastrado')) {
                        setAlertMessage({ title: 'Erro!', body: 'Este login já está em uso. Por favor, escolha outro login.' });
                    } else {
                        setAlertMessage({ title: 'Erro!', body: 'Não foi possível cadastrar o técnico, tente novamente.' });
                    }
                    setAlertType('error');
                    setAlertOpen(true);
                }
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    setAlertMessage({ title: 'Erro!', body: 'Dados inválidos. Verifique os campos e tente novamente.' });
                } else {
                    setAlertMessage({ title: 'Erro!', body: 'Erro ao cadastrar técnico: ' + (error.message || 'Erro desconhecido') });
                }
                setAlertType('error');
                setAlertOpen(true);
            } finally {
                setCarregandoCadastro(false);
            }
        } else {
            setAlertMessage({ title: 'Falha!', body: 'É necessário informar um nome, login, CPF e senha para realizar o cadastro.' });
            setAlertType('fail');
            setAlertOpen(true);
        }
    }


    const editarTecnico = async () => {
        if (!validarEmail(tecnicoLogin)) {
            setAlertMessage({ title: 'Erro!', body: 'Por favor, insira um e-mail válido no campo de login.' });
            setAlertType('error');
            setAlertOpen(true);
            return;
        }

        if ((tecnicoCPF != null && tecnicoCPF !== '') && (registroTecnicoID != null && registroTecnicoID !== '') && (tecnicoLogin != null && tecnicoLogin !== '') && (tecnicoNome != null && tecnicoNome !== '')) {
            setCarregandoEdicao(true);

            const cpfLimpo = tecnicoCPF.replace(/\D/g, '');


            const dados = {
                nome: tecnicoNome,
                login: tecnicoLogin,
                cpf: cpfLimpo,
            }

            const response = await perfilTecnicoService.editarTecnico(registroTecnicoID, dados);

            if (response.error == false) {
                setAlertMessage({ title: 'Sucesso!', body: 'Técnico alterado com sucesso!' });
                setAlertType('success');
                setAlertOpen(true);
                buscarTecnicos();
                fecharModalEditarTecnico();
            } else {
                setAlertMessage({ title: 'Erro!', body: 'Não foi possível alterar o técnico, tente novamente.' });
                setAlertType('error');
                setAlertOpen(true);
            }
            setCarregandoEdicao(false);
        } else {
            setAlertMessage({ title: 'Falha!', body: 'É necessário informar um CPF, nome, login e senha para realizar a edição.' });
            setAlertType('fail');
            setAlertOpen(true);
        }
    };

    const deletarTecnico = async () => {
        setCarregandoDelete(true);

        const response = await perfilTecnicoService.desativarTecnico(registroTecnicoID);
        if (response.error == false) {
            setAlertMessage({ title: 'Sucesso!', body: 'Técnico deletado com sucesso!' });
            setAlertType('success');
            setAlertOpen(true);
            buscarTecnicos();
            fecharModalDeletarTecnico();
        } else {
            setAlertMessage({ title: 'Error!', body: 'Não foi possível deletar o técnico, tente novamente.' });
            setAlertType('error');
            setAlertOpen(true);
        }

        setCarregandoDelete(false);
    }

    const buscarTecnicos = async (page, pageSize, pesquisa) => {
        setCarregandoTable(true);
        try {
            const dados = {
                pagina: page ? page : 0,
                limite: pageSize ? pageSize : 8,
                nome: pesquisa ? pesquisa : '',
            }

            let area =
                authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;

            const response = await perfilTecnicoService.listarTecnicos(microSigaID ? microSigaID : area, dados);

            if (response.dados) {
                setTotalDados(response.total)
                setDadosTecnico(response.dados);
            }
        } catch (error) {
            setAlertMessage({ title: 'Error!', body: 'Erro ao buscar técnicos cadastrados.' });
            setAlertType('error');
            setAlertOpen(true);
        }
        setCarregandoTable(false)
    }

    const valorPesquisa = (nome) => {
        setPesquisa(nome);
        buscarTecnicos(0, pageSize, nome);
    };

    const mudarPagina = (event, newPage) => {
        setPage(newPage);
        buscarTecnicos(newPage, pageSize, pesquisa);
    };

    const mudarTamanhoPagina = (event) => {
        setPageSize(parseInt(event.target.value, 8));
        setPage(0);
        buscarTecnicos(0, parseInt(event.target.value, 8), pesquisa);
    };

    useEffect(() => {
        setCarregandoTela(true);
        buscarTecnicos(page, pageSize, pesquisa);
        setCarregandoTela(false);
        setTableKey(tableKey + 1)
    }, [mobile]);

    useEffect(() => {
        const length = tecnicoSenha.length >= 8;
        const uppercase = /[A-Z]/.test(tecnicoSenha);
        const lowercase = /[a-z]/.test(tecnicoSenha);
        const number = /[0-9]/.test(tecnicoSenha);
        const special = /[^A-Za-z0-9]/.test(tecnicoSenha);

        setSenhaValida({ length, uppercase, lowercase, number, special });
    }, [tecnicoSenha, mobile]);

    if (carregandoTela) {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress />
            </div>
        );
    }

    const StatusBadge = ({ aprovado }) => {
        let statusText = '';
        let bgColor = '';
        let IconComponent = null;

        switch (aprovado) {
            case true:
                statusText = 'Aprovado';
                bgColor = 'rgba(3, 201, 169, 1)';
                IconComponent = CheckCircleIcon;
                break;
            case false:
                statusText = 'Reprovado';
                bgColor = 'rgba(255, 99, 132, 1)';
                IconComponent = CancelIcon;
                break;
            case null:
                statusText = 'Aguardando Aprovação';
                bgColor = '#777';
                IconComponent = HourglassEmptyIcon;
                break;
            default:
                statusText = 'Desconhecido';
                bgColor = '#000';
                IconComponent = InfoIcon;
                break;
        }

        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px 0',
                fontSize: '0.9rem',
                fontWeight: '500',
                color: '#fff',
                backgroundColor: bgColor,
                width: '100%',
                textAlign: 'center',
                borderRadius: '4px',
                marginTop: '10px',
            }}>
                <IconComponent style={{ marginRight: '8px', color: '#fff' }} />
                {statusText}
            </div>
        );
    };

    const handleCloseCadastro = (event, reason) => {
        if (reason !== 'backdropClick') {
            fecharModalCadastroTecnico();
        }
    };

    const handleCloseEdit = (event, reason) => {
        if (reason !== 'backdropClick') {
            fecharModalEditarTecnico();
        }
    };

    const handleCloseDel = (event, reason) => {
        if (reason !== 'backdropClick') {
            fecharModalDeletarTecnico();
        }
    };
    const validarEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    return (
        <>
            <MenuComponent />
            <CustomAlert
                open={alertOpen}
                message={alertMessage}
                type={alertType}
                onClose={() => setAlertOpen(false)}
            />
            {authService.getUserInfo().usuario.tecnico !== true ? (
                <>
                    <div style={{ margin: 30 }}>
                        <h3 className={classes.tituloStyle}>Técnicos</h3>
                        <h4 className={classes.subtituloStyle}>
                            <span
                                onClick={() => {
                                    const userInfo = authService.getUserInfo();
                                    if (userInfo.usuario.gestor) {
                                        window.location.assign('/gestor');
                                    } else {
                                        window.location.assign('/agenda');
                                    }
                                }}
                                className={classes.linkStyle}
                                style={{ cursor: 'pointer' }}
                            >
                                Home
                            </span> {'>'} Técnicos
                        </h4>

                        {authService.getUserInfo().usuario.gestor === true && nomeTecnico && nomeTecnico.trim() !== "" ? (
                            <Box sx={{ marginLeft: 1 }}>
                                <p style={{ fontWeight: 'bold', fontSize: '1rem' }} className={classes.subtituloStyle}><IconButton onClick={handleCloseSolicitacaoCadastroestorTecnico} aria-label="voltar">
                                    <ArrowBackIosIcon />
                                </IconButton> {nomeTecnico}</p>
                            </Box>
                        ) : null}

                        <Grid container alignItems="center" spacing={2} style={{ justifyContent: 'space-between' }}>
                            {authService.getUserInfo().usuario.gestor === false ? (
                                <Grid item xs={12} sm={6} md={3}>
                                    <BlueButton
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                        onClick={abrirModalCadastroTecnico}
                                        startIcon={<Inventory2Icon />}
                                        style={{ height: 55 }}
                                    >
                                        Cadastrar Técnico
                                    </BlueButton>
                                </Grid>
                            ) : (null)}

                            <Grid item xs={12} sm={3} style={{ marginBottom: 20 }}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Pesquisar Técnico"
                                    value={pesquisa}
                                    onChange={(e) => valorPesquisa(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                {dadosTecnico ? (
                                                    <IconButton
                                                        onClick={() => {
                                                            valorPesquisa('');
                                                        }}
                                                        size="small"
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                ) : (
                                                    <InputAdornment position="end">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                )}
                                            </>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Dialog
                            aria-labelledby="customized-dialog-title"
                            onClose={handleCloseCadastro}
                            open={abrirModalCadastro}
                            style={{ marginTop: 35, marginBottom: 10 }}
                            fullWidth
                            maxWidth="sm"
                        >
                            <DialogTitle>
                                <Grid container alignItems="center">
                                    <Grid item xs={10} sm={11}>
                                        Adicionar Técnico
                                    </Grid>
                                    <Grid item xs={2} sm={1}>
                                        <IconButton onClick={fecharModalCadastroTecnico}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent dividers>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={7}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Nome do técnico"
                                            id="nome"
                                            label={<span>Nome do técnico <span style={{ color: 'red' }}>*</span></span>}
                                            value={tecnicoNome}
                                            onChange={(e) => setTecnicoNome(e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={5}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="CPF do técnico"
                                            id="cpf"
                                            label={<span>CPF do técnico <span style={{ color: 'red' }}>*</span></span>}
                                            value={tecnicoCPF}
                                            onChange={cpfChange}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>

                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Login do técnico"
                                            id="login"
                                            label={<span>Login do técnico <span style={{ color: 'red' }}>*</span></span>}
                                            value={tecnicoLogin}
                                            onChange={(e) => setTecnicoLogin(e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Senha do técnico"
                                            id="senha"
                                            label={<span>Senha do técnico <span style={{ color: 'red' }}>*</span></span>}
                                            type="password"
                                            onChange={(e) => setTecnicoSenha(e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                            autoComplete="new-password"
                                        />
                                        <Grid container spacing={1} style={{ marginTop: '10px' }}>
                                            <Grid item xs={12}>
                                                <PasswordCriteria label="Mínimo de 8 caracteres" valid={senhaValida.length} />
                                                <PasswordCriteria label="Letra maiúscula" valid={senhaValida.uppercase} />
                                                <PasswordCriteria label="Letra minúscula" valid={senhaValida.lowercase} />
                                                <PasswordCriteria label="Número" valid={senhaValida.number} />
                                                <PasswordCriteria label="Caractere especial" valid={senhaValida.special} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: 'space-around' }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    disabled={carregandoCadastro}
                                    onClick={fecharModalCadastroTecnico}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                >
                                    Cancelar
                                </Button>
                                <BlueButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={carregandoCadastro}
                                    onClick={handleAdicionarTecnico}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                >
                                    {carregandoCadastro ? 'Cadastrando...' : 'Cadastrar'} {carregandoCadastro && <CircularProgress size={20} />}
                                </BlueButton>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            aria-labelledby="customized-dialog-title"
                            onClose={handleCloseEdit}
                            open={abrirModalEditar}
                            style={{ marginTop: 35, marginBottom: 10 }}

                        >
                            <DialogTitle>
                                <Grid container alignItems="center">
                                    <Grid item xs={10} sm={11}>
                                        Editar Técnico
                                    </Grid>
                                    <Grid item xs={2} sm={1}>
                                        <IconButton onClick={fecharModalEditarTecnico}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <DialogContentText>
                                            Nome do técnico:
                                        </DialogContentText>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Nome do técnico"
                                            id="nomeTecnico"
                                            label={<span>Nome do técnico <span style={{ color: 'red' }}>*</span></span>}
                                            value={tecnicoNome}
                                            onChange={(e) => setTecnicoNome(e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <DialogContentText>
                                            Login do técnico:
                                        </DialogContentText>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Login do técnico"
                                            id="tecnicoLogin"
                                            label={<span>Login do técnico <span style={{ color: 'red' }}>*</span></span>}
                                            value={tecnicoLogin}
                                            onChange={(e) => setTecnicoLogin(e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <DialogContentText>
                                            CPF do técnico:
                                        </DialogContentText>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="CPF do técnico"
                                            id="CPFTecnico"
                                            label={<span>CPF do técnico <span style={{ color: 'red' }}>*</span></span>}
                                            value={tecnicoCPF}
                                            onChange={cpfChange}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>

                            <DialogActions style={{ justifyContent: 'space-around' }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    disabled={carregandoEdicao}
                                    onClick={fecharModalEditarTecnico}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                >
                                    Cancelar
                                </Button>
                                <BlueButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={carregandoEdicao}
                                    onClick={editarTecnico}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                >
                                    {carregandoEdicao ? 'Editando...' : 'Editar'} {carregandoEdicao && <CircularProgress size={20} />}
                                </BlueButton>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            aria-labelledby="customized-dialog-title"
                            onClose={handleCloseDel}
                            open={abrirModalDeletar}
                            style={{ marginTop: 35, marginBottom: 10 }}
                        >
                            <DialogTitle>
                                <Grid container alignItems="center">
                                    <Grid item xs={10} sm={11}>
                                        Confirmação
                                    </Grid>
                                    <Grid item xs={2} sm={1}>
                                        <IconButton onClick={fecharModalDeletarTecnico}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent dividers>
                                <DialogContentText>
                                    Você tem certeza que deseja desativar o acesso do técnico <strong>{tecnicoNome}</strong>?
                                </DialogContentText>
                            </DialogContent>

                            <DialogActions style={{ justifyContent: 'space-around' }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    disabled={carregandoDelete}
                                    onClick={fecharModalDeletarTecnico}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                >
                                    Cancelar
                                </Button>
                                <BlueButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={carregandoDelete}
                                    onClick={deletarTecnico}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                >
                                    {carregandoDelete ? 'Desativando...' : 'Desativar'} {carregandoDelete && <CircularProgress size={20} />}
                                </BlueButton>
                            </DialogActions>
                        </Dialog>

                        <Paper className={classes.paper}>
                            {carregandoTable ? (
                                <Grid container justifyContent="center" alignItems="center" style={{ height: '200px' }}>
                                    <CircularProgress />
                                </Grid>
                            ) : dadosTecnico.length === 0 ? (
                                <Grid container justifyContent="center" alignItems="center" style={{ height: '100px' }}>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        Não há técnicos para serem listados.
                                    </Typography>
                                </Grid>
                            ) : (
                                <>
                                    <Grid container spacing={1} alignItems="stretch">
                                        {dadosTecnico.map((tecnico) => (
                                            <Grid item key={tecnico} xs={12} md={3} style={{ width: '100%', maxWidth: '100%' }}>
                                                <Paper className={classes.gridItem} style={{ padding: '16px', marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
                                                    <Typography variant="h6">
                                                        {tecnico.nome.length > 25 ? `${tecnico.nome.slice(0, 25)}...` : tecnico.nome}
                                                    </Typography>
                                                    <Typography variant="body2">Login: {tecnico.login.split('@')[0]}</Typography>
                                                    <Typography variant="body2">Cpf: {tecnico.cpf}</Typography>
                                                    <Typography variant="body2">Gestor: {tecnico.gestorNome}</Typography>
                                                    <Typography variant="body2">Cadastro: {moment(tecnico.dataCadastro).format('DD/MM/YYYY HH:mm:ss')}</Typography>
                                                    <Typography variant="body2">Atualização: {moment(tecnico.dataAtualizacao).format('DD/MM/YYYY HH:mm:ss')}</Typography>

                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        padding: '2px 5px',
                                                        marginTop: '10px',
                                                        fontSize: '65%',
                                                        fontWeight: '700',
                                                        color: '#fff',
                                                        borderRadius: '10px',
                                                        textAlign: 'center',
                                                        backgroundColor: tecnico.aprovado === null ? '#777' :
                                                            tecnico.aprovado ? 'rgba(3, 201, 169, 1)' :
                                                                'rgba(255, 99, 132, 1)',
                                                    }}>
                                                        {tecnico.aprovado === null && <HourglassEmptyIcon />}
                                                        {tecnico.aprovado === true && <CheckCircleIcon />}
                                                        {tecnico.aprovado === false && <CancelIcon />}
                                                        {tecnico.aprovado === null ? 'Aguardando Aprovação' : tecnico.aprovado ? 'Aprovado' : 'Reprovado'}
                                                    </div>

                                                    <Grid container spacing={1} style={{ paddingTop: '10px' }}>
                                                        <Grid item xs={6}>
                                                            <BlueButton
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => abrirModalEditarTecnico(tecnico)}
                                                                startIcon={<EditIcon />}
                                                                disabled={tecnico.inserido}
                                                                fullWidth
                                                            >
                                                                Editar
                                                            </BlueButton>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <BlueButton
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={() => abrirModalDeletarTecnico(tecnico)}
                                                                startIcon={<DeleteIcon />}
                                                                disabled={tecnico.inserido}
                                                                fullWidth
                                                            >
                                                                Remover
                                                            </BlueButton>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                        ))}
                                    </Grid>

                                    <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                                        <Grid item>
                                            <CustomTablePagination
                                                count={totalDados}
                                                page={page}
                                                rowsPerPage={pageSize}
                                                onPageChange={mudarPagina}
                                                quantidadeItens={totalDados}
                                                onRowsPerPageChange={mudarTamanhoPagina}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Paper>
                    </div>
                </>)
                : (<div style={{ margin: 30 }}>
                    <h3 className={classes.tituloStyle}>Técnicos</h3>
                    <h4 className={classes.subtituloStyle}>
                        <span
                            onClick={() => {
                                const userInfo = authService.getUserInfo();
                                if (userInfo.usuario.gestor) {
                                    window.location.assign('/gestor');
                                } else {
                                    window.location.assign('/agenda');
                                }
                            }}
                            className={classes.linkStyle}
                            style={{ cursor: 'pointer' }}
                        >
                            Home
                        </span> {'>'} Técnicos
                    </h4>

                    <Paper className={classes.paper}>
                        <Grid container justifyContent="center">
                            <h2 className={classes.mensagemStyle} style={{ padding: 10 }}>Você não tem permissão para acessar esta página!</h2>
                        </Grid>
                    </Paper>
                </div>
                )}
        </>
    );
}