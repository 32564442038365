import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  CircularProgress,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBackIos, Mail, Save } from '@material-ui/icons';
import vistoriaService from '../Services/Vistoria/vistoria-service';
import veiculoService from '../Services/Veiculo/veiculo-service';
import BlueButton from '../components/button/BlueButton';
import { AvlLatariaVistoria } from './components/vistoria/avl-lataria-vistoria-component';
import { EmailModal } from './components/vistoria/email-modal-component';
import { VistoriaItems } from './components/vistoria/vistoria-items-component';
import { ListaProdutosServicos } from './components/vistoria/lista-produtos-component'
import { AvlPreliminarVistoria } from './components/vistoria/avl-preliminar-vistoria-component';
import { Alert } from '@material-ui/lab';
import { statusVistoria } from '../util/statusVistoria';
import { validateVistoria } from '../util/validateVistoria';
import { Box } from '@mui/material';
import html2pdf from 'html2pdf.js';
import CustomAlert from '../components/alert/CustomAlert';

const useStyles = makeStyles((theme) => ({
  actions: {
    marginBottom: 6,
    float: 'left'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 20px 60px',
    borderRadius: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 20px',
    },
  },
  title: {
    fontSize: 23,
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 50
  },
  subtitle: {
    fontSize: 19,
    fontWeight: 'bold',
    marginTop: 40,
    marginBottom: 20,
  },
  radioP: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  radioTitle: {
    fontSize: 16,
    marginRight: 10,
  },
  itemText: {
    fontSize: 18,
  },
  errorText: {
    color: '#FF0000',
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px'
  },
  mensagemStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px'
  }
}));

export function OSVistoriaPage({ ordemServico, handleBack, tipoVistoria, itensOS }) {
  const classes = useStyles();
  const [vistoria, setVistoria] = useState(null);
  const [editVistoria, setEditVistoria] = useState(false);
  const [disabledEditEntry, setDisabledEditEntry] = useState(false);
  const [disabledEditExit, setDisabledEditExit] = useState(false);
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [loadSave, setLoadSave] = useState(false);
  const [loadVistoria, setLoadVistoria] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [fotoMarca, setfotoMarca] = React.useState(null);
  const [alertEmailEnviado, setAlertEmailEnviado] = useState(false);
  const [mensagemAlertaEmail, setMensagemAlertaEmail] = useState('');
  const inputVistoriaRef = useRef(null);
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState('');
  const [tipoAlert, setTipoAlert] = useState('error');
  const locais = ['Volante', 'Ponto fixo'];

  useEffect(() => {
    getVistoria();
    // consultaMarcas();
    handleInputChange('cor', ordemServico?.cor);
    handleInputChange('placa', ordemServico?.placa);
    handleInputChange('equipamento', ordemServico?.rastreador);
    handleInputChange('serie', ordemServico?.num_serie_contrato);
    handleInputChange('tipo_servico', ordemServico?.tipoServico);
    handleInputChange('local_servico', ordemServico?.localAtendimento);
  }, [ordemServico]);

  // busca os dados da vistoria a partir da OS passada
  async function getVistoria() {
    try {
      setLoadVistoria(true);
      const { osID, activityId } = ordemServico;

      const responseVistoria = await vistoriaService.consultaVistoria(
        activityId,
        osID
      );
      if (responseVistoria?.error) {
        setMensagemAlert({ title: 'Erro!', body: responseVistoria?.message });
        setTipoAlert('error');
        setAbrirAlert(true);
      } else {
        if (responseVistoria) {
          // consultaModelos(responseVistoria?.id_fabricante)
          // handleInputChange('id_fabricante', responseVistoria?.id_fabricante);
          // handleInputChange('id_modelo', responseVistoria?.id_modelo);

          setVistoria(responseVistoria);
          setMensagemAlertaEmail(obterMensagemAlertaEmail(responseVistoria));
          setEditVistoria(true);

          if (responseVistoria.entrada) setDisabledEditEntry(true);

          if (responseVistoria.saida) setDisabledEditExit(true);

        } else {
          // consultaModelos(ordemServico?.marcaID)
          // handleInputChange('id_fabricante', ordemServico?.marcaID);
          // handleInputChange('id_modelo', ordemServico?.modeloID);

          setEditVistoria(false);
        }
      }
    } catch (error) {
      console.log(error.toString());
    } finally {
      setLoadVistoria(false);
    }
  }

  const cpfChange = (event) => {
    let cpf = event.target.value;

    cpf = cpf.replace(/\D/g, '');

    cpf = cpf.slice(0, 11);

    let cpfFormatado = '';
    for (let i = 0; i < cpf.length; i++) {
      if (i === 3 || i === 6) {
        cpfFormatado += '.';
      }
      else if (i === 9) {
        cpfFormatado += '-';
      }
      cpfFormatado += cpf[i];
    }

    handleInputChange('clienteCpfCnpj', cpfFormatado);
  }

  // ativada sempre que alterar um dado de input
  const handleInputChange = (atr, value) => {
    setVistoria((checkList) => ({
      ...checkList,
      [atr]: value,
    }));
  };

  // ativada quando alterar o dado de qtde. de temp
  const handleInputQtdeTemp = (value) => {
    setVistoria((checkList) => ({
      ...checkList,
      qtde_sensor_temperatura:
        value >= 0 ? parseInt(value) : parseInt(value) * -1,
    }));
  };

  // verifica o valor passado e limita à 4 digitos
  function onChangeYear(value) {
    if (!value) {
      setVistoria((oldVistoria) => ({
        ...oldVistoria,
        ano: null,
      }));
      return;
    }
    if (value?.length <= 4) {
      setVistoria((oldVistoria) => ({
        ...oldVistoria,
        ano: value >= 0 ? parseInt(value) : parseInt(value) * -1,
      }));
    }
  }

  const obterMensagemAlertaEmail = (vistoria) => {
    const tipoVistoria = vistoria.checkListTipo;

    const formatarData = (data) => {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
      const dataFormatada = new Date(data).toLocaleString('pt-BR', options);
      return `${dataFormatada.substr(0, 10)} às ${dataFormatada.substr(12)}.`;
    };

    if (vistoria && (vistoria.dataEnvioEmailDeSaida || vistoria.dataEnvioEmailDeEntrada)) {
      if (vistoria.dataEnvioEmailDeSaida && tipoVistoria === 'Saida') {
        setAlertEmailEnviado(true);
        return `E-mail de vistoria de saída enviado em ${formatarData(vistoria.dataEnvioEmailDeSaida)}`;
      } else if (vistoria.dataEnvioEmailDeEntrada && tipoVistoria === 'Entrada') {
        setAlertEmailEnviado(true);
        return `E-mail de vistoria de entrada enviado em ${formatarData(vistoria.dataEnvioEmailDeEntrada)}`;
      }
    }

    return null;
  };

  // busca e consulta os modelos pelo id da marca
  async function consultaModelos(marcaID) {
    let modelosResult = await veiculoService.modelos(marcaID);
    if (modelosResult) setModelos(modelosResult);
  }

  // busca marcas
  async function consultaMarcas() {
    let marcasResult = await veiculoService.marcas();

    if (marcasResult) setMarcas(marcasResult);
  }

  // converte o valor para boolean
  const parseBool = (value) =>
    value === 'true' || value === true ? true : false;

  // salva o vistoria
  const handleSaveVistoria = async () => {
    const resultSave = await saveVistoria();

    const dados = {
      AtividadeId: ordemServico.activityId,
      TipoVistoria: vistoria.checkListTipo,
      ImagemBase64: vistoria.vistoriaPdfBase64
    }

    if (resultSave.success) {
      await vistoriaService.enviaPDFVistoria(JSON.stringify(dados));
    }

    let title;
    if (resultSave.type === 'success') {
      title = 'Sucesso!';
    } else if (resultSave.type === 'fail') {
      title = 'Falha!';
    } else {
      title = 'Erro!';
    }

    setMensagemAlert({ title: title, body: resultSave.message });
    setTipoAlert(resultSave.type);
    setAbrirAlert(true);
  };

  const trataBaseImg = (img) => {
    return img.replace(/^data:image\/[a-z]+;base64,/, '');
  }

  const trataBasePdf = (pdf) => {
    return pdf.replace(/^data:application\/pdf;filename=.*;base64,/, '');
  }

  const validaImagem = (tipo) => {
    if (tipo === 'image/jpeg' ||
      tipo === 'image/jpg' ||
      tipo === 'image/jpe' ||
      tipo === 'image/png' ||
      tipo === 'image/bmp')
      return true;

    return false;
  }

  const handleFotoMarcaModelo = (file) => {

    if (file && validaImagem(file.type))
      setfotoMarca({
        imagem: trataBaseImg(file.base64),
        nome: `foto_marca_modelo.${file.name.split('.')[1]}`,
        nomeImagem: `foto_marca_modelo.${file.name.split('.')[1]}`
      });
  }

  const isButtonDisabled = () => {
    return (disabledEditEntry && disabledEditExit && loadVistoria);
  };

  const saveVistoria = async () => {
    // validação dos dados
    const validate = validateVistoria(vistoria);
    if (Object.keys(validate).length !== 0) {
      setErrors(validate);
      return {
        success: false,
        message: 'Preencha os campos obrigatórios!',
        type: 'fail'
      };
    }

    setErrors({});
    setLoadSave(true);

    await carregarVistoriaEmPdfBase64();

    const content = inputVistoriaRef.current;

    content.style.marginLeft = `0px`;
    content.style.marginRight = `0px`;
    content.style.marginTop = `0px`;

    if (editVistoria) {
      const { osID, activityId } = ordemServico;

      let responseUpdateVistoria = '';

      if (!vistoria.ClienteCpfCnpj) vistoria.ClienteCpfCnpj = vistoria.clienteCpfCnpj;

      if (vistoria.statusEntrada === 'Aceito') {
        vistoria.ChecklistTipo = 'Saida';
      }

      vistoria.NumeroOS = vistoria?.numeroOS || ordemServico?.os;

      responseUpdateVistoria = await vistoriaService.updateVistoria(
        activityId,
        osID,
        JSON.stringify(vistoria)
      );

      setLoadSave(false);

      if (responseUpdateVistoria.error) {
        setMensagemAlert({ title: 'Erro!', body: responseUpdateVistoria.message });
        setTipoAlert('error');
        setAbrirAlert(true);
        return {
          success: false,
          message: responseUpdateVistoria.message,
          type: 'error'
        };
      } else {
        setMensagemAlert({ title: 'Sucesso!', body: 'A vistoria foi atualizada com sucesso.' });
        setTipoAlert('success');
        setAbrirAlert(true);
        return {
          success: true,
          message: 'A vistoria foi atualizada com sucesso.',
          type: 'success'
        };
      }
    } else {
      vistoria.na = ordemServico.na;
      vistoria.antena = ordemServico.antena;
      vistoria.id_agendamento = ordemServico.activityId;
      vistoria.id_ordem_servico = ordemServico.osID;
      vistoria.id_cliente = ordemServico.clienteID;
      vistoria.id_area_atendimento = ordemServico.id_area_atendimento;
      vistoria.ClienteNome = vistoria.clienteNome;
      vistoria.ClienteCpfCnpj = vistoria.clienteCpfCnpj;
      vistoria.ChecklistTipo = 'Entrada';
      vistoria.NumeroOS = vistoria?.numeroOS || ordemServico?.os;

      const response = await vistoriaService.createVistoria(
        JSON.stringify(vistoria)
      );

      setLoadSave(false);
      if (response.error) {
        setMensagemAlert({ title: 'Erro!', body: response.message });
        setTipoAlert('error');
        setAbrirAlert(true);
        return {
          success: false,
          message: response.message,
          type: 'error'
        };
      } else {
        setEditVistoria(true);
        setMensagemAlert({ title: 'Sucesso!', body: 'A vistoria foi cadastrada com sucesso.' });
        setTipoAlert('success');
        setAbrirAlert(true);
        return {
          success: true,
          message: 'A vistoria foi cadastrada com sucesso',
          type: 'success'
        };
      }
    }
  };

  const carregarVistoriaEmPdfBase64 = async () => {
    try {
      const marginLeft = 50;
      const marginRight = 50;

      const options = {
        filename: `VistoriaDe${tipoVistoria}.pdf`,
        image: { type: 'jpeg', quality: 0.75 },
        html2canvas: { scale: 1, margin: { left: marginLeft, right: marginRight } },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait', compress: true },
      };

      const content = inputVistoriaRef.current;

      content.style.marginLeft = `${marginLeft}px`;
      content.style.marginRight = `${marginRight}px`;
      content.style.marginTop = `${marginRight}px`;

      const base64 = await html2pdf().from(content).set(options).toPdf().output('datauristring');
      vistoria.vistoriaPdfBase64 = trataBasePdf(base64);

    } catch (error) {
      console.error('Erro:', error);
    }
  };

  const handleOpenEmailModal = () => {
    const validate = validateVistoria(vistoria);
    if (Object.keys(validate).length !== 0) {
      setErrors(validate);

      setMensagemAlert({ title: 'Falha!', body: 'Preencha os campos obrigatórios!' });
      setTipoAlert('fail');
      setAbrirAlert(true);

    } else {
      setOpenEmailModal(true);
      setErrors({});
    }
  };

  return (
    <div style={{ margin: 30 }}>
      <h3 className={classes.tituloStyle}>Vistoria de {tipoVistoria === 'saida' ? 'Saída' : 'Entrada'}</h3>
      <h4 className={classes.subtituloStyle}>
        <span
          onClick={() => window.location.href = '/agenda'}
          className={classes.linkStyle}
          style={{ cursor: 'pointer' }}
        >
          Home
        </span> {'>'} Ordem de Serviço
      </h4>

      <EmailModal
        open={openEmailModal}
        handleClose={() => setOpenEmailModal(false)}
        vistoria={vistoria}
        ordemServico={ordemServico}
        saveVistoria={saveVistoria}
      />

      <Grid className={classes.actions}>
        <Grid item xs={12} sm={12}>
          <Button
            fullWidth
            onClick={handleBack}
            style={{ color: '#209BDE' }}
            startIcon={<ArrowBackIos />}
          >
            Voltar
          </Button>
        </Grid>
      </Grid>

      <Paper className={classes.paper}>
        <CustomAlert
          open={abrirAlert}
          message={mensagemAlert}
          type={tipoAlert}
          onClose={() => setAbrirAlert(false)}
        />
        {loadVistoria ? (
          <Grid container alignItems="center" justifyContent="center">
            <CircularProgress color="#209BDE" style={{ color: '#209BDE' }} />
          </Grid>
        ) : (
          <>
            <div ref={inputVistoriaRef}>
              <div className={classes.title} style={{ marginTop: 0 }}>Vistoria de {tipoVistoria === 'saida' ? 'Saída' : 'Entrada'}</div>
              <Divider />

              <div className={classes.subtitle}>
                Informações sobre instalação
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="txtEquipamento"
                    label={
                      <span>
                        Equipamento <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    error={!!errors.equipamento}
                    helperText={errors.equipamento?.message}
                    defaultValue={vistoria?.rastreador || ordemServico?.rastreador || ''}
                    fullWidth
                    placeholder='Equipamento'
                    onChange={(event) =>
                      handleInputChange('equipamento', event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    error={!!errors.serie}
                    placeholder='Número de Série/ID'
                    helperText={errors.serie?.message}
                    id="txtNumSerie"
                    label={
                      <span>
                        Número de Série/ID <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    defaultValue={vistoria?.serie || ordemServico?.num_serie_contrato || ''}
                    onChange={(event) =>
                      handleInputChange('serie', event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    id="txtNA"
                    placeholder='NA'
                    label={
                      <span>
                        NA <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    defaultValue={vistoria?.na || ordemServico?.na || ''}
                    disabled
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    id="txtOS"
                    placeholder='Número da OS'
                    label={
                      <span>
                        Número da OS <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    defaultValue={vistoria?.numeroOS || ordemServico?.os || ''}
                    disabled
                    variant="outlined"
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    id="txtEmpresa"
                    label="Empresa Credenciada"
                    value={""}
                    disabled
                    variant="outlined"
                  />
                </Grid> */}
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    id="txtAntena"
                    placeholder='Antena Sat'
                    label={
                      <span>
                        Antena Sat <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={ordemServico?.antena || ''}
                    disabled
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    error={!!errors.instalador}
                    helperText={errors.instalador?.message}
                    id="txtInstalador"
                    placeholder='Instalador e Auxiliar'
                    label={
                      <span>
                        Instalador e Auxiliar <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    defaultValue={vistoria?.instalador || ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) =>
                      handleInputChange('instalador', event.target.value)
                    }
                    variant="outlined"
                    disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                  />
                </Grid>
              </Grid>


              <div className={classes.subtitle}>
                Tipo de serviço <span style={{ color: 'red' }}>*</span>
              </div>
              {!!errors.tipo_servico && (
                <span className={classes.errorText} style={{ marginTop: 20 }}>
                  {errors.tipo_servico.message}
                </span>
              )}

              <RadioGroup
                row
                id="rdpgTipoVeiculo"
                value={vistoria?.tipo_servico || ordemServico?.tipoServico || ''}
                onChange={(event) =>
                  handleInputChange('tipo_servico', event.target.value)
                }
                style={{ marginTop: errors.tipo_servico ? 2 : 20 }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4} md={4}>
                    <FormControlLabel
                      value="IR"
                      control={<Radio color="primary" />}
                      label="Instalação/Reinstalação"
                      disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <FormControlLabel
                      value="RM"
                      control={<Radio color="primary" />}
                      label="Revisão/Manutenção"
                      disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <FormControlLabel
                      value="RT"
                      control={<Radio color="primary" />}
                      label="Retirada"
                      disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <FormControlLabel
                      value="UP"
                      control={<Radio color="primary" />}
                      label="Upgrade"
                      disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>

              <div className={classes.subtitle}>Dados do cliente e veículo</div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <TextField
                    fullWidth
                    id="txtCliente"
                    label={
                      <span>
                        Cliente <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    value={ordemServico?.cliente || ''}
                    disabled
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4} >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={!!errors.local_servico}
                  >
                    <InputLabel id="label_local">Local do serviço <span style={{ color: 'red' }}>*</span></InputLabel>
                    <Select
                      native
                      labelId="label_local"
                      label={
                        <span>
                          Local do Serviço <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                      id="select_local"
                      value={
                        locais.includes(vistoria?.local_servico?.trim() || ordemServico?.localAtendimento?.trim())
                          ? vistoria?.local_servico || ordemServico?.localAtendimento
                          : ''
                      }
                      onChange={(event) =>
                        handleInputChange('local_servico', event.target.value)
                      }
                    >
                      <optgroup>
                        <option value="" disabled hidden />
                        {locais.map((local) => (
                          <option key={local} value={local}>
                            {local}
                          </option>
                        ))}
                      </optgroup>
                    </Select>
                    <FormHelperText>
                      {errors.local_servico?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    error={!!errors.placa}
                    helperText={errors.placa?.message}
                    id="txtPlaca"
                    label={
                      <span>
                        Placa <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    value={vistoria?.placa || ordemServico?.placa || ''}
                    onChange={(event) =>
                      handleInputChange('placa', event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    name="drpMarca"
                    error={!!errors.id_fabricante}
                    helperText={errors.id_fabricante?.message}
                    value={
                      vistoria?.id_fabricante ?
                        (vistoria?.id_fabricante && marcas.length > 0) ? marcas.find((x) => x.id == vistoria?.id_fabricante) : null
                        :
                        (ordemServico?.marcaID && marcas.length > 0) ? marcas.find((x) => x.id == ordemServico?.marcaID) : null
                    }
                    defaultValue={
                      vistoria?.id_fabricante ?
                        (vistoria?.id_fabricante && marcas.length > 0) ? marcas.find((x) => x.id == vistoria?.id_fabricante) : null
                        :
                        (ordemServico?.marcaID && marcas.length > 0) ? marcas.find((x) => x.id == ordemServico?.marcaID) : null
                    }
                    onChange={async (event, value) => {
                      if (value) {
                        consultaModelos(value.id);
                        handleInputChange('id_fabricante', value.id);
                        handleInputChange('id_modelo', null);
                      }
                    }}
                    options={marcas}
                    getOptionLabel={(option) => option.nome}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <span>
                            Fabricante <span style={{ color: 'red' }}>*</span>
                          </span>
                        }
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                  />
                </Grid> */}
                {/* <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    name="drpModelo"
                    error={!!errors.id_modelo}
                    helperText={errors.id_modelo?.message}
                    value={
                      vistoria?.id_modelo ?
                        (vistoria?.id_modelo && modelos.length > 0) ? modelos.find((x) => x.id == vistoria?.id_modelo) : null
                        :
                        (ordemServico?.modeloID && modelos.length > 0) ? modelos.find((x) => x.id == ordemServico?.modeloID) : null
                    }
                    defaultValue={
                      vistoria?.id_modelo ?
                        (vistoria?.id_modelo && modelos.length > 0) ? modelos.find((x) => x.id == vistoria?.id_modelo) : null
                        :
                        (ordemServico?.modeloID && modelos.length > 0) ? modelos.find((x) => x.id == ordemServico?.modeloID) : null
                    }
                    onChange={async (event, value) => {
                      if (value) handleInputChange('id_modelo', value.id);
                    }}
                    options={modelos}
                    getOptionLabel={(option) => option.nome}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <span>
                            Modelo <span style={{ color: 'red' }}>*</span>
                          </span>
                        }
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    error={!!errors.ano}
                    helperText={errors.ano?.message}
                    placeholder='Ano'
                    id="txtAno"
                    label={
                      <span>
                        Ano <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    type="number"
                    value={vistoria?.ano || ''}
                    onChange={(event) => onChangeYear(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ maxLength: 4 }}
                    variant="outlined"
                    disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField

                    fullWidth
                    error={!!errors.cor}
                    helperText={errors.cor?.message}
                    id="txtCor"
                    label={
                      <span>
                        Cor <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    value={vistoria?.cor || ordemServico?.cor || ''}
                    onChange={(event) =>
                      handleInputChange('cor', event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginTop: 20 }}>
                <Grid item xs={12} sm={4} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="chkMecanico"
                        name="chkMecanico"
                        checked={parseBool(vistoria?.mecanico)}
                        value={vistoria?.mecanico}
                        onChange={(event) =>
                          handleInputChange(
                            'mecanico',
                            !parseBool(event.target.value)
                          )
                        }
                      />
                    }
                    label="Mecânico"
                    disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="chkInjecao"
                        name="chkInjecao"
                        checked={parseBool(vistoria?.injecao_eletronica)}
                        value={vistoria?.injecao_eletronica}
                        onChange={(event) =>
                          handleInputChange(
                            'injecao_eletronica',
                            !parseBool(event.target.value)
                          )
                        }
                      />
                    }
                    label="Injeção Eletrônica"
                    disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="chkDoze"
                        name="chkDoze"
                        checked={parseBool(vistoria?.doze_v)}
                        value={vistoria?.doze_v}
                        onChange={(event) =>
                          handleInputChange(
                            'doze_v',
                            !parseBool(event.target.value)
                          )
                        }
                      />
                    }
                    label="12V"
                    disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="chk24V"
                        name="chk24V"
                        checked={parseBool(vistoria?.vinte_quatro_v)}
                        value={vistoria?.vinte_quatro_v}
                        onChange={(event) =>
                          handleInputChange(
                            'vinte_quatro_v',
                            !parseBool(event.target.value)
                          )
                        }
                      />
                    }
                    label="24V"
                    disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                  />
                </Grid>
              </Grid>

              <div className={classes.subtitle}>Tipo do veículo <span style={{ color: 'red' }}>*</span></div>
              {!!errors.tipo_veiculo && (
                <span className={classes.errorText}>
                  {errors.tipo_veiculo.message}
                </span>
              )}
              <RadioGroup
                row
                id="rdpgTPVeiculo"
                value={vistoria?.tipo_veiculo || ''}
                onChange={(event) =>
                  handleInputChange('tipo_veiculo', event.target.value)
                }
              >
                <Grid container>
                  <Grid item xs={12} sm={3}>
                    <FormControlLabel
                      value="CM"
                      control={<Radio color="primary" />}
                      label="Cavalo Mec."
                      disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControlLabel
                      value="TR"
                      control={<Radio color="primary" />}
                      label="Truck"
                      disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControlLabel
                      value="TC"
                      control={<Radio color="primary" />}
                      label="Toco"
                      disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControlLabel
                      value="VN"
                      control={<Radio color="primary" />}
                      label="Van"
                      disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControlLabel
                      value="PS"
                      control={<Radio color="primary" />}
                      label="Passeio"
                      disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControlLabel
                      value="MT"
                      control={<Radio color="primary" />}
                      label="Moto"
                      disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControlLabel
                      value="CT"
                      control={<Radio color="primary" />}
                      label="Caminhonete"
                      disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControlLabel
                      value="ON"
                      control={<Radio color="primary" />}
                      label="Ônibus"
                      disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControlLabel
                      value="CR"
                      control={<Radio color="primary" />}
                      label="Carreta"
                      disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControlLabel
                      value="LA"
                      control={<Radio color="primary" />}
                      label="Linha amarela"
                      disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>

              {vistoria?.tipo_veiculo === 'CR' && (
                <>
                  <div className={classes.subtitle}>Dados da carreta <span style={{ color: 'red' }}>*</span></div>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <strong>Tipo da carreta: </strong>
                      <RadioGroup
                        row
                        id="rdpgTPCarreta"
                        value={vistoria?.tipo_carreta || ''}
                        onChange={(event) =>
                          handleInputChange('tipo_carreta', event.target.value)
                        }
                      >
                        <Grid container>
                          <Grid item xs={12} sm={3}>
                            <FormControlLabel
                              value="CS"
                              control={<Radio color="primary" />}
                              label="Carga Seca"
                              disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormControlLabel
                              value="BA"
                              control={<Radio color="primary" />}
                              label="Baú Alumínio"
                              disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormControlLabel
                              value="BR"
                              control={<Radio color="primary" />}
                              label="Baú Refrig."
                              disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormControlLabel
                              value="BG"
                              control={<Radio color="primary" />}
                              label="BUG"
                              disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormControlLabel
                              value="TR"
                              control={<Radio color="primary" />}
                              label="Treminhão"
                              disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormControlLabel
                              value="SD"
                              control={<Radio color="primary" />}
                              label="Sider"
                              disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormControlLabel
                              value="PL"
                              control={<Radio color="primary" />}
                              label="Plataforma"
                              disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        fullWidth
                        id="txtPlacaCarreta"
                        label="Placa da Carreta"
                        value={vistoria?.placa_carreta}
                        onChange={(event) =>
                          handleInputChange('placa_carreta', event.target.value)
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <div className="html2pdf__page-break"></div>
              <div className={classes.title}>
                Vistoria do veículo
              </div>
              <Divider />

              <AvlPreliminarVistoria
                vistoria={vistoria}
                handleInputChange={handleInputChange}
                classes={classes}
                errors={errors}
                disabledEdit={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
              />

              {/* IMAGENS DE VEICULOS */}
              <AvlLatariaVistoria
                vistoria={vistoria}
                handleInputChange={handleInputChange}
                parseBool={parseBool}
                classes={classes}
                disabledEdit={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
              />

              {/* CHECKLIST - ITENS */}
              <VistoriaItems
                vistoria={vistoria}
                handleInputChange={handleInputChange}
                classes={classes}
                errors={errors}
                disabledEditEntry={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                disabledEditExit={disabledEditExit || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
              />

              <div className="html2pdf__page-break"></div>
              {/* CHECKLIST - PRODUTOS & SERVIÇOS */}
              <ListaProdutosServicos
                na={ordemServico.na}
                itensOS={itensOS}
                vistoria={vistoria}
                handleInputChange={handleInputChange}
                classes={classes}
                errors={errors}
                disabledEditEntry={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                disabledEditExit={disabledEditExit || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
              />

              {/* <VistoriaAcessorios
                vistoria={vistoria}
                handleInputChange={handleInputChange}
                classes={classes}
                parseBool={parseBool}
                handleInputQtdeTemp={handleInputQtdeTemp}
                disabledEdit={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
              /> */}

              <div className="html2pdf__page-break"></div>
              <div className={classes.subtitle}>
                Dados do Cliente -  Aprovação
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="NomeCliente"
                    label={
                      <span>
                        Nome do conferente <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    placeholder='Nome do conferente'
                    error={errors.clienteNome}
                    helperText={errors.clienteNome?.message}
                    value={vistoria?.clienteNome}
                    onChange={(event) =>
                      handleInputChange('clienteNome', event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}

                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="documentoCliente"
                    label={
                      <span>
                        CPF do conferente <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    placeholder='CPF do conferente'
                    error={errors.clienteCpfCnpj}
                    helperText={errors.clienteCpfCnpj?.message}
                    value={vistoria?.clienteCpfCnpj}
                    onChange={cpfChange}
                    onKeyPress={(e) => e.key.match(/^[0-9]+$/) ? null : e.preventDefault()}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    disabled={disabledEditEntry || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                  />
                </Grid>

              </Grid>
              <Grid container spacing={3} style={{ marginTop: 20 }}>
                <Grid item xs={12}>
                  <TextField
                    id="txtObs"
                    fullWidth
                    label="Observações"
                    placeholder='Observações'
                    value={vistoria?.observacao || ordemServico?.obsCliente}
                    onChange={(event) =>
                      handleInputChange('observacao', event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    disabled={disabledEditExit || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                  />
                </Grid>
                {vistoria?.entrada && (

                  <Grid item xs={12}>
                    <TextField
                      id="txtObs"
                      fullWidth
                      label="Resumo do Serviço"
                      value={vistoria?.resumo_servico}
                      style={{ width: '100%' }}
                      onChange={(event) =>
                        handleInputChange('resumo_servico', event.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      disabled={disabledEditExit || (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false)}
                    />
                  </Grid>
                )}
              </Grid>
            </div>

            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
              style={{ marginTop: '30px' }}
            >
              <Grid item xs={12} sm={3} md={2}>
                <BlueButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleOpenEmailModal}
                  startIcon={<Mail />}
                  disabled={vistoria ? (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false) : false}
                  style={{ height: 55 }}
                >
                  <span style={{ fontSize: '0.7rem' }}>E-mail</span>
                </BlueButton>
              </Grid>
              <Grid item xs={12} sm={3} md={2}>
                {loadSave ? (
                  <Grid container alignItems="center" justifyContent="center">
                    <CircularProgress size={25} style={{ color: '#209BDE' }} />
                  </Grid>
                ) : (
                  <BlueButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleSaveVistoria}
                    startIcon={<Save />}
                    disabled={vistoria ? (statusVistoria(vistoria, tipoVistoria) === 'Aceito' ? true : false) : false}
                    style={{ height: 55 }}
                  >
                    <span style={{ fontSize: '0.7rem' }}>Salvar</span>
                  </BlueButton>
                )}
              </Grid>
            </Grid>

            <Box style={{ marginTop: 20 }}>
              {alertEmailEnviado && <Alert severity='error'>
                {mensagemAlertaEmail}
              </Alert>}
              {(statusVistoria(vistoria, tipoVistoria) != 'Aceito') &&
                <Alert severity='info'>
                  OBS: Após envio do e-mail, o cliente tem 15 minutos para aprovar a vistoria. Passado esse tempo, a vistoria será aceita automaticamente.
                </Alert>
              }
            </Box>
          </>
        )}
      </Paper>
    </div>
  );
}
