import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class NoticiaService {

  async noticias() {
    try {
      const _headerToken = authService.headerAPITecnico();
      const response = await fetch(`${_configAPITecnico.URI_API}/noticias`, {
        method: 'GET',
        headers: _headerToken,
      });

      if (response.status === 200) {
        const data = await response.json();


        // Assegura que cada item possui um id único
        if (Array.isArray(data)) {
          return data.map((item, index) => ({
            ...item,
            id: item.id || index, // Usa o id original ou, como fallback, o índice do array
          }));
        } else {
          console.error('Estrutura de dados inesperada:', data);
          return [];
        }
      } else {
        console.error(`Erro ao buscar notícias: ${response.statusText}`);
        return [];
      }
    } catch (error) {
      console.error('Erro ao buscar notícias:', error);
      return [];
    }
  }

  async detalheNF(areaAtendimentoID, nfID) {
    try {
      const _headerToken = authService.headerAPITecnico();
      const response = await fetch(`${_configAPITecnico.URI_API}/notafiscal/item/${nfID}/${areaAtendimentoID}`, {
        method: 'GET',
        headers: _headerToken,
      });

      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}

export default new NoticiaService();